import {ReactElement} from 'react';
import {FilterOnChange} from '../types';
import {TableFilterWrapper} from './table-filter-wrapper';

type Props = {
  filterId: string;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  filterValues: Record<any, any> | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  onChange: FilterOnChange<any, any>;
  elements: Array<{
    element: ReactElement;
    key: string | number;
  }>;
};
export const TableCheckboxFilter = ({filterId, filterValues, onChange, elements}: Props) => {
  const isActive =
    filterValues?.[filterId]?.length !== elements.length && filterValues?.[filterId]?.length > 0;
  return (
    <TableFilterWrapper isActive={isActive}>
      <div className="py-2">
        {elements.map(({element, key}) => (
          <label key={key} className="m-1.5 flex cursor-pointer items-center space-x-1.5">
            <input
              type="checkbox"
              checked={!!filterValues?.[filterId]?.includes(key)}
              onChange={e => {
                const value = e.target.checked
                  ? [...(filterValues?.[filterId] || []), key]
                  : (filterValues?.[filterId] || []).filter((v: string) => v !== key);
                onChange({id: filterId, value});
              }}
              className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded border-gray-300 "
            />
            {element}
          </label>
        ))}
      </div>
    </TableFilterWrapper>
  );
};
