import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {
  OrganizationAccountActivityDetailsItem,
  TenantAccountActivityDetailsItem,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {formatTransferCategory} from '@zentact/common/src/utils/formatTransferCategory';
import {Link, generatePath} from 'react-router-dom';
import {TruncatedText} from '../../..';
import {formatPayoutDateWithLocalTime} from '../payoutDateFormat';

const accountActivityDetailColumnsHelper = createColumnHelper<
  TenantAccountActivityDetailsItem | OrganizationAccountActivityDetailsItem
>();

type AccountActivityDetailsProps = {
  locale: LocaleCode;
  i18n: I18n;
  showOrganizationColumn?: boolean;
  showMerchantColumn?: boolean;
  paymentDetailsPath: string;
};

export const getAccountActivityDetailsColumns = ({
  locale,
  i18n,
  showMerchantColumn,
  showOrganizationColumn,
  paymentDetailsPath,
}: AccountActivityDetailsProps) => {
  return [
    accountActivityDetailColumnsHelper.accessor('creationDate', {
      cell: props => formatLocaleDate(props.getValue()),
      header: () => i18n._('Transaction Date'),
      size: TableColumnSize.S,
      meta: {
        sort: {
          isSortable: true,
        },
      },
    }),
    accountActivityDetailColumnsHelper.accessor('valueDate', {
      cell: row => formatPayoutDateWithLocalTime(row.getValue()),
      header: () => i18n._('Value Date'),
      size: TableColumnSize.S,
      meta: {
        sort: {
          isSortable: true,
        },
      },
    }),
    ...(showMerchantColumn
      ? [
          accountActivityDetailColumnsHelper.accessor('merchantAccount.businessName', {
            cell: row => <TruncatedText text={row.getValue() || i18n._('N/A')} />,
            header: () => i18n._('Merchant Account'),
            meta: {
              collapseAt: 'sm',
            },
          }),
        ]
      : []),
    ...(showOrganizationColumn
      ? [
          accountActivityDetailColumnsHelper.accessor('organization.name', {
            cell: row => <TruncatedText text={row.getValue() || i18n._('N/A')} />,
            header: () => i18n._('Organization'),
            meta: {
              collapseAt: showMerchantColumn && showOrganizationColumn ? 'full' : 'sm',
            },
          }),
        ]
      : []),
    accountActivityDetailColumnsHelper.accessor(
      row =>
        formatAmount(
          row.amount * (row.direction === 'outgoing' ? -1 : 1),
          locale,
          row.currency as CurrencyCode
        ),
      {
        id: 'amount',
        header: () => i18n._('Amount'),
        size: TableColumnSize.XXS,
      }
    ),
    accountActivityDetailColumnsHelper.accessor('category', {
      cell: row => {
        return formatTransferCategory(row.row.original);
      },
      header: () => i18n._('Category'),
      size: TableColumnSize.XXS,
      meta: {
        visibleAt: 'sm',
      },
    }),
    accountActivityDetailColumnsHelper.accessor('pspPaymentReference', {
      header: () => i18n._('Transaction ID'),
      cell: info =>
        info.row.original.pspPaymentReference ? (
          <Link
            className="text-sm text-primary-600"
            to={generatePath(paymentDetailsPath, {
              pspReferenceId: info.row.original.pspPaymentReference,
            })}
          >
            {info.row.original.pspPaymentReference}
          </Link>
        ) : info.row.original.reference ? (
          info.row.original.reference
        ) : (
          i18n._('N/A')
        ),
      size: TableColumnSize.M,
      meta: {
        visibleAt: 'lg',
      },
    }),
    accountActivityDetailColumnsHelper.accessor('batchId', {
      header: () => i18n._('Batch ID'),
      cell: info => info.row.original.batchId || i18n._('N/A'),
      size: TableColumnSize.M,
      meta: {
        visibleAt: '2xl',
      },
    }),

    accountActivityDetailColumnsHelper.accessor('description', {
      header: () => i18n._('Description'),
      cell: row =>
        formatDescription(row.getValue(), i18n, 'merchantAccount' in row.row.original) || 'N/A',
      meta: {
        visibleAt: 'full',
      },
    }),
  ];
};

export const formatDescription = (
  adyenDescription: string | null,
  i18n: I18n,
  isPortal: boolean
) => {
  if (
    adyenDescription === 'Aggregated Fee for AdyenCommission, AdyenMarkup, Interchange, SchemeFee'
  ) {
    return isPortal
      ? i18n._('Payment Processing Fee')
      : i18n._('Aggregated Fee for Adyen Commission, Adyen Markup, Interchange, and Scheme Fee');
  }

  if (adyenDescription === 'PaymentFee') {
    return i18n._('Payment Fee');
  }

  return adyenDescription;
};
