import {AUD, CAD, EUR, GBP, NZD, USD} from '@dinero.js/currencies';
import {Currency} from 'dinero.js';
import {CurrencyCode} from '../../constants';

export const supportedCurrency: {
  [_ in CurrencyCode]: Currency<number>;
} = {
  // biome-ignore lint/style/useNamingConvention: Map
  USD: USD,
  // biome-ignore lint/style/useNamingConvention: Map
  CAD: CAD,
  // biome-ignore lint/style/useNamingConvention: Map
  EUR: EUR,
  // biome-ignore lint/style/useNamingConvention: Map
  GBP: GBP,
  // biome-ignore lint/style/useNamingConvention: Map
  AUD: AUD,
  // biome-ignore lint/style/useNamingConvention: Map
  NZD: NZD,
};
