import {useCallback, useState} from 'react';

export const useToggleWithData = <Data>(initial = false, data: Data | null = null) => {
  const [value, set] = useState<{state: boolean; data: Data | null}>({state: initial, data});
  const on = useCallback((data: Data) => {
    set({
      state: true,
      data,
    });
  }, []);
  const off = useCallback(() => {
    set({state: false, data: null});
  }, []);
  return {isOpen: value.state, data: value.data, on, off};
};
