import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  DEFAULT_CURRENCY,
  fromMinorUnits,
  maxAchSurchargePercentageFee,
  maxCreditCardSurchargePercentageFee,
  toMinorUnits,
} from '@zentact/common';
import {
  Button,
  InputCheckbox,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  getSurchargeConfigurationScheme,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

export type UpdateMerchantSurchargeConfigurationData = z.infer<
  ReturnType<typeof getSurchargeConfigurationScheme>
>;

export type UpdateMerchantSurchargeConfigurationProps = {
  merchantAccountRow: MerchantAccountDetailsOutput;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateMerchantSurchargeConfiguration = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateMerchantSurchargeConfigurationProps) => {
  const {i18n} = useLingui();
  const {locale} = useLocale();
  const {currency} = useStore();

  const currentSurchargeConfiguration = merchantAccountRow.surchargeConfiguration;
  const merchantAccountId = merchantAccountRow.id;

  const form = useForm<UpdateMerchantSurchargeConfigurationData>({
    resolver: zodResolver(getSurchargeConfigurationScheme({i18n})),
    defaultValues: {
      ...currentSurchargeConfiguration,
      creditCardPercentageFee: currentSurchargeConfiguration?.creditCardPercentageFee ?? 0,
      creditCardFlatFee: fromMinorUnits(
        currentSurchargeConfiguration?.creditCardFlatFee ?? 0,
        DEFAULT_CURRENCY
      ),
      achPercentageFee: currentSurchargeConfiguration?.achPercentageFee ?? 0,
      achFlatFee: fromMinorUnits(currentSurchargeConfiguration?.achFlatFee ?? 0, DEFAULT_CURRENCY),
    },
  });
  const {
    handleSubmit,
    formState: {errors},
    register,
    watch,
    setValue,
  } = form;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const updateMerchantMutation =
    trpc.merchantAccount.updateMerchantSurchargeConfiguration.useMutation({
      onSuccess: () => {
        refetchMerchantAccountsList();
        onClose();
        showSuccessNotification(t`Merchant Surcharge configuration updated!`);
      },
      onError: error => {
        showErrorNotification(t`Error`, error.message);
      },
    });

  const onUpdateMerchantSubmit = useCallback(
    (data: UpdateMerchantSurchargeConfigurationData) => {
      if (!merchantAccountId) {
        return;
      }
      updateMerchantMutation.mutate({
        merchantAccountId: merchantAccountId,
        surchargeConfiguration: {
          ...data,
          creditCardPercentageFee:
            data.surchargeCreditCardEnabled && data.creditCardPercentageFee
              ? data.creditCardPercentageFee
              : 0,
          creditCardFlatFee:
            data.surchargeCreditCardEnabled && data.creditCardFlatFee
              ? toMinorUnits(data.creditCardFlatFee, DEFAULT_CURRENCY)
              : 0,
          achPercentageFee:
            data.surchargeAchEnabled && data.achPercentageFee ? data.achPercentageFee : 0,
          achFlatFee:
            data.surchargeAchEnabled && data.achFlatFee
              ? toMinorUnits(data.achFlatFee, DEFAULT_CURRENCY)
              : 0,
        },
      });
      return;
    },
    [merchantAccountId]
  );

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
  const surchargeConfiguration = watch();

  useEffect(() => {
    if (!surchargeConfiguration) {
      return;
    }
    if (!surchargeConfiguration.surchargeAchEnabled) {
      setValue('achPercentageFee', 0);
      setValue('achFlatFee', 0);
    }
    if (!surchargeConfiguration.surchargeCreditCardEnabled) {
      setValue('creditCardFlatFee', 0);
      setValue('creditCardPercentageFee', 0);
    }
  }, [
    surchargeConfiguration?.surchargeAchEnabled,
    surchargeConfiguration?.surchargeCreditCardEnabled,
  ]);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Update Merchant Surcharge Configuration')}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSubmit)}
              isLoading={updateMerchantMutation.isLoading}
            >
              <Trans>Update</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSubmit)}>
        <div className="flex flex-col w-full gap-6">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium leading-6 text-gray-900">
              <Trans>Surchage Enabled Payment Methods</Trans>
            </div>
            <div className="flex gap-3">
              <InputCheckbox {...register('surchargeCreditCardEnabled')}>
                <Trans>Cards</Trans>
              </InputCheckbox>
              <InputCheckbox {...register('surchargeAchEnabled')}>
                <Trans>ACH</Trans>
              </InputCheckbox>
            </div>
          </div>
          {!!surchargeConfiguration?.surchargeCreditCardEnabled && (
            <>
              <Label
                text={t`Credit Card Percentage Fee (${0}% - ${maxCreditCardSurchargePercentageFee}%)`}
              >
                <InputText
                  {...register('creditCardPercentageFee')}
                  addonBefore="%"
                  hasError={Boolean(errors.creditCardPercentageFee)}
                />
                <ValidationError isVisible={Boolean(errors.creditCardPercentageFee)}>
                  {errors.creditCardPercentageFee?.message}
                </ValidationError>
              </Label>
              <Label text={t`Credit Card Flat Fee`}>
                <InputText
                  {...register('creditCardFlatFee')}
                  addonBefore={currencySymbol}
                  hasError={Boolean(errors.creditCardFlatFee)}
                />
                <ValidationError isVisible={Boolean(errors.creditCardFlatFee)}>
                  {errors.creditCardFlatFee?.message}
                </ValidationError>
              </Label>
            </>
          )}
          {!!surchargeConfiguration?.surchargeAchEnabled && (
            <>
              <Label text={t`ACH Percentage Fee (${0}% - ${maxAchSurchargePercentageFee}%)`}>
                <InputText
                  {...register('achPercentageFee')}
                  addonBefore="%"
                  hasError={Boolean(errors.achPercentageFee)}
                />
                <ValidationError isVisible={Boolean(errors.achPercentageFee)}>
                  {errors?.achPercentageFee?.message}
                </ValidationError>
              </Label>
              <Label text={t`ACH Flat Fee`}>
                <InputText
                  {...register('achFlatFee')}
                  addonBefore={currencySymbol}
                  hasError={Boolean(errors.achFlatFee)}
                />
                <ValidationError isVisible={Boolean(errors.achFlatFee)}>
                  {errors?.achFlatFee?.message}
                </ValidationError>
              </Label>
            </>
          )}
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
