export const supportedExtensions = ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp'];
export const supportedDefenseDocumentExtensions = ['.jpg', '.tif', '.pdf'];

export const MAX_UPLOAD_FILE_FIZE_MB = 5;

export const DEFAULT_IMAGE_UPLOAD_DIMENSIONS = {
  width: 2048,
  height: 1536,
};

export type ImageUploadDimensions = typeof DEFAULT_IMAGE_UPLOAD_DIMENSIONS;
