import React, {forwardRef, InputHTMLAttributes, ReactNode} from 'react';

type Size = 'sm' | 'md' | 'lg';

interface InputToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: ReactNode;
  toggleSize?: Size;
}

export const InputToggle = forwardRef<HTMLInputElement, InputToggleProps>(
  ({toggleSize = 'md', label, ...props}, ref) => {
    const baseClasses =
      "bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 peer-checked:bg-primary-600 peer-disabled:opacity-70";

    const sizeClasses = {
      sm: 'w-9 h-5 after:top-[2px] after:start-[2px] after:h-4 after:w-4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full',
      md: 'w-11 h-6 after:top-[2px] after:start-[2px] after:h-5 after:w-5 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full',
      lg: 'w-14 h-7 after:top-0.5 after:start-[2px] after:h-6 after:w-6 peer-checked:after:translate-x-7 rtl:peer-checked:after:-translate-x-7',
    };

    const currentSizeClasses = `${baseClasses} ${sizeClasses[toggleSize] || sizeClasses.md}`;

    return (
      <div className="flex items-center">
        <label className="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" className="sr-only peer" {...props} ref={ref} />
          <div className={currentSizeClasses} />
          <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">{label}</span>
        </label>
      </div>
    );
  }
);
