import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {DeclinedPaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {
  HighlightedText,
  TruncatedText,
  getTableMeta,
  renderPaymentMethod,
} from '@zentact/ui-tailwind';
import {DeclinedPaymentActions} from './declined-payment-actions';

const columnsHelper = createColumnHelper<DeclinedPaymentListItemOutput>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
  customAttributes?: string[];
  openDetailsPanel?: (row: DeclinedPaymentListItemOutput) => void;
  showOrganizationColumn?: boolean;
  paymentMethodsFilters?: string[];
};

export const getDeclinedPaymentColumns = ({
  locale,
  i18n,
  openDetailsPanel,
  showOrganizationColumn,
}: Props) => {
  return [
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error
    columnsHelper.accessor('createdAt', {
      cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
      header: () => i18n._('Date'),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.pspReferenceId as string;
        const textContent = props.row.original.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'pspReferenceId',
      header: () => i18n._('Transaction ID'),
      size: TableColumnSize.L,
      meta: {
        visibleAt: 'sm',
      },
    }),
    columnsHelper.display({
      id: 'merchantName',
      cell: props => <TruncatedText text={props.row.original.merchantAccount.businessName} />,
      header: () => i18n._('Merchant Account'),
      meta: {
        collapseAt: 'md',
      },
    }),
    ...(showOrganizationColumn
      ? [
          columnsHelper.display({
            id: 'organizationName',
            cell: props => <TruncatedText text={props.row.original.organization.name} />,
            header: () => i18n._('Organization'),
            meta: {
              collapseAt: '2xl',
            },
          }),
        ]
      : []),
    columnsHelper.accessor(row => formatAmount(row.amount, locale, row.currency as CurrencyCode), {
      id: 'amount',
      header: props => (getTableMeta(props.table).breakpoints['2xl'] ? 'Amount' : 'Payment'),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.XS,
    }),
    columnsHelper.display({
      cell: props =>
        renderPaymentMethod(props.row.original.paymentMethod, props.row.original.cardSummary),
      id: 'paymentMethod',
      header: () => i18n._('Payment Method'),
      meta: {
        collapseAt: '2xl',
      },
      size: 140,
    }),
    columnsHelper.display({
      cell: props => props.row.original.refusalReason,
      id: 'refusalReason',
      header: () => i18n._('Refusal Reason'),
      meta: {
        collapseAt: 'lg',
      },
      size: 140,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <DeclinedPaymentActions
            row={props.row.original}
            i18n={i18n}
            openDetailsPanel={(...props) => openDetailsPanel?.(...props)}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
