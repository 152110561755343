import {useLocale} from '@/hooks/useLocale';
import {Trans} from '@lingui/macro';
import {ShopperPrimaryPaymentMethodOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {getPaymentMethodName} from '@zentact/common';
import {
  FlatPillWithDot,
  PaymentMethodIcon,
  displaySavedPaymentMethodStatusMap,
  savedPaymentMethodStatusToColor,
} from '@zentact/ui-tailwind';
import React from 'react';

type PrimarySavedMethodProps = {
  paymentMethod: ShopperPrimaryPaymentMethodOutput;
};
export const PrimarySavedMethod = ({paymentMethod}: PrimarySavedMethodProps) => {
  const {locale} = useLocale();

  return (
    <div className="sm:col-span-3">
      <div className="flex mb-2 text-sm font-medium leading-6">
        <div className="text-gray-900">
          <Trans>Primary Saved Payment Method</Trans>
        </div>
      </div>
      <div className="flex items-center gap-2 text-sm">
        <PaymentMethodIcon paymentType={paymentMethod.paymentMethod} />
        <span>{getPaymentMethodName(paymentMethod.paymentMethod, locale)}</span>
        <span>***{paymentMethod.cardLastFour}</span>
        <span>
          {paymentMethod.expiryMonth?.toString().padStart(2, '0')}/{paymentMethod.expiryYear}
        </span>
        <FlatPillWithDot
          color={savedPaymentMethodStatusToColor[paymentMethod.status] || 'blue'}
          label={displaySavedPaymentMethodStatusMap[paymentMethod.status] ?? paymentMethod.status}
        />
      </div>
    </div>
  );
};
