import {useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
};

const Portal = ({children}: PortalProps): React.ReactPortal | null => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const mount = document.getElementById('portal-root');

    if (mount && el) {
      mount.appendChild(el);
    }

    return () => {
      if (mount && el) {
        mount.removeChild(el);
      }
    };
  }, [el]);

  return el ? createPortal(children, el) : null;
};

export default Portal;
