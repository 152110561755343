import {StoreProvider} from '@/store';
import {AppProvider} from './AppProvider';

export const App = () => {
  return (
    <StoreProvider>
      <AppProvider />
    </StoreProvider>
  );
};
