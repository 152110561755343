import {cn} from '../utils';

export type FlatFillColors = 'red' | 'yellow' | 'green' | 'blue' | 'gray';

type Props = {
  label: string;
  color: FlatFillColors;
};

const colorVariants = {
  blue: 'bg-blue-100 text-blue-800',
  red: 'bg-red-100 text-red-800',
  green: 'bg-green-100 text-green-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  gray: 'bg-gray-100 text-gray-800',
};

const fillColorVariants = {
  blue: 'fill-blue-500',
  red: 'fill-red-500',
  green: 'fill-green-500',
  yellow: 'fill-yellow-500',
  gray: 'fill-gray-500',
};

export const FlatPillWithDot = ({color, label}: Props) => {
  return (
    <span
      className={cn(
        'inline-flex w-max items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium',
        colorVariants[color]
      )}
    >
      <svg
        className={cn('h-1.5 w-1.5', fillColorVariants[color])}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {label}
    </span>
  );
};
