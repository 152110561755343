import {HTTPHeaders, createTRPCReact, httpBatchLink} from '@trpc/react-query';
import {type ServerRouter} from '@zentact/api';

export const trpc = createTRPCReact<ServerRouter>();

export let accessToken: string | null;
let checkoutId: string;

export const setAccessToken = (newToken: string | null) => {
  accessToken = newToken;
};

export const setCheckoutToken = (newCheckoutId: string) => {
  checkoutId = newCheckoutId;
};

const getAuthorizationHeader = (): HTTPHeaders => {
  return {
    ...(checkoutId && {'x-checkout-id': checkoutId}),
    ...(accessToken && {authorization: `Bearer ${accessToken}`}),
  };
};

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_TRPC_URL,
      headers: getAuthorizationHeader,
    }),
  ],
});
