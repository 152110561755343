import {ColorCard} from '.';

export const ColorCards = ({
  colorPalette,
}: {
  colorPalette: {[key: string]: string};
}) => (
  <div className="grid grid-cols-3 gap-4 sm:grid-cols-5 md:grid-cols-5 xl:grid-cols-9">
    {Object.entries(colorPalette || {}).map((paletteItem, i) => {
      const color = paletteItem?.[1] as string;
      return (
        <ColorCard
          key={`color-card${i}`}
          {...(i === 4
            ? {value: 'Primary-500', color, className: 'border-black-100'}
            : {value: color, color})}
        />
      );
    })}
  </div>
);
