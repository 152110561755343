import {useAddressAutocomplete} from '@/hooks/useAddressAutocomplete';
import {AddressLookupItem} from '@adyen/adyen-web/dist/types/components/internal/Address/types';
import {Trans, t} from '@lingui/macro';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {
  InputSelect,
  InputText,
  Label,
  Typeahead,
  Typography,
  ValidationError,
  useToggle,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {InitiateAdyenCustomCardComponentFunction} from '../adyen-components/use-adyen-custom-card';
import {CheckoutBetaV2FormData, checkoutSupportedCountries} from '../checkout-schema';

type SchemePaymentMethodFormProps = {
  form: UseFormReturn<CheckoutBetaV2FormData>;
  checkout: GetCheckoutV2Output;
  initiateAdyenCustomCardComponent: InitiateAdyenCustomCardComponentFunction;
};

export const SchemePaymentMethodForm = ({
  checkout,
  form,
  initiateAdyenCustomCardComponent,
}: SchemePaymentMethodFormProps) => {
  const {initialize: initGoogleMapsApi, getAddressPredictions} = useAddressAutocomplete(
    checkoutSupportedCountries
  );
  const [predictions, setPredictions] = useState<AddressLookupItem[]>([]);
  const [isBillingAddressSectionShown, showAddressSection] = useToggle(false);

  const {
    register,
    setValue,
    control,
    formState: {errors},
  } = form;

  const onSelectAddress = (value: string, onChange: (v: string) => void) => {
    const addressId = Array.isArray(value) ? value[0] : value;
    const address = predictions.find(prediction => prediction.id === addressId);
    if (!address) {
      onChange(value);
      return;
    }
    onChange('');
    showAddressSection();
    setValue('paymentMethod.scheme.billingAddress.country', address.country || '', {
      shouldValidate: true,
    });
    setValue('paymentMethod.scheme.billingAddress.city', address.city || '', {
      shouldValidate: true,
    });
    setValue(
      'paymentMethod.scheme.billingAddress.houseNumberOrName',
      address.houseNumberOrName || '',
      {
        shouldValidate: true,
      }
    );
    setValue('paymentMethod.scheme.billingAddress.street', address.street || '', {
      shouldValidate: true,
    });
    setValue('paymentMethod.scheme.billingAddress.stateOrProvince', address.stateOrProvince || '', {
      shouldValidate: true,
    });
    setValue('paymentMethod.scheme.billingAddress.postalCode', address.postalCode || '', {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    initGoogleMapsApi();
  }, []);

  useEffect(() => {
    if (!checkout) {
      return;
    }

    const domNode = document.getElementById('customCard-container');
    if (!domNode) {
      return;
    }

    initiateAdyenCustomCardComponent(domNode, checkout, setCardErrors);
  }, [checkout, initiateAdyenCustomCardComponent]);

  const handleAddressLineChange = async (address: string) => {
    setValue('paymentMethod.scheme.billingAddress.searchAddress', address);
    if (address.length === 0) return;
    const predictionItems = await getAddressPredictions(address);
    setPredictions(predictionItems);
  };

  const [cardErrors, setCardErrors] = useState<{
    encryptedCardNumber: {message: string} | null;
    encryptedExpiryDate: {message: string} | null;
    encryptedSecurityCode: {message: string} | null;
  }>({
    encryptedCardNumber: null,
    encryptedExpiryDate: null,
    encryptedSecurityCode: null,
  });

  return (
    <div id="customCard-container">
      <Label className="mt-2" text={t`Card Number`}>
        <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
          <span data-cse="encryptedCardNumber" />
        </div>
        <ValidationError isVisible={Boolean(cardErrors.encryptedCardNumber)}>
          {cardErrors.encryptedCardNumber?.message}
        </ValidationError>
      </Label>
      <div className="flex flex-row items-stretch gap-x-4 gap-y-4">
        <Label className="mt-2" text={t`Expiry Date`}>
          <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
            <span data-cse="encryptedExpiryDate" />
          </div>
          <ValidationError isVisible={Boolean(cardErrors.encryptedExpiryDate)}>
            {cardErrors.encryptedExpiryDate?.message}
          </ValidationError>
        </Label>
        <Label className="mt-2" text={t`Security Code`}>
          <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
            <span data-cse="encryptedSecurityCode" />
          </div>
          <ValidationError isVisible={Boolean(cardErrors.encryptedSecurityCode)}>
            {cardErrors.encryptedSecurityCode?.message}
          </ValidationError>
        </Label>
      </div>
      <Typography variant="header-form-section" className="mt-8">
        <Trans>Cardholder</Trans>
      </Typography>
      <Label className="mt-2" text={t`Full Name`}>
        <InputText
          {...register('paymentMethod.scheme.holderName', {
            required: true,
          })}
          placeholder="John Doe"
          containerClassName="h-10"
          inputClassName="sm:text-base"
        />
        <ValidationError isVisible={Boolean(errors.paymentMethod?.scheme?.holderName?.message)}>
          {errors.paymentMethod?.scheme?.holderName?.message}
        </ValidationError>
      </Label>
      <Label
        className="mt-4 gap-y-4"
        as="div"
        text={
          <div className="flex items-center justify-between">
            <div>
              <Trans>Billing Address</Trans>
            </div>
            {!isBillingAddressSectionShown && (
              <button
                type="button"
                className="text-sm cursor-pointer w-fit text-primary-500 hover:text-primary-400"
                onClick={showAddressSection}
              >
                <Trans>+ Enter Address Manually</Trans>
              </button>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-2 col-span-full">
          <Controller
            name="paymentMethod.scheme.billingAddress.searchAddress"
            control={control}
            render={({field}) => (
              <Typeahead
                {...field}
                onSelect={(value: string) => {
                  onSelectAddress(value, field.onChange);
                }}
                options={predictions.map(item => ({
                  id: item.id,
                  label: item.name,
                }))}
                placeholder={t`Search`}
                onChange={handleAddressLineChange}
                wrapperClassName="h-10"
                inputClassName="sm:text-base"
                showSearchIcon
              />
            )}
          />
          {!isBillingAddressSectionShown && (
            <ValidationError
              isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.street)}
            >
              {errors.paymentMethod?.scheme?.billingAddress?.street?.message}
            </ValidationError>
          )}
        </div>
        {isBillingAddressSectionShown && (
          <>
            <div className="flex flex-col gap-2 sm:col-span-3">
              <Controller
                name="paymentMethod.scheme.billingAddress.country"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    options={
                      [
                        {
                          id: 'US',
                          label: t`United States`,
                        },
                        {
                          id: 'CA',
                          label: t`Canada`,
                        },
                        {
                          id: 'AU',
                          label: t`Australia`,
                        },
                        {
                          id: 'GB',
                          label: t`United Kingdom`,
                        },
                        {
                          id: 'NZ',
                          label: t`New Zealand`,
                        },
                      ] satisfies Array<{
                        id: (typeof checkoutSupportedCountries)[number];
                        label: string;
                      }>
                    }
                    placeholder={t`Country`}
                    buttonClassName="h-10 sm:text-base"
                  />
                )}
              />
              <ValidationError
                isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.country)}
              >
                {errors.paymentMethod?.scheme?.billingAddress?.country?.message}
              </ValidationError>
            </div>
            <div className="flex flex-col gap-2 col-span-full">
              <InputText
                {...register('paymentMethod.scheme.billingAddress.street', {required: true})}
                placeholder={t`Address`}
                containerClassName="h-10"
                inputClassName="w-40 sm:text-base"
              />
              <ValidationError
                isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.street)}
              >
                {errors.paymentMethod?.scheme?.billingAddress?.street?.message}
              </ValidationError>
            </div>
            <div className="flex flex-col gap-2 col-span-full">
              <InputText
                {...register('paymentMethod.scheme.billingAddress.houseNumberOrName')}
                placeholder={t`Apartment / Suite (optional)`}
                containerClassName="h-10"
                inputClassName="w-40 sm:text-base"
              />
              <ValidationError
                isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.houseNumberOrName)}
              >
                {errors.paymentMethod?.scheme?.billingAddress?.houseNumberOrName?.message}
              </ValidationError>
            </div>
            <div className="flex flex-col gap-2 col-span-full">
              <InputText
                {...register('paymentMethod.scheme.billingAddress.city')}
                placeholder={t`City`}
                containerClassName="h-10"
                inputClassName="w-40 sm:text-base"
              />
              <ValidationError
                isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.city)}
              >
                {errors.paymentMethod?.scheme?.billingAddress?.city?.message}
              </ValidationError>
            </div>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-3">
                <div className="flex flex-col w-full gap-2">
                  <InputText
                    {...register('paymentMethod.scheme.billingAddress.stateOrProvince', {
                      required: true,
                    })}
                    placeholder={t`State`}
                    containerClassName="h-10"
                    inputClassName="w-40 sm:text-base"
                  />
                  <ValidationError
                    isVisible={Boolean(
                      errors.paymentMethod?.scheme?.billingAddress?.stateOrProvince
                    )}
                  >
                    {errors.paymentMethod?.scheme?.billingAddress?.stateOrProvince?.message}
                  </ValidationError>
                </div>
              </div>
              <div className="col-span-3">
                <div className="flex flex-col gap-2 md:w-full">
                  <InputText
                    {...register('paymentMethod.scheme.billingAddress.postalCode', {
                      required: true,
                    })}
                    placeholder={t`ZIP Code`}
                    containerClassName="h-10"
                    inputClassName="w-40 sm:text-base"
                  />
                  <ValidationError
                    isVisible={Boolean(errors.paymentMethod?.scheme?.billingAddress?.postalCode)}
                  >
                    {errors.paymentMethod?.scheme?.billingAddress?.postalCode?.message}
                  </ValidationError>
                </div>
              </div>
            </div>
          </>
        )}
      </Label>
    </div>
  );
};
