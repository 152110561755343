import {useLingui} from '@lingui/react';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {Link} from 'react-router-dom';
import {useMediaQuery} from 'usehooks-ts';

const minDepthToStartTruncating = 4;
const maxDepthToShowWhenTruncating = 2;

type Props = {
  pages?: {
    name: string;
    href: string;
    current?: boolean;
  }[];
  currentDashboard?: boolean;
};

export const Breadcrumbs = ({pages, currentDashboard = false}: Props) => {
  const {i18n} = useLingui();
  const isMobile = useMediaQuery(`(max-width: ${tailwindDefaultBreakpoints.sm})`);
  const isTruncating = isMobile && (pages?.length ?? 0) + 1 >= minDepthToStartTruncating;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        <li>
          <div>
            <Link
              to="/"
              className="text-sm font-medium text-gray-600 hover:text-gray-700"
              aria-current={currentDashboard ? 'page' : undefined}
            >
              {!isTruncating ? i18n._('Dashboard') : '...'}
            </Link>
          </div>
        </li>
        {pages?.map((page, i) => {
          const breadcrumbName =
            isTruncating && i < pages.length - maxDepthToShowWhenTruncating ? '...' : page.name;
          return (
            <li key={page.name}>
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 text-gray-300 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                {page.current ? (
                  <span className="ml-1 text-sm font-medium text-primary-600">
                    {breadcrumbName}
                  </span>
                ) : (
                  <Link
                    to={page.href}
                    className={'ml-1 text-sm font-medium text-gray-600 hover:text-gray-700'}
                  >
                    {breadcrumbName}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
