import {RoutePath} from '@/components/layout/navigation';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {ErrorCode} from '@zentact/common';
import {Loading} from '@zentact/ui-tailwind';

const getErrorCode = () => {
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');
  const errorDescription = params.get('error_description');

  if (error === 'invalid_request' && errorDescription === 'invitation not found or already used') {
    return ErrorCode.INVITATION_IS_EXPIRED_OR_NOT_FOUND;
  }

  if (error === 'access_denied') {
    return ErrorCode.ACCESS_DENIED;
  }

  return undefined;
};

export const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const errorCode = getErrorCode();

    if (errorCode) {
      navigate(RoutePath.ERROR, {state: {errorCode}});
    }
  }, []);

  return <Loading />;
};
