import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {
  InvitedOrganizationUserItem,
  InvitedTenantUserItem,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {Auth0UserRole, TableColumnSize, formatLocaleDate} from '@zentact/common';
import {FlatPillWithDot} from '../../../../elements';
import {TruncatedText} from '../../../../other';
import {getLocalizedUserRoleMap} from '../../active-users/users-list/columns';
import {UserActions} from './users-actions';

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;

const userStatusToColor: Record<string, PillProps['color']> = {
  // biome-ignore lint/style/useNamingConvention: UI name map
  Invited: 'yellow',
  'Invite Expired': 'red',
};

const getLocalizedStatusMap = (i18n: I18n): Record<string, string> => ({
  // biome-ignore lint/style/useNamingConvention: UI name map
  Invited: i18n._('Invited'),
  'Invite Expired': i18n._('Invite Expired'),
});

const columnsHelper = createColumnHelper<InvitedOrganizationUserItem | InvitedTenantUserItem>();

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  invitedUsersRefetch: () => void;
  i18n: I18n;
  mode: 'organizationUser' | 'tenantUser';
  openInvitedUserEditPanel: (row: InvitedOrganizationUserItem | InvitedTenantUserItem) => void;
};
export const getInvitedUsersColumns = ({
  trpc,
  invitedUsersRefetch,
  i18n,
  mode,
  openInvitedUserEditPanel,
}: Props) => {
  const localizedUserRoleMap = getLocalizedUserRoleMap(i18n);
  return [
    columnsHelper.accessor('name', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Name'),
    }),
    columnsHelper.accessor('email', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Contact'),
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor('roles', {
      cell: info => {
        const roles = (info.getValue() as string[]).map((role: string) => {
          const localizedRole = localizedUserRoleMap[role as Auth0UserRole];
          if (localizedRole) {
            return localizedRole;
          }

          return role;
        });
        return roles.join(', ') || '-';
      },
      header: () => i18n._('Role'),
      size: TableColumnSize.XXS,
      meta: {
        collapseAt: '2xl',
      },
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={userStatusToColor[info.getValue() as string] || 'blue'}
          label={getLocalizedStatusMap(i18n)[info.getValue() as string] ?? i18n._('No info')}
        />
      ),
      header: () => i18n._('Status'),
      size: TableColumnSize.XXS,
    }),
    ...(mode === 'organizationUser'
      ? [
          columnsHelper.accessor('invitedBy', {
            cell: info => info.getValue() || '-',
            header: () => i18n._('Invited By'),
            meta: {
              visibleAt: 'sm',
            },
          }),
        ]
      : []),
    columnsHelper.accessor('createdAt', {
      header: () => i18n._('Created On'),
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime') || '-',
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: 'sm',
        collapseAt: 'xl',
      },
      size: TableColumnSize.S,
    }),
    ...(mode === 'organizationUser'
      ? [
          columnsHelper.accessor('expiresAt', {
            header: () => i18n._('Expires On'),
            cell: info => formatLocaleDate(info.getValue() as string, 'shortWithTime') || '-',
            size: TableColumnSize.S,
            meta: {
              visibleAt: '2xl',
            },
          }),
        ]
      : []),
    columnsHelper.display({
      id: 'actions',
      cell: props => {
        return (
          <div className="flex justify-center">
            <UserActions
              invitationRow={props.row.original}
              refetchInvitedUsersList={invitedUsersRefetch}
              openInvitedUserEditPanel={openInvitedUserEditPanel}
              trpc={trpc}
              mode={mode}
            />
          </div>
        );
      },
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
