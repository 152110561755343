import {useStore} from '@/store';
import {cn} from '@zentact/ui-tailwind/utils';
import {Link} from 'react-router-dom';

export const Logo = ({className}: {className?: string}) => {
  const {tenant} = useStore();
  return (
    <div className={cn('flex lg:flex-1', className)}>
      <Link to="/">
        <img
          className="h-10 w-auto"
          src={tenant?.brandConfiguration.logoUrl}
          alt={tenant?.brandConfiguration.displayName}
        />
      </Link>
    </div>
  );
};
