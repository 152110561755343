import {useLingui} from '@lingui/react';
import {LocaleCode, capitalizeCustomAttribute} from '@zentact/common';
import {ApexOptions} from 'apexcharts';
import {useMemo} from 'react';
import ReactApexChart from 'react-apexcharts';
import {cn, getPalleteFromPrimaryColor} from '../..';

export type PaymentMethodsPieChartProps = {
  paymentMethods: Array<{_count: number; paymentMethod: string}>;
  locale: LocaleCode;
  width?: number;
  hideLabel?: boolean;
  primaryColorHex?: string;
};

export const PaymentMethodsPieChart = ({
  paymentMethods,
  primaryColorHex = '#56BEB0',
  width = 350,
  hideLabel,
}: PaymentMethodsPieChartProps) => {
  const i18n = useLingui();

  const colors = useMemo(() => getPalleteFromPrimaryColor(primaryColorHex), [primaryColorHex]);

  const values = paymentMethods.map(p => p._count);
  const options: ApexOptions = {
    chart: {
      type: 'pie',
      width,
    },
    colors: [colors['600'], colors['500'], colors['400'], colors['300'], colors['200']],
    dataLabels: {
      // @ts-ignore
      formatter: (val, opts) => {
        const name: string = opts.w.globals.labels[opts.seriesIndex];
        return [name, `${Number(val).toFixed(1)}%`];
      },
      dropShadow: {
        enabled: true,
      },
    },
    labels: paymentMethods.map(p => getPaymentMethodTranslations(p.paymentMethod)),
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15,
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div
      className={cn('flex flex-col items-center flex-grow gap-y-3', hideLabel && 'justify-center')}
    >
      {!hideLabel && (
        <div className="text-lg font-medium text-gray-900">{i18n._('Payment Methods')}</div>
      )}
      {values.length ? (
        <ReactApexChart series={values} options={options} type="pie" width={width} />
      ) : (
        <div className="flex justify-center text-sm text-gray-400">{i18n._('No Data')}</div>
      )}
    </div>
  );
};

const getPaymentMethodTranslations = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'mc':
      return 'MasterCard';
    case 'discover':
      return 'Discover';
    case 'applepay':
      return 'ApplePay';
    case 'amex_applepay':
      return 'Amex ApplePay';
    case 'mc_applepay':
      return 'MasterCard ApplePay';
    case 'discover_applepay':
      return 'Discover ApplePay';
    case 'directdebit_GB':
      return 'BACS Direct Debit';
    default:
      return capitalizeCustomAttribute(paymentMethod);
  }
};
