import {DateTime} from 'luxon';
import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {z} from 'zod';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const useTypedSearchParams = <T extends z.ZodType<any, any>>(schema: T) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const typedSearchParams = useMemo<z.infer<T>>(() => {
    const paramsObj = Object.fromEntries(searchParams.entries());

    const result = schema.safeParse(paramsObj);
    if (!result.success) {
      console.error('Failed to parse search params:', result.error);
      return {};
    }
    return result.data;
  }, [searchParams]);

  const setTypedSearchParams = useCallback(
    (params: z.infer<T>) => {
      const result = schema.safeParse(params);
      if (!result.success) {
        console.error('setTypedSearchParams error', result.error);
        return;
      }

      if (Object.keys(result.data).length === 0) {
        const clearedSearchParams = new URLSearchParams();
        setSearchParams(clearedSearchParams);
        return;
      }
      const updatedParams = new URLSearchParams(searchParams);
      for (const [key, value] of Object.entries(result.data)) {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          updatedParams.delete(key);
          continue;
        }
        if (value instanceof Date) {
          const dateString = DateTime.fromJSDate(value).toISODate();
          if (!dateString) continue;
          updatedParams.set(key, dateString);
        } else if (Array.isArray(value) && value.length > 0) {
          updatedParams.set(key, value.join(','));
        } else if (typeof value === 'object') {
          updatedParams.set(key, encodeURIComponent(JSON.stringify(value)));
        } else {
          updatedParams.set(key, value.toString());
        }
      }
      setSearchParams(updatedParams);
    },
    [searchParams]
  );

  return {typedSearchParams, setTypedSearchParams};
};
