import {trpc} from '@/api/trpcClient';
import {Trans, t} from '@lingui/macro';
import {MAX_UPLOAD_FILE_FIZE_MB} from '@zentact/common';
import {
  FileUpload,
  Typography,
  uploadFileToCloudStorage,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';

type Props = {
  registrationSessionId: string;
  uploadedDocumentName: string | null;
  title: string;
  description: string;
  supportedExtensions: string[];
  refetchRegistrationStatus: () => void;
};

export const UploadApplicationDocument = ({
  title,
  description,
  registrationSessionId,
  supportedExtensions,
  uploadedDocumentName,
  refetchRegistrationStatus,
}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const trpcContext = trpc.useUtils();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(!!uploadedDocumentName);

  const onFileSelect = useCallback(
    async (file: File) => {
      setIsLoading(true);
      setIsUploaded(false);
      const fileName = file.name;
      try {
        const {signedUrl} =
          await trpcContext.merchantRegistration.generateApplicationDocumentUploadLink.fetch({
            fileName,
            registrationSessionId,
          });

        await uploadFileToCloudStorage(signedUrl, file);

        await trpcContext.client.merchantRegistration.saveApplicationDocument.mutate({
          uploadedDocumentName: fileName,
          registrationSessionId,
        });
        showSuccessNotification(t`File was successfully uploaded`);
        setIsUploaded(true);
        refetchRegistrationStatus();
      } catch (e) {
        showErrorNotification(t`Failed to upload a file`);
        console.error(e);
      }

      setIsLoading(false);
    },
    [showErrorNotification, showSuccessNotification, setIsLoading]
  );
  return (
    <div>
      <label
        htmlFor="company-website"
        className="block mb-1 text-sm font-medium leading-6 text-gray-900"
      >
        <Trans>{title}</Trans>
      </label>
      <Typography variant="paragraph" className="mb-3">
        <Trans>{description}</Trans>
      </Typography>
      <FileUpload
        isLoading={isLoading}
        onSelectFile={onFileSelect}
        allowedExt={supportedExtensions}
        localeText={{
          upload: !isUploaded ? t`Click to upload` : t`Click to re-upload`,
          dragndrop: t`or drag and drop`,
        }}
        type="file"
        maxSizeMb={MAX_UPLOAD_FILE_FIZE_MB}
        isUploaded={isUploaded}
      />
    </div>
  );
};
