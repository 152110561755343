import {trpc} from '@/api/trpcClient';
import {t} from '@lingui/macro';
import {ShopperPrimaryPaymentMethodOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {Typeahead} from '@zentact/ui-tailwind';
import {Controller, UseFormReturn} from 'react-hook-form';
import {useDebounce} from 'use-debounce';
import {TakePaymentFormData} from './get-take-payment-form-schema';

type Props = {
  form: UseFormReturn<TakePaymentFormData>;
  setPaymentMethod: (d: ShopperPrimaryPaymentMethodOutput | null) => void;
};
export const EmailTypeahead = ({form, setPaymentMethod}: Props) => {
  const {watch, setError, control} = form;
  const emailWatch = watch('email');
  const merchantAccountId = watch('merchantAccountId');

  const [email] = useDebounce(emailWatch, 300);

  const {data: shopperEmailPredictions} =
    trpc.savedPaymentMethod.getShopperWithPrimaryPaymentMethodPredictions.useQuery(
      {
        merchantAccountId,
        shopperEmail: email,
      },
      {
        enabled: !!merchantAccountId && !!email,
        onSuccess: res => {
          if (email === res?.query.shopperEmail && !res?.rows?.find(row => row.email === email)) {
            setPaymentMethod(null);
          }
        },
      }
    );

  const onSelectTypeaheadEmail = (value: string) => {
    if (value && !merchantAccountId) {
      setError('email', {
        message: t`Please choose merchant account before selecting a customer`,
      });
      return;
    }
    const row = shopperEmailPredictions?.rows?.find(
      prediction => prediction.merchantShopperId === value
    );
    if (!row) {
      return;
    }
    setPaymentMethod(row);
  };

  return (
    <Controller
      name="email"
      control={control}
      render={({field}) => (
        <Typeahead
          {...field}
          onSelect={onSelectTypeaheadEmail}
          label={t`Customer Email`}
          options={
            shopperEmailPredictions?.rows?.map(item => ({
              id: item.merchantShopperId,
              label: item.email || item.merchantShopperId,
            })) ?? []
          }
        />
      )}
    />
  );
};
