import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  InputToggle,
  useNotification,
} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

enum MerchantActions {
  DEACTIVATE = 'deactivate',
  RESEND = 'resend',
  REVOKE = 'revoke',
  EDIT_AND_RESEND = 'editAndResend',
  RESUME = 'resume',
  CANCEL_ONBOARDING = 'cancelOnboarding',
  UPDATE_CUSTOMER_SUPPORT = 'updateCustomerSupport',
  UPDATE_EMAIL_NOTIFICATIONS = 'updateEmailNotififications',
  UPDATE_SURCHARGE_CONFIGURATION = 'updateSurchargeConfiguration',
  DETAILS = 'details',
}

const statusToItemsMap = (
  action: (action: MerchantActions) => void,
  allowResume: boolean,
  openDetails?: (row: MerchantAccountsListItem | null) => void,
  // not passed when the merchant is not "active" or if the context user is not an organization admin
  openUpdateSurchargeConfigurationPanel?: (row: MerchantAccountsListItem | null) => void
): Record<
  MerchantAccountPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => ({
  [MerchantAccountPublicStatus.ACTIVE]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    {
      name: t`Update Customer Support`,
      onClick: () => action(MerchantActions.UPDATE_CUSTOMER_SUPPORT),
    },
    {
      name: t`Update Email Notifications`,
      onClick: () => action(MerchantActions.UPDATE_EMAIL_NOTIFICATIONS),
    },
    ...(openUpdateSurchargeConfigurationPanel
      ? [
          {
            name: t`Update Surcharge Configuration`,
            onClick: () => action(MerchantActions.UPDATE_SURCHARGE_CONFIGURATION),
          },
        ]
      : []),
  ],
  [MerchantAccountPublicStatus.REJECTED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume
      ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}]
      : [{name: t`Resend`, onClick: () => action(MerchantActions.RESEND)}]),
  ],
  [MerchantAccountPublicStatus.ONBOARDING]: [
    ...(allowResume
      ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}]
      : [{name: t`Resend`, onClick: () => action(MerchantActions.RESEND)}]),
    {
      name: t`Cancel Onboarding`,
      onClick: () => action(MerchantActions.CANCEL_ONBOARDING),
      itemClassName: 'text-red-500',
    },
  ],
  [MerchantAccountPublicStatus.AWAITING_INFO]: [
    ...(allowResume
      ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}]
      : [{name: t`Resend`, onClick: () => action(MerchantActions.RESEND)}]),
    {
      name: t`Cancel Onboarding`,
      onClick: () => action(MerchantActions.CANCEL_ONBOARDING),
      itemClassName: 'text-red-500',
    },
  ],
  [MerchantAccountPublicStatus.DEACTIVATED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.INVITED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
    {name: t`Edit & Resend`, onClick: () => action(MerchantActions.EDIT_AND_RESEND)},
    {
      name: t`Revoke Invitation`,
      onClick: () => action(MerchantActions.REVOKE),
      itemClassName: 'text-red-500',
    },
  ],
  [MerchantAccountPublicStatus.INITIATED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
    {name: t`Edit & Invite`, onClick: () => action(MerchantActions.EDIT_AND_RESEND)},
    {
      name: t`Revoke`,
      onClick: () => action(MerchantActions.REVOKE),
      itemClassName: 'text-red-500',
    },
  ],
  [MerchantAccountPublicStatus.INVITE_EXPIRED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    {name: t`Edit & Resend`, onClick: () => action(MerchantActions.EDIT_AND_RESEND)},
  ],
  [MerchantAccountPublicStatus.INVITE_REVOKED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    {name: t`Edit & Resend`, onClick: () => action(MerchantActions.EDIT_AND_RESEND)},
  ],
  [MerchantAccountPublicStatus.ONBOARDING_CANCELLED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    {name: t`Resend Invitation`, onClick: () => action(MerchantActions.RESEND)},
  ],
  [MerchantAccountPublicStatus.AWAITING_REVIEW]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.REVIEW_REJECTED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
});

export type MerchantAccountActionsProps = {
  merchantAccountRow: MerchantAccountsListItem | MerchantAccountDetailsOutput;
  businessName: string;
  registrationSessionId: string;
  userId: string | null;
  inviteeEmail: string;
  refetchMerchantAccountsList: () => void;
  openDetails?: (row: MerchantAccountsListItem | null) => void;
  openEditAndResendPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateCustomerSupportPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateEmailNotificationsPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateSurchargeConfigurationPanel?: (
    // not passed when the merchant is not "active" or if the context user is not an organization admin
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  viewType: 'dots-button' | 'chevron-button';
};

export const MerchantAccountActions = ({
  merchantAccountRow,
  registrationSessionId,
  userId,
  inviteeEmail,
  businessName,
  refetchMerchantAccountsList,
  openDetails,
  openEditAndResendPanel,
  openUpdateCustomerSupportPanel,
  openUpdateEmailNotificationsPanel,
  openUpdateSurchargeConfigurationPanel,
  viewType,
}: MerchantAccountActionsProps) => {
  const {user} = useAuth0();
  const {authProfile} = useStore();
  const navigate = useNavigate();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [cancelOnboardingConfirmationOpen, setCancelOnboardingConfirmationOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);

  const userMerchantsList = trpc.merchantAccount.getMerchantAccountsList.useQuery({
    where: {
      ...(userId && {
        userId,
      }),
    },
  });
  const filteredUserMerchantsList = userMerchantsList.data?.rows.filter(
    merchant =>
      merchant.status === MerchantAccountPublicStatus.ONBOARDING ||
      merchant.status === MerchantAccountPublicStatus.INVITED ||
      merchant.status === MerchantAccountPublicStatus.ACTIVE
  );

  const disableToggle = (filteredUserMerchantsList ?? []).length > 1;

  const deleteOrganizationUserAccount = trpc.users.deleteOrganizationUserAccount.useMutation({
    onSuccess: () => {
      refetchMerchantAccountsList();
      showSuccessNotification(t`User deleted`, t`You have successfully deleted user`);
    },
  });

  const revokeMutation = trpc.merchantRegistration.revokeInvitation.useMutation();

  const resendMutation = trpc.merchantRegistration.resendInvitation.useMutation({
    onSuccess: () => {
      refetchMerchantAccountsList();
      showSuccessNotification(
        t`Invitation resent!`,
        t`You have successfully resent the invitation to ${businessName}.`
      );
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const handleAction = (action: MerchantActions) => {
    if (action === MerchantActions.UPDATE_CUSTOMER_SUPPORT && openUpdateCustomerSupportPanel) {
      openUpdateCustomerSupportPanel(merchantAccountRow);
    }
    if (
      action === MerchantActions.UPDATE_EMAIL_NOTIFICATIONS &&
      openUpdateEmailNotificationsPanel
    ) {
      openUpdateEmailNotificationsPanel(merchantAccountRow);
    }
    if (action === MerchantActions.DETAILS && openDetails && 'firstName' in merchantAccountRow) {
      openDetails(merchantAccountRow);
    }

    if (action === MerchantActions.EDIT_AND_RESEND && openEditAndResendPanel) {
      openEditAndResendPanel(merchantAccountRow);
    }

    if (action === MerchantActions.REVOKE) {
      revokeMutation.mutate(
        {registrationSessionId},
        {
          onSuccess: () => {
            refetchMerchantAccountsList();
            showSuccessNotification(
              t`Invitation revoked!`,
              t`You have successfully revoked the invitation.`
            );
          },
        }
      );
    }

    if (action === MerchantActions.CANCEL_ONBOARDING) {
      setCancelOnboardingConfirmationOpen(true);
    }

    if (action === MerchantActions.RESEND) {
      resendMutation.mutate({
        registrationSessionId,
        inviterName: user?.name || user?.email || 'Unknown',
      });
    }

    if (action === MerchantActions.RESUME) {
      navigate(`/registration/${registrationSessionId}`);
    }

    if (
      action === MerchantActions.UPDATE_SURCHARGE_CONFIGURATION &&
      openUpdateSurchargeConfigurationPanel
    ) {
      openUpdateSurchargeConfigurationPanel(merchantAccountRow);
      return;
    }
  };

  const canResume = Boolean(userId) && userId === user?.sub;

  const menuItems = statusToItemsMap(
    handleAction,
    canResume,
    openDetails,
    openUpdateSurchargeConfigurationPanel
  )[merchantAccountRow.status];
  if (!menuItems || !menuItems.length) return null;

  const handleCancelOnboarding = () => {
    if (userId && deleteUser) {
      deleteOrganizationUserAccount.mutate({userId});
    }
    revokeMutation.mutate(
      {registrationSessionId},
      {
        onSuccess: () => {
          refetchMerchantAccountsList();
          setCancelOnboardingConfirmationOpen(false);
          showSuccessNotification(
            t`Invitation revoked!`,
            t`You have successfully revoked the invitation.`
          );
        },
      }
    );
  };

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
      />
      {cancelOnboardingConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={cancelOnboardingConfirmationOpen}
          setOpen={setCancelOnboardingConfirmationOpen}
          handleAction={handleCancelOnboarding}
          localeText={{
            title: t`Cancel onboarding`,
            description: t`Are you sure you would like to cancel merchant onboarding for ${businessName}?`,
            confirm: t`Cancel Onboarding`,
            cancel: t`Cancel`,
          }}
        >
          {authProfile.data?.isOrganizationAdmin && !disableToggle ? (
            <div className="items-center justify-center px-6 mb-3 sm:flex">
              <InputToggle
                toggleSize="md"
                onClick={() => setDeleteUser(prev => !prev)}
                defaultChecked={false}
                // biome-ignore lint/complexity/noUselessFragments: <explanation>
                label={<></>}
              />
              <p className="text-sm text-gray-500">
                <Trans>
                  Do you also want to delete the user {inviteeEmail} from the{' '}
                  {merchantAccountRow.organization.name} organization?
                </Trans>
              </p>
            </div>
          ) : undefined}
        </AlertOverlayWithConfirmation>
      )}
    </>
  );
};
