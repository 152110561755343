import {CurrencyCode} from '../../constants';
import {supportedCurrency} from './supportedCurrency';

/**
 * Converts a decimal to minor units.
 * @param {number} decimalAmount - The amount to be converted. For example, 11.11.
 * @param {CurrencyCode} currency - The currency code of the amount to be converted.
 * @param {number} scale - Optional. The scale to use if the currency is not supported.
 * @returns The amount in minor units.
 * @example
 *
 * const amountMinorUnits = toMinorUnits(11.11, CurrencyCode.USD);
 *
 * console.log(amountMinorUnits); // 1111
 *
 */

export const toMinorUnits = (decimalAmount: number, currency: CurrencyCode, scale?: number) => {
  const _currency = supportedCurrency[currency];
  if (!_currency) {
    throw new Error(`currency ${currency} is not supported`);
  }
  const factor = Number(_currency.base) ** _currency.exponent || scale;
  if (!factor) {
    throw new Error('factor is invalid');
  }
  return Math.round(decimalAmount * factor);
};
