import {RoutePath} from '@/components/layout/navigation';
import {MutationCache, QueryCache, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {trpc, trpcClient} from './trpcClient';

const errorRedirectRoutes: Record<string, RoutePath> = {
  [ErrorCode.SESSION_EXPIRED]: RoutePath.SESSION_EXPIRED,
};

export function TrpcProvider({children}: {children: React.ReactNode}): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: error => {
            if (isFormattedTrpcError(error)) {
              const errorRoute = errorRedirectRoutes[error.data.errorCode];
              if (errorRoute && window.location.pathname !== errorRoute) {
                navigate(errorRoute);
              }
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: error => {
            if (isFormattedTrpcError(error)) {
              const errorRoute = errorRedirectRoutes[error.data.errorCode];
              if (errorRoute && window.location.pathname !== errorRoute) {
                navigate(errorRoute);
              }
            }
          },
        }),
      }),
    [navigate]
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
