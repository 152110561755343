import {useStore} from '@/store';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {ContentCard, Typography} from '@zentact/ui-tailwind';
import {merchantRegistrationStepTitles} from '../../constants';

export const MerchantTenantReviewFailure = () => {
  const {tenant} = useStore();
  return (
    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
        <Typography as="h3" variant="header-lg">
          {i18n._(merchantRegistrationStepTitles(tenant?.name).step5)}
        </Typography>
      </div>
      <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
        <ContentCard type="error" title={t`${tenant?.name} declined your application`}>
          <Trans>
            Please contact us by email {tenant?.brandConfiguration.supportEmail} or phone{' '}
            {tenant?.brandConfiguration.phoneNumber} for more details.
          </Trans>
        </ContentCard>
      </div>
    </div>
  );
};
