import {useLingui} from '@lingui/react';
import type {MerchantAccountStatus} from '@prisma/client';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {InputSearchSelectFilter} from '@zentact/ui-tailwind';
import {useEffect, useMemo} from 'react';

const ALL = 'all';
type MerchantAccountFilterOption = {
  id: string;
  businessName: string;
  status: MerchantAccountStatus | MerchantAccountPublicStatus;
  onboardedAt: string | null;
};

type Props = {
  selectedMerchantAccount?: string | null;
  merchantAccountsOptions: MerchantAccountFilterOption[];
  onSelectMerchantAccount: (merchantAccountId?: string) => void;
  allLabel?: string;
  // Optional name for form submission
  name?: string;
};

export const MerchantAccountsPickerFilter = ({
  selectedMerchantAccount,
  merchantAccountsOptions,
  onSelectMerchantAccount,
  allLabel,
  name,
}: Props) => {
  const {i18n} = useLingui();

  const filteredOptions = useMemo(() => {
    const {activeOptions, inactiveOptions} = merchantAccountsOptions
      .filter(option => option.onboardedAt !== null)
      .reduce<{
        activeOptions: MerchantAccountFilterOption[];
        inactiveOptions: MerchantAccountFilterOption[];
      }>(
        (acc, option) => {
          if (option.status === 'ACTIVE') {
            acc.activeOptions.push(option);
          } else {
            acc.inactiveOptions.push(option);
          }
          return acc;
        },
        {activeOptions: [], inactiveOptions: []}
      );
    return [...activeOptions, ...inactiveOptions];
  }, [merchantAccountsOptions]);

  useEffect(() => {
    if (filteredOptions.length === 1 && filteredOptions[0]) {
      onSelectMerchantAccount(filteredOptions[0].id);
    }
  }, [selectedMerchantAccount]);

  const options = useMemo(
    () => [
      ...(allLabel && filteredOptions.length > 1
        ? [
            {
              id: ALL,
              label: i18n._('All Merchant Accounts'),
            },
          ]
        : []),
      ...filteredOptions.map(({id, businessName, status}) => ({
        id,
        label: businessName,
        ...(status !== 'ACTIVE' && {
          className: 'text-gray-400',
          classNameActive: 'text-gray-400',
          tooltipText: i18n._('Merchant is not active'),
        }),
      })),
    ],
    [filteredOptions, allLabel]
  );

  return (
    <InputSearchSelectFilter
      value={selectedMerchantAccount ?? (allLabel ? ALL : undefined)}
      name={name}
      options={options}
      label=""
      className="w-full mt-0"
      labelClassName="gap-0"
      onChange={merchantAccountId =>
        onSelectMerchantAccount(
          merchantAccountId === ALL ? undefined : (merchantAccountId as string)
        )
      }
      placeholder={allLabel}
      defaultValue={allLabel ? ALL : undefined}
    />
  );
};
