export const nzStateAbbreviations: Record<string, string> = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Northland: 'NTL',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Auckland: 'AUK',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Waikato: 'WKO',
  'Bay of Plenty': 'BOP',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Gisborne: 'GIS',
  "Hawke's Bay": 'HKB',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Taranaki: 'TKI',
  'Manawatu-Whanganui': 'MWT',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Wellington: 'WGN',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Tasman: 'TAS',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Nelson: 'NSN',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Marlborough: 'MBH',
  'West Coast': 'WTC',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Canterbury: 'CAN',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Otago: 'OTA',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Southland: 'STL',
};
