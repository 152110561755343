import {MerchantAccountPublicStatus, SavedPaymentMethodsFilters} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db/src';
import {useCallback} from 'react';
import {MerchantAccountsPickerFilter} from '../dropdowns';
import {ResetTableFiltersButton} from '../table';
import {InputSearchFilter} from './InputSearchFilter';

type Props<T extends SavedPaymentMethodsFilters> = {
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  name?: string;
  merchantShopperId?: string;
  email?: string;
  selectedMerchantAccount?: string;
  merchantAccounts?: {
    id: string;
    businessName: string;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
};

export const SavedPaymentMethodFilters = <T extends SavedPaymentMethodsFilters>({
  typedSearchParams,
  setTypedSearchParams,
  name,
  merchantShopperId,
  email,
  selectedMerchantAccount,
  merchantAccounts,
}: Props<T>) => {
  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({[key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      {!!merchantAccounts?.length && (
        <div className="font-normal shrink-0 max-lg:w-full">
          <MerchantAccountsPickerFilter
            selectedMerchantAccount={selectedMerchantAccount || ''}
            onSelectMerchantAccount={value => handleFilterChange('selectedMerchantAccount', value)}
            merchantAccountsOptions={merchantAccounts}
            allLabel={'Merchant'}
          />
        </div>
      )}
      <div className="max-lg:w-full">
        <ResetTableFiltersButton
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="max-lg:w-full">
        <InputSearchFilter
          label="Name"
          selected={name}
          onChange={value => handleFilterChange('name', value)}
        />
      </div>
      <div className="max-lg:w-full">
        <InputSearchFilter
          label="Customer ID"
          selected={merchantShopperId}
          onChange={value => handleFilterChange('merchantShopperId', value)}
        />
      </div>
      <div className="max-lg:w-full">
        <InputSearchFilter
          label="Email"
          selected={email}
          onChange={value => handleFilterChange('email', value)}
          itemsClassName="right-0"
        />
      </div>
    </div>
  );
};
