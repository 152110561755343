import {Table} from '@tanstack/react-table';

export const getTableMeta = <T>(table: Table<T>) => {
  const meta = table.options.meta;
  if (!meta) {
    // this exception should never be triggered
    // meta is always injected for convenience despite the type of CoreOptions
    // sadly the optionality of the value can't be augmented in the interface, so the helper is needed
    throw new Error('getTableMeta utility is used ouside table context, table meta is undefined');
  }
  return meta;
};
