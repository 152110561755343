import {useLingui} from '@lingui/react';
import {
  OrganizationAccountActivityDetailsItem,
  OrganizationAccountActivityDetailsOutput,
  TenantAccountActivityDetailsItem,
  TenantAccountActivityDetailsOutput,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {LocaleCode} from '@zentact/common';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../table';
import {getAccountActivityDetailsColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  accountActivityDetails?:
    | TenantAccountActivityDetailsOutput
    | OrganizationAccountActivityDetailsOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  onRowClick: (
    row: TenantAccountActivityDetailsItem | OrganizationAccountActivityDetailsItem
  ) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string>>>;
  showOrganizationColumn?: boolean;
  showMerchantColumn?: boolean;
  isLoading?: boolean;
  paymentDetailsPath: string;
};

export const AccountActivityDetailsTable = <TFilters extends Record<string, unknown>>({
  accountActivityDetails,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  onRowClick,
  showOrganizationColumn,
  showMerchantColumn,
  isLoading,
  paymentDetailsPath,
}: Props<TFilters>) => {
  const {i18n} = useLingui();

  return (
    <TableWrapper
      data={accountActivityDetails?.rows ?? []}
      pageCount={accountActivityDetails?.pagination?.pageCount ?? 0}
      columns={getAccountActivityDetailsColumns({
        locale: i18n.locale as LocaleCode,
        i18n,
        showOrganizationColumn,
        showMerchantColumn,
        paymentDetailsPath,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            {i18n._('No Transactions Recorded')}
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={onRowClick}
      isLoading={isLoading}
    />
  );
};
