import {cn} from '@zentact/ui-tailwind/utils';
import {useNavigate} from 'react-router-dom';

export const LogoutLink = ({className}: {className?: string}) => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={cn('text-primary-500 cursor-pointer text-sm font-semibold leading-6', className)}
      onClick={() => navigate('/logout')}
    >
      Log out <span aria-hidden="true">&rarr;</span>
    </button>
  );
};
