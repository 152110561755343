import {useStore} from '@/store';
import {i18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {Badge, Typography} from '@zentact/ui-tailwind';
import {merchantRegistrationStepTitles} from '../../constants';

export const MerchantTenantReview = () => {
  const {tenant} = useStore();

  return (
    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <Typography as="h3" variant="header-lg">
          {i18n._(merchantRegistrationStepTitles(tenant?.name).step5)}
        </Typography>
      </div>
      <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
        <Badge className="w-fit">
          <Trans>Awaiting {tenant?.name} approval</Trans>
        </Badge>
        <Typography variant="paragraph">
          <Trans>Your merchant application is under review.</Trans>
        </Typography>
        <Typography variant="paragraph">
          <Trans>
            {tenant?.name} has successfully received your application data and carefully reviewing
            it. Review could take from a few hours to a few days. We'll notify you by email when
            it's done.
          </Trans>
        </Typography>
      </div>
    </div>
  );
};
