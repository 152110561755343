import {useParams} from 'react-router-dom';
export const useRequiredParams = <
  ParamsOrKey extends string | Record<string, string | undefined>,
>(): ParamsOrKey => {
  const params = useParams<ParamsOrKey>();

  // biome-ignore lint/complexity/noForEach:
  Object.entries(params).forEach(([key, value]) => {
    if (value === null) {
      throw new Error(`Reuired param ${key} is missing or null`);
    }
  });

  return params as ParamsOrKey;
};
