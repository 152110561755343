import {InputHTMLAttributes, forwardRef} from 'react';
import {cn} from '../../utils';

type InputTextProps = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean;
  addonBefore?: string | React.ReactNode;
  addonAfter?: string;
  containerClassName?: string;
  inputClassName?: string;
};

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {hasError, className, containerClassName, inputClassName, addonBefore, addonAfter, ...other},
    ref
  ) => {
    return (
      <div
        className={cn(
          'relative flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-600 focus:ring-2 focus:ring-inset h-9',
          className,
          containerClassName,
          hasError && 'ring-red-300 focus-within:ring-red-500'
        )}
      >
        {addonBefore && (
          <div className="flex items-center pl-3 text-gray-500 sm:text-sm">
            <span className="text-gray-500 sm:text-sm">{addonBefore}</span>
          </div>
        )}
        <input
          ref={ref}
          type="text"
          className={cn(
            'flex-1 mx-1 border-0 py-1 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm',
            className,
            inputClassName,
            addonBefore && 'pl-1'
          )}
          {...other}
        />
        {addonAfter && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{addonAfter}</span>
          </div>
        )}
      </div>
    );
  }
);
