import {DateTime} from 'luxon';

export const formatPayoutDate = (valueDate: string) => {
  const date = new Date(valueDate);
  const utcDay = date.getUTCDay();
  let daysToAdd = 0;

  if (utcDay === 0) {
    // If day is Sunday, payout is on Monday
    daysToAdd = 1;
  } else if (utcDay === 6) {
    // If day is Saturday, payout is on Monday
    daysToAdd = 2;
  }
  return DateTime.fromJSDate(date)
    .plus({days: daysToAdd})
    .setZone('UTC')
    .toLocaleString(DateTime.DATE_MED);
};

export const formatPayoutDateWithLocalTime = (valueDate: string) => {
  const date = new Date(valueDate);
  const utcDay = date.getUTCDay();
  let daysToAdd = 0;

  if (utcDay === 0) {
    // If day is Sunday, payout is on Monday
    daysToAdd = 1;
  } else if (utcDay === 6) {
    // If day is Saturday, payout is on Monday
    daysToAdd = 2;
  }
  return DateTime.fromISO(valueDate).plus({days: daysToAdd}).toLocaleString(DateTime.DATETIME_MED);
};

export const getPayoutDateTime = (valueDate: string) => {
  const date = new Date(valueDate);
  const utcDay = date.getUTCDay();
  let daysToAdd = 0;

  if (utcDay === 0) {
    // If day is Sunday, payout is on Monday
    daysToAdd = 1;
  } else if (utcDay === 6) {
    // If day is Saturday, payout is on Monday
    daysToAdd = 2;
  }
  return DateTime.fromISO(valueDate).plus({days: daysToAdd});
};
