import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {
  InvitedOrganizationUserItem,
  InvitedTenantUserItem,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {useCallback, useState} from 'react';
import {AlertOverlayWithConfirmation, DropDownMinimalMenuIcon, useNotification} from '../../../..';
import {cn, saveAuth0DeletedUserInStorage} from '../../../../utils';

type Props = {
  invitationRow: InvitedOrganizationUserItem | InvitedTenantUserItem;
  refetchInvitedUsersList: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  openInvitedUserEditPanel: (row: InvitedOrganizationUserItem | InvitedTenantUserItem) => void;
};

const organizationStatusToItemsMap = (
  action: (action: 'editAndResend' | 'revoke' | 'delete' | 'resend' | 'edit') => void,
  i18n: I18n
): Record<string, {name: string; onClick: () => void; itemClassName?: string}[]> => ({
  // biome-ignore lint/style/useNamingConvention: UI status map
  Invited: [
    {name: i18n._('Edit & Resend'), onClick: () => action('editAndResend')},
    {
      name: i18n._('Revoke Invitation'),
      onClick: () => action('revoke'),
      itemClassName: cn('text-red-500'),
    },
  ],
  'Invite Expired': [{name: i18n._('Edit & Resend'), onClick: () => action('editAndResend')}],
});

const tenantStatusToItemsMap = (
  action: (action: 'editAndResend' | 'revoke' | 'delete' | 'resend' | 'edit') => void,
  i18n: I18n
): Record<string, {name: string; onClick: () => void; itemClassName?: string}[]> => ({
  // biome-ignore lint/style/useNamingConvention: UI status map
  Invited: [
    {name: i18n._('Edit'), onClick: () => action('edit')},
    {name: i18n._('Resend'), onClick: () => action('resend')},
    {
      name: i18n._('Revoke Invitation'),
      onClick: () => action('delete'),
      itemClassName: cn('text-red-500'),
    },
  ],
});

export const UserActions = ({
  invitationRow,
  refetchInvitedUsersList,
  trpc,
  mode,
  openInvitedUserEditPanel,
}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isDeleteUserConfirmationOpen, setDeleteUserConfirmationOpen] = useState(false);
  const {i18n} = useLingui();

  const revokeMutation = trpc.users.revokeInvitationOrganizationUser.useMutation({
    onSuccess: () => {
      refetchInvitedUsersList();
      showSuccessNotification(
        i18n._('Invitation revoked!'),
        i18n._('You have successfully revoked invitation.')
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const deleteTenantUserMutation = trpc.users.deleteTenantUserAccount.useMutation({
    onSuccess: (_1, req) => {
      saveAuth0DeletedUserInStorage(req.userId);
      setDeleteUserConfirmationOpen(false);
      refetchInvitedUsersList();
      showSuccessNotification(
        i18n._('Invitation deleted!'),
        i18n._('You have successfully deleted invitation.')
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const resendTenantUserInvitationMutation = trpc.users.resendTenantUserInvitation.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        i18n._('Invitation was sent.'),
        i18n._('You have successfully resent invitation.')
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleAction = useCallback(
    (action: string) => {
      if (action === 'editAndResend' || action === 'edit') {
        return openInvitedUserEditPanel(invitationRow);
      }

      if (action === 'revoke') {
        return revokeMutation.mutate({
          invitationId: invitationRow.id,
        });
      }

      if (action === 'delete') {
        return setDeleteUserConfirmationOpen(true);
      }

      if (action === 'resend') {
        return resendTenantUserInvitationMutation.mutate({
          userId: invitationRow.id,
        });
      }
    },
    [invitationRow, openInvitedUserEditPanel, setDeleteUserConfirmationOpen]
  );

  const handleUserDelete = () => {
    return deleteTenantUserMutation.mutate({
      userId: invitationRow.id,
    });
  };

  const menuItems =
    mode === 'organizationUser'
      ? organizationStatusToItemsMap(handleAction, i18n)[invitationRow.status]
      : tenantStatusToItemsMap(handleAction, i18n)[invitationRow.status];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon items={menuItems} />
      {isDeleteUserConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={isDeleteUserConfirmationOpen}
          setOpen={setDeleteUserConfirmationOpen}
          handleAction={handleUserDelete}
          localeText={{
            title: i18n._('Delete user'),
            description: i18n._('Are you sure you want to delete this user?'),
            confirm: i18n._('Delete'),
            cancel: i18n._('Cancel'),
          }}
        />
      )}
    </>
  );
};
