import {Transition} from '@headlessui/react';
import {useRef, useState} from 'react';
import {cn} from '..';
import Portal from './Portal';

type Props = {
  text: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
};

export const Tooltip = ({text, children, contentClassName}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [coords, setCoords] = useState<{
    left: number;
    top: number;
  }>({top: 0, left: 0});

  const anchorRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const coordHandler = () => {
    if (!anchorRef.current || !parentRef.current) {
      throw Error('Ref is empty');
    }

    const parentRect = parentRef.current.getBoundingClientRect();
    const anchorRect = anchorRef.current.getBoundingClientRect();
    const widthDiff = parentRect.width - anchorRect.width;
    const left = Math.round(parentRect.x + widthDiff / 2);
    setCoords({
      left: left > 0 ? left : 0,
      top: Math.round(parentRect.y + window.scrollY + parentRect.height) + 4,
    }); // bottom-center position
  };

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      ref={parentRef}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onClick={e => {
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
          e.stopPropagation();
        }
      }}
      onMouseLeave={() => setIsOpen(false)}
      onTouchStart={() => {
        setIsOpen(true);
      }}
      onTouchEnd={() => setTimeout(() => setIsOpen(false), 1500)}
      className="relative w-fit"
    >
      {children}
      <Portal>
        <Transition
          show={isOpen}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          beforeEnter={coordHandler}
        >
          <div
            ref={anchorRef}
            style={{
              top: `${coords.top}px`,
              left: `${coords.left}px`,
            }}
            className={cn(
              'absolute z-50 p-2 mt-1 text-gray-900 bg-white rounded shadow-md pointer-events-none text-sm',
              contentClassName
            )}
          >
            {text}
          </div>
        </Transition>
      </Portal>
    </div>
  );
};
