import {Menu} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import React, {ReactElement} from 'react';
import {InputSearch} from '../forms';
import {cn} from '../utils';

type Props<T extends string> = {
  label: string;
  selected?: T;
  onChange: (id?: T) => void;
  icon?: ReactElement;
  itemsClassName?: string;
};

export const InputSearchFilter = <T extends string>({
  label,
  selected,
  onChange,
  itemsClassName,
}: Props<T>) => {
  return (
    <Menu as="div" className="relative">
      {({open, close}) => (
        <>
          <Menu.Button
            className={`relative min-h-[2.25rem] w-full cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 sm:text-sm sm:leading-6 ${
              open ? 'bg-white ring-1 ring-inset ring-gray-300 text-gray-900' : ''
            } hover:font-semibold hover:bg-white hover:ring-1 hover:ring-inset hover:ring-gray-300 hover:text-gray-900`}
          >
            {label}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Menu.Button>
          <Menu.Items
            className={cn(
              'lg:w-52 relative lg:absolute z-50 mt-1 max-h-64 focus:outline-none shadow-lg sm:text-sm w-full sm:w-auto sm:min-w-min',
              itemsClassName
            )}
          >
            <InputSearch
              value={selected || ''}
              onChange={e => {
                onChange(e.currentTarget.value as T);
              }}
              onKeyDown={e => {
                // Preventing the menu from closing when you enter a space
                if (e.key === ' ') {
                  e.stopPropagation();
                }
              }}
              onClear={() => {
                onChange('' as T);
                close();
              }}
              placeholder="Search..."
            />
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};
