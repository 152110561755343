import {AuthCallback} from '@/pages/auth/callback';
import {Configure} from '@/pages/configure';
import {AccountActivityDetails, Payouts} from '@/pages/finance';
import {Statements} from '@/pages/finance/statements';
import {LogoutPage} from '@/pages/logout';
import {MerchantAccounts, MerchantDetails} from '@/pages/merchant-accounts';
import {SavedPaymentMethodDetails, SavedPaymentMethods, VirtualTerminal} from '@/pages/payments';
import {DeclinedPayments, Disputes, PaymentDetails, Payments} from '@/pages/transactions';
import {ActiveUsers, InvitedUsers} from '@/pages/users';
import {useStore} from '@/store';
import {ErrorCode} from '@zentact/common';
import {Error419, ErrorPage, Loading} from '@zentact/ui-tailwind';
import {Navigate, Route, Routes} from 'react-router-dom';
import {useAccessToken} from '../../hooks/useAccessToken';
import {CheckoutPage, CheckoutV2Page} from '../../pages/checkout';
import {DashboardPage} from '../../pages/dashboard';
import {MerchantRegistrationPage} from '../../pages/merchant-registration';
import {InviteSignUpPage, SignUpPage} from '../../pages/signup';
import {PortalLayout} from '../layout';
import {RoutePath} from '../layout/navigation';
import BrandedRoute from './BrandedRoute';

export const AppRoutes = () => {
  const {accessToken} = useAccessToken();
  const {tenant, organization, authProfile} = useStore();

  if (accessToken.isLoading || authProfile.isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* tenant branding routes */}
      <Route element={<BrandedRoute />}>
        <Route path="/" element={<PortalLayout />}>
          <Route path={RoutePath.DASHBOARD} element={<DashboardPage />} />
          <Route path={RoutePath.FINANCE}>
            <Route index element={<Navigate replace to={RoutePath.FINANCE_ACCOUNT_ACTIVITY} />} />
            <Route path={RoutePath.FINANCE_ACCOUNT_ACTIVITY} element={<AccountActivityDetails />} />
            <Route path={RoutePath.PAYOUTS} element={<Payouts />} />
            <Route path={RoutePath.STATEMENTS} element={<Statements />} />
          </Route>
          {organization?.organizationConfiguration?.savePaymentMethod !== 'DISABLED' && (
            <>
              <Route path={RoutePath.SAVED_PAYMENT_METHODS} element={<SavedPaymentMethods />} />
              <Route
                path={RoutePath.SAVED_PAYMENT_METHODS_DETAILS}
                element={<SavedPaymentMethodDetails />}
              />
            </>
          )}
          <Route path={RoutePath.TRANSACTIONS}>
            <Route index element={<Navigate replace to={RoutePath.PAYMENTS} />} />
            <Route path={RoutePath.PAYMENTS} element={<Payments />} />
            <Route path={RoutePath.DECLINED_PAYMENTS} element={<DeclinedPayments />} />
            <Route path={RoutePath.DISPUTES} element={<Disputes />} />
            <Route path={RoutePath.PAYMENT_DETAILS} element={<PaymentDetails />} />
          </Route>
          {!tenant?.features?.disablePortalPayments && (
            <Route path={RoutePath.TAKE_PAYMENT} element={<VirtualTerminal />} />
          )}
          <Route path="/" element={<Navigate replace to={RoutePath.DASHBOARD} />} />
          <Route path={RoutePath.MERCHANT_ACCOUNTS} element={<MerchantAccounts />} />
          <Route path={RoutePath.MERCHANT_ACCOUNT_DETAILS} element={<MerchantDetails />} />
          {authProfile.data?.isOrganizationAdmin && (
            <>
              <Route path={RoutePath.USERS}>
                <Route index element={<Navigate replace to={RoutePath.ACTIVE_USERS} />} />
                <Route path={RoutePath.ACTIVE_USERS} element={<ActiveUsers />} />
                <Route path={RoutePath.INVITED_USERS} element={<InvitedUsers />} />
              </Route>
              <Route path={RoutePath.CONFIGURE} element={<Configure />} />
            </>
          )}
        </Route>
        <Route path={RoutePath.AUTH_CALLBACK} element={<AuthCallback />} />
        <Route path={RoutePath.SIGNUP} element={<SignUpPage />} />
        <Route path={RoutePath.USER_INVITE} element={<InviteSignUpPage />} />
        <Route path={RoutePath.LOGOUT} element={<LogoutPage />} />
        <Route path={RoutePath.REGISTRATION}>
          <Route
            index
            element={<MerchantRegistrationPage registrationCompleteRedirectPath="/" />}
          />
          <Route path="*" element={<Navigate replace to={RoutePath.REGISTRATION} />} />
        </Route>
        <Route path={RoutePath.ERROR} element={<ErrorPage logoutUri={tenant?.logoutUri} />} />
        <Route path={RoutePath.SESSION_EXPIRED} element={<Error419 />} />
        <Route
          path="*"
          element={
            <Navigate replace to={RoutePath.ERROR} state={{errorCode: ErrorCode.PAGE_NOT_FOUND}} />
          }
        />
      </Route>
      {/* org branding routes */}
      <Route path={RoutePath.CHECKOUT} element={<CheckoutPage />} />
      <Route path={RoutePath.CHECKOUT_V2} element={<CheckoutV2Page />} />
    </Routes>
  );
};
