import {TRPCClientError} from '@trpc/client';
import {TRPCFetchQueryOptions} from '@trpc/react-query/shared';
import type {AnyProcedure, inferProcedureInput} from '@trpc/server';
import type {AnyRootConfig, Procedure, ProcedureParams} from '@trpc/server';
import type {inferTransformedProcedureOutput} from '@trpc/server/shared';

type DecorateProcedure<TProcedure extends AnyProcedure> = {
  fetch(
    input: inferProcedureInput<TProcedure>,
    opts?: TRPCFetchQueryOptions<
      inferProcedureInput<TProcedure>,
      TRPCClientError<TProcedure>,
      inferTransformedProcedureOutput<TProcedure>
    >
  ): Promise<inferTransformedProcedureOutput<TProcedure>>;
};

export const fetchFullListFromPaginatedEndpoint = async <
  TConfig extends AnyRootConfig,
  TContextOut,
  TInputInput,
  TInputOutput,
  TOutputIn,
  TOutputOut extends {rows: unknown[]; pagination: {hasNextPage: boolean}},
  TMeta,
  TProcedure extends Procedure<
    'query',
    ProcedureParams<TConfig, TContextOut, TInputInput, TInputOutput, TOutputIn, TOutputOut, TMeta>
  >,
>(
  endpoint: DecorateProcedure<TProcedure>,
  filters: inferProcedureInput<TProcedure>,
  pageSize = 20000
) => {
  const allData: inferTransformedProcedureOutput<TProcedure>['rows'] = [];
  let pageIndex = 0;
  let hasNextPage = true;

  while (hasNextPage) {
    const response = await endpoint.fetch({
      ...filters,
      pageIndex,
      pageSize,
    });

    allData.push(...response.rows);
    pageIndex++;
    hasNextPage = response.pagination.hasNextPage;
  }

  return {rows: allData};
};
