import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {AllMerchantAccountListItem} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {OrganizationUserItem} from '@zentact/api/src/trpc/routers/usersRouter';
import {Auth0UserRole} from '@zentact/common';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Button, Loading} from '../../../..';
import {useNotification} from '../../../../hooks';
import {AlertOverlayWithConfirmation, SlideOverWithBrandedHeader} from '../../../../overlays';
import {UserForm, UserFormData, getUserFormSchema} from '../../user-form';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  userRow: OrganizationUserItem;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  merchantAccountsOptions?: AllMerchantAccountListItem[];
};

const parseName = (fullName: string) => {
  const nameParts: string[] = fullName.split(' ');
  const firstName = nameParts[0];
  let lastName = '';

  if (nameParts.length > 1) {
    lastName = nameParts.slice(1).join(' ');
  }

  return {firstName, lastName};
};

export const EditActiveUserPanel = ({
  userRow,
  onSuccess,
  onClose,
  trpc,
  mode,
  merchantAccountsOptions,
}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isClosing, setIsClosing] = useState<{success: boolean} | null>(null);

  const [changeUserDataConfirmationOpen, setChangeUserDataConfirmationOpen] = useState(false);
  const navigate = useNavigate();
  const {user: contextUser} = useAuth0();
  const {i18n} = useLingui();

  const editUserForm = useForm<UserFormData>({
    resolver: zodResolver(getUserFormSchema(i18n)),
    defaultValues: {
      email: userRow.email,
      role: userRow.roles?.[0] ?? Auth0UserRole.ORGANIZATION_ADMIN,
      ...(userRow.name ? parseName(userRow.name) : {}),
    },
  });

  const organizationUserMerchantsVisibility =
    trpc.users.getOrganizationUserMerchantsVisibility.useQuery(userRow.zentactOrganizationUserId, {
      enabled: mode === 'organizationUser',
      onSuccess: data => {
        if (data.merchantAccountIds) {
          editUserForm.setValue('merchantAccountIds', data.merchantAccountIds);
        }
      },
    });
  const {handleSubmit} = editUserForm;

  const isContextUser = userRow?.id === contextUser?.sub;

  const editTenantUserMutation = trpc.users.editTenantUserAccount.useMutation({
    onSuccess: () => {
      setChangeUserDataConfirmationOpen(false);
      setIsClosing({success: true});
      showSuccessNotification(
        i18n._('User edited'),
        i18n._('You have successfully edited user { name }.', {name: userRow.name})
      );
      if (isContextUser) {
        navigate('/logout');
      }
    },
    onError: error => {
      setIsClosing({success: false});
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const editOrganizationUserMutation = trpc.users.editOrganizationUserAccount.useMutation({
    onSuccess: () => {
      setChangeUserDataConfirmationOpen(false);
      setIsClosing({success: true});
      showSuccessNotification(
        i18n._('User edited'),
        i18n._('You have successfully edited user { name }.', {name: userRow.name})
      );
      if (isContextUser) {
        navigate('/logout');
      }
    },
    onError: error => {
      setIsClosing({success: false});
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleEditUser = useCallback(
    (data: UserFormData) => {
      if (mode === 'organizationUser') {
        editOrganizationUserMutation.mutate({
          firstName: data.firstName,
          lastName: data.lastName,
          userId: userRow.id,
          ...(mode === 'organizationUser' && {
            role: data.role as Auth0UserRole.ORGANIZATION_ADMIN | Auth0UserRole.MERCHANT_ADMIN,
            merchantAccountIds: data.merchantAccountIds,
          }),
        });
        return;
      }

      editTenantUserMutation.mutate({
        firstName: data.firstName,
        lastName: data.lastName,
        userId: userRow.id,
      });
    },
    [userRow.id, mode]
  );

  const handleEditUserConfirmation = (data: UserFormData) => {
    if (isContextUser) {
      setChangeUserDataConfirmationOpen(true);
    } else {
      handleEditUser(data);
    }
  };

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={!isClosing}
        title={i18n._('Edit User')}
        text={i18n._('Update the information for { name }', {name: userRow.name})}
        closeHandler={() => {
          if (isClosing?.success) {
            onSuccess();
          }
          onClose();
        }}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                disabled={
                  mode === 'organizationUser' && organizationUserMerchantsVisibility.isLoading
                }
                onClick={handleSubmit(handleEditUserConfirmation)}
                isLoading={
                  editOrganizationUserMutation.isLoading || editTenantUserMutation.isLoading
                }
              >
                {i18n._('Update User')}
              </Button>
            </div>
            <Button
              variant="secondary"
              size="lg"
              className="w-fit"
              onClick={() => setIsClosing({success: false})}
            >
              {i18n._('Close')}
            </Button>
          </footer>
        }
      >
        {mode === 'organizationUser' && organizationUserMerchantsVisibility.isLoading ? (
          <div className="flex items-center justify-center">
            <Loading mode="inline" size="small" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleEditUserConfirmation)}>
            <UserForm
              form={editUserForm}
              emailReadonly
              mode={mode}
              merchantAccountsOptions={merchantAccountsOptions}
            />
          </form>
        )}
      </SlideOverWithBrandedHeader>
      {changeUserDataConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={changeUserDataConfirmationOpen}
          setOpen={setChangeUserDataConfirmationOpen}
          handleAction={handleSubmit(handleEditUser)}
          localeText={{
            title: i18n._('You need to re-login'),
            description: i18n._(`To update your profile, you'll need to re-login. Proceed?`),
            confirm: i18n._('Continue'),
            cancel: i18n._('Cancel'),
          }}
        />
      )}
    </>
  );
};
