import {useCallback, useState} from 'react';

export const useDrawerState = ({onSuccessHandler}: {onSuccessHandler: () => void}) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onSuccess = useCallback(() => {
    onSuccessHandler();
    close();
  }, [onSuccessHandler, close]);

  return {
    isOpen,
    open,
    close,
    onSuccess,
  };
};
