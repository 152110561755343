import {trpc} from '@/api/trpcClient';
import {useAddressAutocomplete} from '@/hooks/useAddressAutocomplete';
import {AdyenConfiguration, initAdyenCheckout} from '@/utils/adyen';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {AddressLookupItem} from '@adyen/adyen-web/dist/types/components/internal/Address/types';
import {CheckoutCreateAdyenSessionOutput} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {ErrorCode, FormattedTrpcError, isFormattedTrpcError} from '@zentact/common';
import {useNotification} from '@zentact/ui-tailwind';
import React from 'react';

type Action =
  | {
      type: 'getSession';
    }
  | {
      type: 'getSessionSuccess';
      payload: Pick<State, 'sessionDetails'>;
    }
  | {
      type: 'initCheckout';
    }
  | {
      type: 'initCheckoutSuccess';
      payload: Pick<State, 'dropinElement'>;
    }
  | {
      type: 'payment';
    }
  | {
      type: 'paymentSuccess';
    }
  | {
      type: 'error';
      payload: Pick<State, 'hasFatalError' | 'error'>;
    };

type State = {
  isInitializing: boolean;
  isLoading: boolean;
  paymentSuccess: boolean;
  hasFatalError: boolean;
  error: FormattedTrpcError | {errorCode: ErrorCode} | null;
  sessionDetails: CheckoutCreateAdyenSessionOutput | null;
  dropinElement: DropinElement | null;
};

const initialState = {
  isInitializing: true,
  isLoading: false,
  sessionDetails: null,
  paymentSuccess: false,
  hasFatalError: false,
  error: null,
  dropinElement: null,
} satisfies State;

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'getSession':
      return {
        ...state,
        sessionDetails: null,
        isInitializing: true,
        isLoading: true,
        paymentSuccess: false,
        hasFatalError: false,
        error: null,
      };
    case 'getSessionSuccess':
      return {
        ...state,
        ...action.payload,
        isInitializing: true,
        isLoading: true,
        paymentSuccess: false,
        hasFatalError: false,
        error: null,
      };
    case 'initCheckout':
      return {
        ...state,
        isInitializing: false,
        isLoading: true,
        paymentSuccess: false,
        hasFatalError: false,
        error: null,
      };
    case 'initCheckoutSuccess':
      return {
        ...state,
        ...action.payload,
        isInitializing: false,
        isLoading: false,
        paymentSuccess: false,
        hasFatalError: false,
        error: null,
      };
    case 'payment':
      return {
        ...state,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false,
        error: null,
      };
    case 'paymentSuccess':
      return {
        ...state,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: true,
        hasFatalError: false,
        error: null,
      };
    case 'error':
      return {
        ...state,
        ...action.payload,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
      };
    default:
      throw new Error();
  }
};

export const useCheckout = () => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });

  const {showErrorNotification} = useNotification();
  const {initialize: initGoogleMapsApi, getAddressPredictions} = useAddressAutocomplete();
  const createAdyenSession = trpc.checkout.createAdyenSession.useMutation();

  /**
   * Sets the UI state of the payment component.
   */
  const setComponentStatus = (
    status: 'success' | 'error' | 'loading' | 'ready',
    component: DropinElement
  ) => {
    component.setStatus(status);
  };

  /**
   * Setting a temporary error will show an error for 2 seconds then reset the form.
   */
  const setTemporaryComponentError = (component: DropinElement) => {
    setComponentStatus('error', component);
    setTimeout(() => {
      setComponentStatus('ready', component);
    }, 3500);
  };

  const showTemporaryErrorMessage = (component: DropinElement, message: string, title: string) => {
    component.setStatus('error');
    showErrorNotification(title, message);
    setTimeout(() => {
      component.setStatus('ready');
    }, 3500);
  };

  /**
   * Setting a fatal error will redirect the user to a dedicated error page.
   */
  const setError = (error: State['error'], hasFatalError = true) =>
    dispatch({type: 'error', payload: {hasFatalError, error}});

  /**
   * First step of loading the checkout page is to get the session details from the server.
   * The session is retrieved by providing the server with the checkoutId that was parsed from the URL.
   */
  const getSession = async () => {
    dispatch({type: 'getSession'});
    try {
      const sessionDetails = await createAdyenSession.mutateAsync();

      if (sessionDetails?.adyenConfiguration.billingAddressMode === 'FULL') {
        await initGoogleMapsApi();
      }

      dispatch({
        type: 'getSessionSuccess',
        payload: {sessionDetails: sessionDetails ?? null},
      });
    } catch (error) {
      if (isFormattedTrpcError(error)) {
        setError(error, true);
      } else {
        setError({errorCode: ErrorCode.ERROR_GENERIC});
      }
    }
  };

  /**
   * The second step of loading the checkout page is to initialize the Adyen checkout component.
   * When this function is complete, the form is ready for user input.
   */
  const initCheckout = (
    domNode: HTMLDivElement,
    {paymentMethodsConfiguration, ...config}: AdyenConfiguration
  ) => {
    if (!state.sessionDetails) {
      return;
    }

    initAdyenCheckout(domNode, state.sessionDetails, {
      ...config,
      paymentMethodsConfiguration: {
        ...paymentMethodsConfiguration,
        card: {
          ...paymentMethodsConfiguration?.card,
          ...(state.sessionDetails.adyenConfiguration.billingAddressMode === 'FULL' && {
            onAddressLookup: async (
              value: string,
              actions: {
                resolve: (address: AddressLookupItem[]) => void;
                reject: (e: unknown) => void;
              }
            ) => {
              try {
                const addresses = await getAddressPredictions(value);
                actions.resolve(addresses);
              } catch (e) {
                actions.reject(e);
              }
            },
          }),
        },
      },
    })
      .then(component => {
        dispatch({
          type: 'initCheckoutSuccess',
          payload: {dropinElement: component},
        });
      })
      .catch((_err: unknown) => {
        setError({errorCode: ErrorCode.ERROR_GENERIC});
      });
  };

  const acknowledgePayment = () => {
    dispatch({
      type: 'paymentSuccess',
    });
  };

  return {
    ...state,
    setTemporaryComponentError,
    setError,
    setComponentStatus,
    getSession,
    initCheckout,
    acknowledgePayment,
    showTemporaryErrorMessage,
  };
};
