import {ReactNode} from 'react';
import {cn} from '../../utils';

export type ValidationErrorProps = {isVisible?: boolean; children?: ReactNode; className?: string};

export const ValidationError = ({isVisible, children, className}: ValidationErrorProps) => {
  return isVisible && children ? (
    <div className={cn('block text-sm text-red-600', className)}>{children}</div>
  ) : null;
};
