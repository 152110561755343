import {XMarkIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {Dispatch, SetStateAction, memo, useCallback} from 'react';
import {Button} from '../../forms';

type Props<Filters extends Record<string, unknown>> = {
  defaultFilters: Filters;
  activeFilters: Filters;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  setFilters: Dispatch<SetStateAction<any>>;
  ignoreKeys?: string[];
};
export const ResetTableFiltersButton = memo(
  <Filters extends Record<string, unknown>>({
    defaultFilters,
    activeFilters,
    setFilters,
    ignoreKeys = [],
  }: Props<Filters>) => {
    const {i18n} = useLingui();
    const appliedFilters = getDiffFiltersCount(activeFilters, defaultFilters, ignoreKeys);

    const onClick = useCallback(() => {
      setFilters(defaultFilters);
    }, [defaultFilters, setFilters]);

    if (!appliedFilters) {
      return null;
    }
    return (
      <Button
        variant="secondary"
        size="lg"
        className="items-center w-full font-normal sm:w-fit gap-x-2"
        onClick={onClick}
      >
        <span className="whitespace-nowrap">
          {i18n._(
            '{count} filters applied',
            {count: appliedFilters},
            {
              message:
                '{count, plural, =1 {{count} filter applied} other {{count} filters applied}}',
            }
          )}
        </span>
        <span className="sr-only">{i18n._('Reset filters')}</span>
        <XMarkIcon className="w-4 h-4 border-2 border-gray-200 rounded-full" aria-hidden="true" />
      </Button>
    );
  }
);

const getDiffFiltersCount = (obj1: unknown, obj2: unknown, ignoreKeys: string[], isRoot = true) => {
  if (Array.isArray(obj1)) {
    // element order can be different
    return !Array.isArray(obj2) || obj1.length !== obj2.length ? 1 : 0;
  }
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    let count = 0;
    for (const key in obj1) {
      if (ignoreKeys.includes(key)) {
        continue;
      }
      const diff = getDiffFiltersCount(
        obj1[key as keyof typeof obj1],
        (obj2 as Record<string, unknown>)[key],
        ignoreKeys,
        false
      );
      if (diff) {
        if (!isRoot) {
          // stop on first diff if it's not first level deep
          return 1;
        }

        count++;
      }
    }
    return count;
  }
  if (Number.isNaN(obj1) && Number.isNaN(obj2)) {
    return 0;
  }

  if (obj2 === undefined && obj1 === undefined) {
    return 0;
  }
  return typeof obj2 !== typeof obj1 || obj1 !== obj2 ? 1 : 0;
};
