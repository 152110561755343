import {DocumentIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {I18n} from '@lingui/core';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {
  getDefenseDocumentTypeCode,
  getDefenseReasonCode,
  supportedDefenseDocumentExtensions,
} from '@zentact/common';
import {useState} from 'react';
import {Typography} from '../..';
import {Button, FileUpload, InputRadio, Loading, useNotification} from '../../..';

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  disputePspReference: string;
  i18n: I18n;
};

interface DefenseDocumentType {
  defenseDocumentTypeCode: string;
  available: boolean;
  requirementLevel: string;
}

interface DefenceReason {
  defenseReasonCode: string;
  satisfied: boolean;
  defenseDocumentTypes?: DefenseDocumentType[] | undefined;
}

export const DisputeApiDetails = ({trpc, disputePspReference, i18n}: Props) => {
  const defenseReasons = trpc.chargeback.getDefenseReasons.useQuery(
    {
      disputePspReference,
    },
    {
      onSuccess(data) {
        if (!defenseReasonState.defenseReasonCode) {
          return;
        }

        const activeDefenseReason = data.defenseReasons?.find(
          row => row.defenseReasonCode === defenseReasonState.defenseReasonCode
        );

        if (activeDefenseReason) {
          setDefenseReasonState(activeDefenseReason);
        }
      },
    }
  );
  const [defenseReasonState, setDefenseReasonState] = useState<DefenceReason>({
    defenseReasonCode: '',
    satisfied: false,
    defenseDocumentTypes: undefined,
  });
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const supplyDefenseDocument = trpc.chargeback.supplyDefenseDocument.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        i18n._('Document supplied'),
        i18n._('Your defense document has been successfully submitted')
      );
      defenseReasons.refetch();
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });
  const defendDispute = trpc.chargeback.defendDispute.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        i18n._('Defend Dispute'),
        i18n._('You have successfully sent a dispute defense request')
      );
      defenseReasons.refetch();
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });
  const deleteDefenseDocument = trpc.chargeback.deleteDefenseDocument.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        i18n._('Document was deleted'),
        i18n._('The defense document has been successfully deleted')
      );
      defenseReasons.refetch();
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const onSelectFile = async (file: File, document: DefenseDocumentType) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      // The result is a raw base64 string, stripped of the data URL scheme, MIME type, and encoding indicator
      const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
      supplyDefenseDocument.mutate({
        defenseDocuments: [
          {
            content: base64String,
            contentType: file.type,
            defenseDocumentTypeCode: document.defenseDocumentTypeCode,
          },
        ],
        disputePspReference,
      });
    };
    reader.onerror = error => {
      console.error('Error reading file:', error);
    };
  };

  if (!defenseReasons.data) {
    return;
  }

  if (defenseReasons.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="header-section" className="my-4">
        {i18n._('Possible Defense Reasons')}
      </Typography>
      <div className="">
        <div>
          {defenseReasons.data?.defenseReasons?.map(reason => {
            const defenseReasonCode = getDefenseReasonCode(reason.defenseReasonCode, i18n);
            if (!defenseReasonCode) {
              console.error('Unknown defense reason code: ', reason.defenseReasonCode);
            }
            return (
              <div key={reason.defenseReasonCode} className="pb-6 sm:grid sm:gap-4 ">
                <InputRadio
                  label={
                    <div className="mx-1">
                      {defenseReasonCode ||
                        i18n._('Defense reason code: {reasonCode}', {
                          reasonCode: reason.defenseReasonCode,
                        })}
                    </div>
                  }
                  inputClassName="mt-1 w-4 h-4 bg-gray-100 border-gray-300 cursor-pointer text-primary-600 focus:ring-primary-500 disabled:opacity-70"
                  wrapperClassName="flex items-start space-x-2"
                  value={reason.defenseReasonCode}
                  name="defenseReason"
                  onClick={() => setDefenseReasonState(reason)}
                />
              </div>
            );
          })}
        </div>
        <div>
          {defenseReasonState.defenseReasonCode && (
            <>
              <Typography variant="header-section" className="my-4">
                Defense Documents
              </Typography>
              {defenseReasonState.defenseDocumentTypes?.map(document => (
                <div key={document.defenseDocumentTypeCode} className="my-4">
                  {getDefenseDocumentTypeCode(document.defenseDocumentTypeCode, i18n)}
                  <div className="my-2">
                    {document.available ? (
                      <div className="flex items-center justify-between w-full my-2">
                        <div className="flex items-center space-x-2">
                          <DocumentIcon className="w-6 h-6" />
                          {i18n._('Uploaded Document')}
                        </div>
                        <button
                          type="button"
                          onClick={() =>
                            deleteDefenseDocument.mutate({
                              defenseDocumentType: document.defenseDocumentTypeCode,
                              disputePspReference,
                            })
                          }
                        >
                          <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    ) : (
                      <FileUpload
                        onSelectFile={f => onSelectFile(f, document)}
                        allowedExt={supportedDefenseDocumentExtensions}
                        localeText={{
                          upload: '',
                          dragndrop: '',
                        }}
                        maxSizeMb={10}
                      />
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="flex justify-end">
          <Button
            variant="primary"
            size="lg"
            className="w-fit my-2"
            disabled={!defenseReasonState.satisfied}
            onClick={() =>
              defendDispute.mutate({
                defenseReasonCode: defenseReasonState.defenseReasonCode,
                disputePspReference,
              })
            }
          >
            {i18n._('Defend Dispute')}
          </Button>
        </div>
      </div>
    </>
  );
};
