import {LocaleCode} from '../constants/enums';

const paymentMethodNameMap: {[key: string]: {[K in LocaleCode]: string}} = {
  mc: {
    [LocaleCode.en_US]: 'Mastercard',
    [LocaleCode.ps_DO]: 'Mastercard',
  },
  visa: {
    [LocaleCode.en_US]: 'Visa',
    [LocaleCode.ps_DO]: 'Visa',
  },
  amex: {
    [LocaleCode.en_US]: 'American Express',
    [LocaleCode.ps_DO]: 'American Express',
  },
  discover: {
    [LocaleCode.en_US]: 'Discover',
    [LocaleCode.ps_DO]: 'Discover',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  mc_applepay: {
    [LocaleCode.en_US]: 'Apple Pay Mastercard',
    [LocaleCode.ps_DO]: 'Apple Pay Mastercard',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  visa_applepay: {
    [LocaleCode.en_US]: 'Apple Pay Visa',
    [LocaleCode.ps_DO]: 'Apple Pay Visa',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  discover_applepay: {
    [LocaleCode.en_US]: 'Apple Pay Discover',
    [LocaleCode.ps_DO]: 'Apple Pay Discover',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  amex_applepay: {
    [LocaleCode.en_US]: 'Apple Pay American Express',
    [LocaleCode.ps_DO]: 'Apple Pay American Express',
  },
  ach: {
    [LocaleCode.en_US]: 'ACH',
    [LocaleCode.ps_DO]: 'ACH',
  },
};

export const getPaymentMethodName = (
  paymentMethod: string,
  locale: LocaleCode = LocaleCode.en_US
) => {
  const localizedName = paymentMethodNameMap[paymentMethod]?.[locale];
  return localizedName || paymentMethod;
};
