import {createPaletteFromColor} from 'palettey';
import WebFont from 'webfontloader';

type PaletteLevels = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
type PaletteColors = {[_ in PaletteLevels]: string};

// these will come from the tenant theme configuration in postgres
export const BaseTheme = {
  primary: '#B433A7',
};

export interface ITheme {
  primary: string;
  fontFamily?: string | null;
}

const generatePalette = (variable: string, color: string) => {
  const primary = getPalleteFromPrimaryColor(color);
  return {
    [variable]: color,
    ...Object.entries(primary).reduce((acc, [weight, shade]) => {
      const key = `${variable}-${weight}`;
      // biome-ignore lint/performance/noAccumulatingSpread: TODO
      return {...acc, [key]: shade || ''};
    }, {}),
  };
};

export const mapTheme = (variables: ITheme): {[key: string]: string} => {
  const {primary, fontFamily} = variables;
  const vars = {
    ...generatePalette('--color-primary', primary),
    ...(fontFamily ? {'--font-family': fontFamily} : {}),
  };
  return vars;
};

export const applyTheme = (themeConfiguration: ITheme): void => {
  const theme = {...BaseTheme, ...themeConfiguration};
  const themeObject = mapTheme(theme);

  if (theme.fontFamily) {
    loadGoogleFonts(theme.fontFamily);
  }
  const root = document.documentElement;
  for (const property of Object.keys(themeObject)) {
    if (property === 'name') {
      continue;
    }
    root.style.setProperty(property, themeObject[property] ?? null);
  }
};

export const applyTenantFavicon = (faviconSrc: string): void => {
  const link = document.querySelector('link[rel="icon"]');
  link?.setAttribute('href', faviconSrc ? `${faviconSrc}?w=64&h=64&fit=crop` : '');
};

export const getPalleteFromPrimaryColor = (color: string): PaletteColors => {
  const {primary} = createPaletteFromColor('primary', color, {useLightness: false});
  if (!primary) {
    throw new Error('Primary colors are undefined');
  }
  //Default type from the library is not correct
  const {'50': _, ...palette} = primary as unknown as PaletteColors & {'50': string};

  return palette;
};

const loadGoogleFonts = (fontFamily: string) => {
  const fonts = fontFamily.split(',').map(s => s.trim());
  WebFont.load({
    google: {
      families: fonts,
    },
  });
};
