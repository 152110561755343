import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {DropDownMinimalMenuIcon} from '../../../../dropdowns';

type Props = {
  row: ShopperListItemOutput;
  openDetails: (row: ShopperListItemOutput) => void;
  navigateToTransactions: (row: ShopperListItemOutput) => void;

  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  refetch: () => void;
};

export const SavedPaymentMethodsActions = ({row, openDetails, navigateToTransactions}: Props) => {
  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {name: 'View Details', onClick: () => openDetails(row)},
    {name: 'View Transactions', onClick: () => navigateToTransactions(row)},
  ];
  return (
    <>
      <DropDownMinimalMenuIcon items={options} />
    </>
  );
};
