export const VisaMastercardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" height="60" width="80">
      <title>VisaMasterCard</title>
      {/* Visa Icon */}

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 324.68">
        <title>Visa</title>
        <path
          fill="#1434cb"
          d="m651.19.5c-70.93,0-134.32,36.77-134.32,104.69,0,77.9,112.42,83.28,112.42,122.42,0,16.48-18.88,31.23-51.14,31.23-45.77,0-79.98-20.61-79.98-20.61l-14.64,68.55s39.41,17.41,91.73,17.41c77.55,0,138.58-38.57,138.58-107.66,0-82.32-112.89-87.54-112.89-123.86,0-12.91,15.5-27.05,47.66-27.05,36.29,0,65.89,14.99,65.89,14.99l14.33-66.2S696.61.5,651.18.5h0ZM2.22,5.5L.5,15.49s29.84,5.46,56.72,16.36c34.61,12.49,37.07,19.77,42.9,42.35l63.51,244.83h85.14L379.93,5.5h-84.94l-84.28,213.17-34.39-180.7c-3.15-20.68-19.13-32.48-38.68-32.48,0,0-135.41,0-135.41,0Zm411.87,0l-66.63,313.53h81L494.85,5.5h-80.76Zm451.76,0c-19.53,0-29.88,10.46-37.47,28.73l-118.67,284.8h84.94l16.43-47.47h103.48l9.99,47.47h74.95L934.12,5.5h-68.27Zm11.05,84.71l25.18,117.65h-67.45l42.28-117.65h0Z"
        />
      </svg>

      {/* Mastercard Icon */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-80 -20 150 150">
        <title>MasterCard</title>
        <g>
          <rect width="152.407" height="108" style={{fill: 'none'}} />
          <g>
            <rect x="60.4117" y="25.6968" width="31.5" height="56.6064" style={{fill: '#ff5f00'}} />
            <path
              d="M382.20839,306a35.9375,35.9375,0,0,1,13.7499-28.3032,36,36,0,1,0,0,56.6064A35.938,35.938,0,0,1,382.20839,306Z"
              transform="translate(-319.79649 -252)"
              style={{fill: '#eb001b'}}
            />
            <path
              d="M454.20349,306a35.99867,35.99867,0,0,1-58.2452,28.3032,36.00518,36.00518,0,0,0,0-56.6064A35.99867,35.99867,0,0,1,454.20349,306Z"
              transform="translate(-319.79649 -252)"
              style={{fill: '#f79e1b'}}
            />
            <path
              d="M450.76889,328.3077v-1.1589h.4673v-.2361h-1.1901v.2361h.4675v1.1589Zm2.3105,0v-1.3973h-.3648l-.41959.9611-.41971-.9611h-.365v1.3973h.2576v-1.054l.3935.9087h.2671l.39351-.911v1.0563Z"
              transform="translate(-319.79649 -252)"
              style={{fill: '#f79e1b'}}
            />
          </g>
        </g>
      </svg>
    </svg>
  );
};
