import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
} from '@zentact/common';
import {PaymentSource, PaymentStatus} from '@zentact/db';
import {
  FlatFillColors,
  FlatPillWithDot,
  HighlightedText,
  PaymentMethodIcon,
  TruncatedText,
  getTableMeta,
} from '@zentact/ui-tailwind';
import {PaymentActions} from './payment-actions';

export const paymentStatusToColor: {
  [_ in PaymentStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  AUTHORIZED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  SETTLED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  SETTLEMENT_FAILED: 'red',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  VOID_REQUESTED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  VOIDED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  PARTIAL_REFUND_REQUESTED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  PARTIALLY_REFUNDED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  REFUND_REQUESTED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  REFUNDED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  DISPUTED: 'gray',
};
export const displayPaymentStatusMap: {
  [_ in PaymentStatus]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  AUTHORIZED: 'Authorized',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  SETTLED: 'Settled',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  SETTLEMENT_FAILED: 'Settlement Failed',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  VOID_REQUESTED: 'Void Requested',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  VOIDED: 'Voided',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  PARTIAL_REFUND_REQUESTED: 'Partial Refund Requested',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  PARTIALLY_REFUNDED: 'Partially Refunded',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  REFUND_REQUESTED: 'Refund Requested',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  REFUNDED: 'Refunded',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  DISPUTED: 'Disputed',
};
export const displayPaymentSourceMap: {
  [_ in PaymentSource]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  TERMINAL: 'Terminal',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ECOMMERCE: 'Ecommerce',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  SAVED_PAYMENT_METHOD: 'Saved Payment Method',
};

const columnsHelper = createColumnHelper<PaymentListItemOutput>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
  customAttributes?: string[];
  openDetailsPanel?: (row: PaymentListItemOutput) => void;
  showOrganizationColumn?: boolean;
  paymentMethodsFilters?: string[];
  showProfit?: boolean;
};

export const renderPaymentMethod = (paymentMethod: string, cardSummary?: string | null) => (
  <div className="flex items-center gap-2">
    <PaymentMethodIcon paymentType={paymentMethod} onlyPrimaryPart />
    {cardSummary ? (
      <span>*{cardSummary}</span>
    ) : paymentMethod === 'ach' ? (
      <span>ACH</span>
    ) : (
      <span />
    )}
  </div>
);

export const getPaymentColumns = ({
  locale,
  i18n,
  openDetailsPanel,
  showOrganizationColumn,
  showProfit,
}: Props) => {
  return [
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error
    columnsHelper.accessor('createdAt', {
      cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
      header: () => i18n._('Date'),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.pspReferenceId as string;
        const textContent = props.row.original.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'pspReferenceId',
      header: () => i18n._('Transaction ID'),
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      id: 'merchantName',
      cell: props => <TruncatedText text={props.row.original.merchantAccount.businessName} />,
      header: () => i18n._('Merchant Account'),
      meta: {
        collapseAt: 'md',
      },
    }),
    ...(showOrganizationColumn
      ? [
          columnsHelper.display({
            id: 'organizationName',
            cell: props => <TruncatedText text={props.row.original.organization.name} />,
            header: () => i18n._('Organization'),
            meta: {
              collapseAt: '2xl',
            },
          }),
        ]
      : []),
    columnsHelper.accessor(
      row => formatAmount(row.authorizedAmount, locale, row.currency as CurrencyCode),
      {
        id: 'authorizedAmount',
        header: props => (getTableMeta(props.table).breakpoints['2xl'] ? 'Amount' : 'Payment'),
        meta: {
          sort: {
            isSortable: true,
          },
        },
        size: TableColumnSize.XS,
      }
    ),
    ...(showProfit
      ? [
          columnsHelper.accessor(
            row =>
              row.totalProcessingFee !== null && row.merchantSplitAmount !== null
                ? `${formatAmount(
                    row.authorizedAmount -
                      row.merchantSplitAmount -
                      row.totalProcessingFee -
                      (row.tenantRefundedAmount || 0),
                    locale,
                    row.currency as CurrencyCode
                  )}
                  (${formatPercentage(
                    row.authorizedAmount,
                    row.authorizedAmount -
                      row.merchantSplitAmount -
                      row.totalProcessingFee -
                      (row.tenantRefundedAmount || 0),
                    i18n.locale as LocaleCode
                  )})`
                : 'N/A',
            {
              id: 'profit',
              header: () => i18n._('Profit'),
              size: TableColumnSize.XXS,
            }
          ),
        ]
      : []),
    columnsHelper.display({
      cell: props =>
        renderPaymentMethod(props.row.original.paymentMethod, props.row.original.cardSummary),
      id: 'paymentMethod',
      header: () => i18n._('Payment Method'),
      meta: {
        collapseAt: '2xl',
      },
      size: 140,
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={paymentStatusToColor[info.getValue()] || 'blue'}
          label={displayPaymentStatusMap[info.getValue()] ?? info.getValue()}
        />
      ),
      meta: {
        collapseAt: 'lg',
      },
      header: () => i18n._('Status'),
      size: TableColumnSize.XS,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <PaymentActions
            row={props.row.original}
            openDetailsPanel={(...props) => openDetailsPanel?.(...props)}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
