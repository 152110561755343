// copy from node_modules\tailwindcss\stubs\config.full.js
export const tailwindDefaultBreakpoints = {
  sm: '640px',
  md: '768px', // tablet
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
  '3xl': '1792px',
  full: '1980px',
};
