import {CurrencyCode, LocaleCode} from '../../constants';
import {fromMinorUnits} from './fromMinorUnits';

export const formatAmountWithoutCurrency = (
  amount: number | undefined | null,
  locale: LocaleCode,
  currency: CurrencyCode,
  decimals: number | undefined = 2
) => {
  // todo:
  // ensure amount is in minor units and use toIntlFormat
  // return toIntlFormat({amountMinorUnits: amount ?? 0, currencyCode: currency}, locale);
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(fromMinorUnits(amount ?? 0, currency));
};
