import {useStore} from '@/store';
import {I18nProvider} from '@lingui/react';
import {ErrorCode} from '@zentact/common';
import {Loading} from '@zentact/ui-tailwind';
import {Navigate} from 'react-router-dom';
import {AppRoutes} from './AppRoutes';
import {Auth0ProviderWithRedirectCallback} from './Auth0ProviderWithRedirectCallback';

export const AppProvider = () => {
  const {isError, tenant, localeState} = useStore();

  if (localeState.isLoading || !tenant) {
    return <Loading />;
  }

  if (localeState.isError || isError) {
    return <Navigate replace to="/error" state={{errorCode: ErrorCode.ERROR_GENERIC}} />;
  }

  return (
    <Auth0ProviderWithRedirectCallback
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        organization: tenant.auth0organizationId,
        // biome-ignore lint/style/useNamingConvention: Auth0 uses snake case
        redirect_uri: `${window.location.origin}/auth/callback`,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      <I18nProvider i18n={localeState.data.i18n}>
        <AppRoutes />
      </I18nProvider>
    </Auth0ProviderWithRedirectCallback>
  );
};
