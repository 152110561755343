import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {StatementListOutput} from '@zentact/api/src/trpc/routers/statementRouter';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../table';
import {getStatementColumns} from './columns';

type Props = {
  statementList?: StatementListOutput;
  isLoading?: boolean;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string>>>;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
};

export const StatementList = ({
  statementList,
  sort,
  setSort,
  isLoading,
  trpc,
  pagination,
  onPaginationChange,
}: Props) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      columns={getStatementColumns({i18n, trpc})}
      data={statementList?.rows ?? []}
      pageCount={statementList?.pagination.pageCount ?? 0}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">{i18n._('No statements found')}</h3>
          <span className="text-sm text-gray-500">
            {i18n._(
              'Statements are generated at the beginning of the new month for all active merchants.'
            )}
          </span>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
    />
  );
};
