import {I18n} from '@lingui/core';
import {DeclinedPaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {DropDownMinimalMenuIcon} from '../../../dropdowns';

type Props = {
  row: DeclinedPaymentListItemOutput;
  i18n: I18n;
  openDetailsPanel: (row: DeclinedPaymentListItemOutput) => void;
};

export const DeclinedPaymentActions = ({row, i18n, openDetailsPanel}: Props) => {
  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {name: i18n._('View Details'), onClick: () => openDetailsPanel(row)},
  ];
  return <DropDownMinimalMenuIcon items={options} />;
};
