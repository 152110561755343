import {AdyenCheckout, CustomCard} from '@adyen/adyen-web-checkout-beta';
import {useLingui} from '@lingui/react';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {useCallback, useState} from 'react';

type SetCardsErrors = React.Dispatch<
  React.SetStateAction<{
    encryptedCardNumber: {
      message: string;
    } | null;
    encryptedExpiryDate: {
      message: string;
    } | null;
    encryptedSecurityCode: {
      message: string;
    } | null;
  }>
>;

export const useAdyenCustomCard = () => {
  const {i18n} = useLingui();
  const [customCardComponent, setCustomCardComponent] = useState<CustomCard>();

  const initiateAdyenCustomCardComponent = useCallback(
    async (domNode: HTMLElement, checkout: GetCheckoutV2Output, setCardErrors: SetCardsErrors) => {
      const configuration = {
        locale: checkout.locale,
        amount: {
          value: checkout.amount,
          currency: checkout.currency,
        },
        allowPaymentMethods: ['Card'],
        environment:
          (checkout.adyenConfiguration.environment as
            | 'test'
            | 'live'
            | 'live-us'
            | 'live-au'
            | 'live-apse'
            | 'live-in') || 'test',
        clientKey: checkout.adyenConfiguration.clientKey,
        countryCode: checkout.countryCode,

        showPayButton: true,
        translations: {
          'en-US': {
            payButtonText: i18n._('Pay'),
            'cc.cvc.920': i18n._('Enter valid security code'),
            'cc.cvc.921': i18n._('Enter valid security code'),
          },
        },
      };

      const adyenCheckout = await AdyenCheckout(configuration);

      const cutomCard = new CustomCard(adyenCheckout, {
        type: 'card',
        brands: ['mc', 'visa', 'amex', 'bcmc', 'maestro', 'discover'],
        amount: {
          value: checkout.amount,
          currency: checkout.currency,
        },
        placeholders: {
          cardNumber: '**** **** **** ****',
          expiryDate: 'MM/YY',
          securityCodeThreeDigits: 'CVC',
          securityCodeFourDigits: 'CVC',
        },
        styles: {
          base: {
            fontSize: '16px',
            padding: '12px',
          },
          placeholder: {
            color: '#9CA3AF',
            fontWeight: 'semibold',
          },
        },
        showPayButton: true,
        // Events
        onValidationError: errorList => {
          setCardErrors(
            errorList.reduce(
              (acc, error) => ({
                // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
                ...acc,
                [error.fieldType]: {message: error.errorI18n},
              }),
              {
                encryptedCardNumber: null,
                encryptedExpiryDate: null,
                encryptedSecurityCode: null,
              }
            )
          );
        },
      }).mount(domNode);
      setCustomCardComponent(cutomCard);
    },
    [setCustomCardComponent]
  );

  return {
    initiateAdyenCustomCardComponent,
    customCardComponent,
  };
};

export type InitiateAdyenCustomCardComponentFunction = ReturnType<
  typeof useAdyenCustomCard
>['initiateAdyenCustomCardComponent'];
