import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from '@heroicons/react/20/solid';
import {Table} from '@tanstack/react-table';

type Props<TItem> = {
  table: Table<TItem>;
};
export const TablePagination = <TItem,>({table}: Props<TItem>) => {
  return (
    <div className="flex items-center justify-center gap-3">
      <button
        type="button"
        className="inline-flex h-8 w-8 items-center justify-center text-gray-500"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <span className="sr-only">Prev Page</span>
        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <div>
        <label htmlFor="PaginationPage" className="sr-only">
          Page
        </label>
        <input
          type="number"
          className="h-8 w-12 rounded border border-gray-100 bg-white p-0 text-center text-xs font-medium text-gray-500 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
          value={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          id="PaginationPage"
        />
      </div>
      <div className="text-center text-xs font-medium text-gray-500 ">of</div>
      <div className="text-center text-xs font-medium text-gray-500 ">{table.getPageCount()}</div>
      <button
        type="button"
        className="inline-flex h-8 w-8 items-center justify-center text-gray-500"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <span className="sr-only">Next Page</span>
        <ChevronDoubleRightIcon className="h-5 w-5 " aria-hidden="true" />
      </button>
    </div>
  );
};
