import {useLingui} from '@lingui/react';
import {PaymentsListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {LocaleCode} from '@zentact/common';
import {TableWrapper} from '@zentact/ui-tailwind';
import {getLatestPaymentColumns} from './columns';

type Props = {
  latestPaymentsList?: PaymentsListOutput;
  isLoading?: boolean;
};

export const LatestPaymentsList = ({latestPaymentsList, isLoading}: Props) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={latestPaymentsList?.rows ?? []}
      pageCount={latestPaymentsList?.pagination.pageCount ?? 0}
      columns={getLatestPaymentColumns({
        locale: i18n.locale as LocaleCode,
        i18n,
      })}
      noDataMessage={
        <span className="text-sm font-semibold text-gray-900">{i18n._('No Payments')}</span>
      }
      isLoading={isLoading}
    />
  );
};
