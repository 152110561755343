import {Dialog, Transition} from '@headlessui/react';
import {ExclamationCircleIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {cn} from '@zentact/ui-tailwind/utils';
import {Fragment, ReactNode, useRef, useState} from 'react';
import {Button} from '../forms';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAction: () => void;
  localeText: {
    title: string;
    description: string | ReactNode;
    confirm: string;
    cancel: string;
  };
  mode?: 'error' | 'success' | 'warning';
  buttonMode?: 'error' | 'success' | 'warning';
  loading?: boolean;
  children?: JSX.Element;
  disableConfirmButton?: boolean;
  handleCancelButton?: () => void;
};

export const AlertOverlayWithConfirmation = ({
  open,
  setOpen,
  handleAction,
  localeText,
  mode = 'error',
  loading,
  children,
  disableConfirmButton = true,
  handleCancelButton,
  ...props
}: Props) => {
  const buttonMode = props.buttonMode ?? mode;
  const cancelButtonRef = useRef(null);
  const [actionLoading, setActionLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onConfirmButtonClick = async () => {
    if (disableConfirmButton) {
      // Prevents button event to become queued up
      if (buttonRef.current?.disabled) return;
      if (buttonRef.current) {
        buttonRef.current.disabled = true;
      }
    }
    setActionLoading(true);
    await handleAction();
    setActionLoading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-50 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={
                  'relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg'
                }
              >
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={cn(
                        'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                        mode === 'error'
                          ? 'bg-red-100'
                          : mode === 'warning'
                            ? 'bg-yellow-100'
                            : 'bg-primary-100'
                      )}
                    >
                      {mode === 'error' || mode === 'warning' ? (
                        <ExclamationTriangleIcon
                          className={cn(
                            'w-6 h-6 ',
                            mode === 'error' ? 'text-red-600' : 'text-yellow-600'
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationCircleIcon
                          className="w-6 h-6 text-primary-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {localeText.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm text-gray-500">{localeText.description}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {children}
                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    type="button"
                    className={cn(
                      'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto',
                      buttonMode === 'error' && 'bg-red-600 hover:bg-red-500',
                      buttonMode === 'warning' && 'bg-yellow-600 hover:bg-yellow-500'
                    )}
                    onClick={onConfirmButtonClick}
                    isLoading={actionLoading || loading}
                    ref={buttonRef}
                  >
                    {localeText.confirm}
                  </Button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      if (handleCancelButton) {
                        handleCancelButton();
                      }
                    }}
                    ref={cancelButtonRef}
                  >
                    {localeText.cancel}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
