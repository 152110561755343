import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {useMemo} from 'react';
import {useMediaQuery} from 'usehooks-ts';

export type BreakPoints = 'mobile' | keyof typeof tailwindDefaultBreakpoints;

const sortedBreakpointPairs = Object.entries(tailwindDefaultBreakpoints).sort(
  ([, a], [, b]) => parseInt(a) - parseInt(b)
);

export const useTailwindBreakpoints = (): {
  currentBreakpoint: BreakPoints;
  breakpoints: Record<BreakPoints, boolean>;
} => {
  const breakpoints = sortedBreakpointPairs.map(
    ([key, value]) => [key, useMediaQuery(`(min-width: ${value})`)] as const
  );

  const active = breakpoints.filter(([, value]) => value) as [BreakPoints, true][];
  const currentBreakpoint: BreakPoints =
    // biome-ignore lint/style/noNonNullAssertion: TODO
    active.length > 0 ? active[active.length - 1]![0] : 'mobile';

  const memoized = useMemo(
    () => ({
      mobile: true,
      ...(Object.fromEntries(breakpoints) as Record<
        keyof typeof tailwindDefaultBreakpoints,
        boolean
      >),
    }),
    [currentBreakpoint]
  );

  return {
    // biome-ignore lint/style/noNonNullAssertion: TODO
    currentBreakpoint: active.length > 0 ? active[active.length - 1]![0] : 'mobile',
    breakpoints: memoized,
  };
};
