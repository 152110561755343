import DOMPurify from 'dompurify';
import {memo} from 'react';

type Props = {
  html: string;
};

export const SanitizeHTML = memo(({html}: Props) => {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  const sanitized = DOMPurify.sanitize(html, {USE_PROFILES: {html: true}});

  return (
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    <div dangerouslySetInnerHTML={{__html: sanitized}} />
  );
});
