import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputSelect, ValidationError} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import z from 'zod';

export enum SavePaymentMethod {
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
  FORCE = 'FORCE',
  ADD_EMBED = 'ADD_EMBED',
}

const getSavePaymentMethodsSelectOptions = () => [
  {
    id: SavePaymentMethod.DISABLED,
    label: t`Disabled`,
  },
  {
    id: SavePaymentMethod.OPTIONAL,
    label: t`Optional`,
  },
  {
    id: SavePaymentMethod.FORCE,
    label: t`Force`,
  },
];

const savePaymentMethodSchema = () =>
  z.object({
    savePaymentMethod: z.nativeEnum(SavePaymentMethod),
  });

export type SavePaymentMethodData = z.infer<ReturnType<typeof savePaymentMethodSchema>>;

type Props = {
  defaultValues: {
    savePaymentMethod?: SavePaymentMethod;
  };
  onSave: (formData: SavePaymentMethodData) => void;
};

export const SavePaymentMethodForm = ({defaultValues, onSave}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<SavePaymentMethodData>({
    resolver: zodResolver(savePaymentMethodSchema()),
    defaultValues: {
      savePaymentMethod: defaultValues.savePaymentMethod,
    },
  });

  useEffect(() => {
    reset({
      savePaymentMethod: defaultValues?.savePaymentMethod,
    });
  }, [defaultValues.savePaymentMethod]);

  const submitForm = async (values: SavePaymentMethodData) => {
    setLoading(true);
    await onSave(values);
    setLoading(false);
  };

  return (
    <form className="flex flex-col gap-5 h-52 sm:h-44">
      <div className="flex flex-col w-full gap-6 md:w-1/2">
        <div className="sm:col-span-3">
          <div className="sm:col-span-3">
            <Controller
              control={control}
              defaultValue={defaultValues.savePaymentMethod}
              name="savePaymentMethod"
              render={({field}) => (
                <>
                  <InputSelect
                    label={t`Save Payment Method`}
                    value={field.value}
                    options={getSavePaymentMethodsSelectOptions()}
                    onChange={value => {
                      field.onChange(value);
                    }}
                  />
                  <ValidationError isVisible={Boolean(errors.savePaymentMethod)}>
                    {errors.savePaymentMethod?.message}
                  </ValidationError>
                  {field.value === SavePaymentMethod.FORCE && (
                    <p className="mt-2 text-xs leading-5 text-gray-500">
                      <Trans>
                        Prevent users from making payments unless they agree to save a payment
                        method.
                      </Trans>
                    </p>
                  )}
                  {field.value === SavePaymentMethod.OPTIONAL && (
                    <p className="mt-2 text-xs leading-5 text-gray-500">
                      <Trans>Allow users to choose whether or not to save a payment method.</Trans>
                    </p>
                  )}
                  {field.value === SavePaymentMethod.DISABLED && (
                    <p className="mt-2 text-xs leading-5 text-gray-500">
                      <Trans>Do not support saved payment methods.</Trans>
                    </p>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-6">
        <Button
          type="button"
          className="items-center gap-1 w-fit"
          size="xl"
          onClick={handleSubmit(submitForm)}
          isLoading={loading}
          disabled={loading}
        >
          <Trans>Save Payment Methods</Trans>
        </Button>
      </div>
    </form>
  );
};
