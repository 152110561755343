import {trpc} from '@/api/trpcClient';

import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  AddMerchantAccountForm,
  MerchantAccountFormData,
  getMerchantAccountFormSchema,
} from '../add-merchant-account-form';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const AddMerchantAccount = ({isOpen, onCancel}: Props) => {
  const {showErrorNotification} = useNotification();
  const form = useForm<MerchantAccountFormData>({
    resolver: zodResolver(getMerchantAccountFormSchema()),
  });
  const {user} = useAuth0();

  const {handleSubmit, setError} = form;
  const addMerchant = trpc.merchantAccount.addSubMerchantToMyOrganization.useMutation({
    onSuccess: registrationUrl => {
      window.location.replace(registrationUrl);
    },
    onError: error => {
      if (isFormattedTrpcError(error)) {
        if (error.data.errorCode === ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS) {
          setError('name', {
            type: 'manual',
            message: t`Merchant registration with the same business name already exists`,
          });

          return;
        }
        if (error.data.errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_DOES_NOT_EXIST) {
          showErrorNotification(t`Failed to add new merchant account`, error.message);
          return;
        }
      }

      showErrorNotification(
        t`Failed to add new merchant account`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [actionLoading, setActionLoading] = useState(false);

  const onSubmit = useCallback(async (data: MerchantAccountFormData) => {
    if (buttonRef.current?.disabled) return;
    if (buttonRef.current) {
      buttonRef.current.disabled = true;
    }
    setActionLoading(true);
    await addMerchant.mutateAsync({
      merchant: {businessName: data.name},
    });
    setActionLoading(false);
  }, []);

  const onCancelClick = useCallback(
    (_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, isCancelClick?: boolean) => {
      onCancel();
      if (isCancelClick) {
        form.reset();
      }
    },
    []
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Add Merchant Account`}
      text={t`If you are an authorized signer, you can sign up additional merchant accounts. This process will launch the standard application process.`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={actionLoading}
              ref={buttonRef}
            >
              <Trans>Start Application</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddMerchantAccountForm form={form} initialValues={{email: user?.email}} />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
