import {LocaleCode} from '../constants/enums';

const errorMessageMap: {[key: string]: {[K in LocaleCode]: string}} = {
  '1_10': {
    [LocaleCode.en_US]: "Information couldn't be verified",
    [LocaleCode.ps_DO]: "Information couldn't be verified",
  },
  '1_11': {
    [LocaleCode.en_US]: "Document didn't meet requirements",
    [LocaleCode.ps_DO]: "Document didn't meet requirements",
  },
  '1_12': {
    [LocaleCode.en_US]: 'Legal entity declined',
    [LocaleCode.ps_DO]: 'Legal entity declined',
  },
  '1_13': {
    [LocaleCode.en_US]: "Verification couldn't be completed",
    [LocaleCode.ps_DO]: "Verification couldn't be completed",
  },
  '1_14': {
    [LocaleCode.en_US]: 'Capability not allowed for type individual',
    [LocaleCode.ps_DO]: 'Capability not allowed for type individual',
  },
  '1_30': {
    [LocaleCode.en_US]: "Individual details couldn't be verified",
    [LocaleCode.ps_DO]: "Individual details couldn't be verified",
  },
  '1_31': {
    [LocaleCode.en_US]: 'ID document is needed',
    [LocaleCode.ps_DO]: 'ID document is needed',
  },
  '1_32': {
    [LocaleCode.en_US]: "ID document couldn't be processed",
    [LocaleCode.ps_DO]: "ID document couldn't be processed",
  },
  '1_33': {
    [LocaleCode.en_US]: "ID document didn't meet requirements",
    [LocaleCode.ps_DO]: "ID document didn't meet requirements",
  },
  '1_34': {
    [LocaleCode.en_US]: "Image of the ID document didn't meet requirements",
    [LocaleCode.ps_DO]: "Image of the ID document didn't meet requirements",
  },
  '1_35': {
    [LocaleCode.en_US]: "Individual details didn't match the ID document",
    [LocaleCode.ps_DO]: "Individual details didn't match the ID document",
  },
  '1_36': {
    [LocaleCode.en_US]: "Proof of residency didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of residency didn't meet requirements",
  },
  '1_37': {
    [LocaleCode.en_US]: "Image of the proof of residency didn't meet the requirements",
    [LocaleCode.ps_DO]: "Image of the proof of residency didn't meet the requirements",
  },
  '1_38': {
    [LocaleCode.en_US]: "Individual details didn't match the proof of residency",
    [LocaleCode.ps_DO]: "Individual details didn't match the proof of residency",
  },
  '1_39': {
    [LocaleCode.en_US]: "Proof of national ID number didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of national ID number didn't meet requirements",
  },
  '1_40': {
    [LocaleCode.en_US]: "Image of the proof of national ID number didn't meet the requirements",
    [LocaleCode.ps_DO]: "Image of the proof of national ID number didn't meet the requirements",
  },
  '1_41': {
    [LocaleCode.en_US]: "Individual details didn't match the proof of national ID number",
    [LocaleCode.ps_DO]: "Individual details didn't match the proof of national ID number",
  },
  '1_42': {
    [LocaleCode.en_US]: "Individual details didn't meet the format requirements",
    [LocaleCode.ps_DO]: "Individual details didn't meet the format requirements",
  },
  '1_43': {
    [LocaleCode.en_US]: "Image of the proof of the individual tax ID didn't meet the requirements",
    [LocaleCode.ps_DO]: "Image of the proof of the individual tax ID didn't meet the requirements",
  },
  '1_44': {
    [LocaleCode.en_US]: "Proof of the individual tax ID didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of the individual tax ID didn't meet requirements",
  },
  '1_45': {
    [LocaleCode.en_US]: "Individual details didn't match the proof of the individual tax ID",
    [LocaleCode.ps_DO]: "Individual details didn't match the proof of the individual tax ID",
  },
  '1_50': {
    [LocaleCode.en_US]: "Organization details couldn't be verified",
    [LocaleCode.ps_DO]: "Organization details couldn't be verified",
  },
  '1_51': {
    [LocaleCode.en_US]: "Organization details didn't match the document",
    [LocaleCode.ps_DO]: "Organization details didn't match the document",
  },
  '1_52': {
    [LocaleCode.en_US]: "Registration document didn't meet requirements",
    [LocaleCode.ps_DO]: "Registration document didn't meet requirements",
  },
  '1_53': {
    [LocaleCode.en_US]: "Tax document didn't meet requirements",
    [LocaleCode.ps_DO]: "Tax document didn't meet requirements",
  },
  '1_54': {
    [LocaleCode.en_US]: "Proof of address didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of address didn't meet requirements",
  },
  '1_55': {
    [LocaleCode.en_US]: 'Proof of ownership is needed',
    [LocaleCode.ps_DO]: 'Proof of ownership is needed',
  },
  '1_56': {
    [LocaleCode.en_US]: "Organization ownership couldn't be verified",
    [LocaleCode.ps_DO]: "Organization ownership couldn't be verified",
  },
  '1_57': {
    [LocaleCode.en_US]: "Submitted affiliation document didn't meet the requirements",
    [LocaleCode.ps_DO]: "Submitted affiliation document didn't meet the requirements",
  },
  '1_58': {
    [LocaleCode.en_US]: "VAT document didn't meet requirements",
    [LocaleCode.ps_DO]: "VAT document didn't meet requirements",
  },
  '1_59': {
    [LocaleCode.en_US]: "Organization details didn't meet the format requirements",
    [LocaleCode.ps_DO]: "Organization details didn't meet the format requirements",
  },
  '1_60': {
    [LocaleCode.en_US]: "Legal arrangement details couldn't be verified",
    [LocaleCode.ps_DO]: "Legal arrangement details couldn't be verified",
  },
  '1_61': {
    [LocaleCode.en_US]: "Constitutional document didn't meet requirements",
    [LocaleCode.ps_DO]: "Constitutional document didn't meet requirements",
  },
  '1_62': {
    [LocaleCode.en_US]: "Legal arrangement details didn't match the constitutional document",
    [LocaleCode.ps_DO]: "Legal arrangement details didn't match the constitutional document",
  },
  '1_63': {
    [LocaleCode.en_US]:
      "Legal arrangement's affiliated member's details didn't match the constitutional document",
    [LocaleCode.ps_DO]:
      "Legal arrangement's affiliated member's details didn't match the constitutional document",
  },
  '1_70': {
    [LocaleCode.en_US]: "Bank account couldn't be verified",
    [LocaleCode.ps_DO]: "Bank account couldn't be verified",
  },
  '1_71': {
    [LocaleCode.en_US]: "Bank account details couldn't be verified",
    [LocaleCode.ps_DO]: "Bank account details couldn't be verified",
  },
  '1_72': {
    [LocaleCode.en_US]: 'Verification could not be completed',
    [LocaleCode.ps_DO]: 'Verification could not be completed',
  },
  '1_73': {
    [LocaleCode.en_US]: 'Bank account access could not be authenticated',
    [LocaleCode.ps_DO]: 'Bank account access could not be authenticated',
  },
  '1_74': {
    [LocaleCode.en_US]: 'Bank statement is needed',
    [LocaleCode.ps_DO]: 'Bank statement is needed',
  },
  '1_75': {
    [LocaleCode.en_US]: "Bank statement didn't meet requirements",
    [LocaleCode.ps_DO]: "Bank statement didn't meet requirements",
  },
  '1_76': {
    [LocaleCode.en_US]: "Bank details didn't match the bank document",
    [LocaleCode.ps_DO]: "Bank details didn't match the bank document",
  },
  '1_77': {
    [LocaleCode.en_US]: 'The entity is in pending status',
    [LocaleCode.ps_DO]: 'The entity is in pending status',
  },
  '1_81': {
    [LocaleCode.en_US]: "Signatory details didn't match proof of signatory",
    [LocaleCode.ps_DO]: "Signatory details didn't match proof of signatory",
  },
  '1_82': {
    [LocaleCode.en_US]: "Proof of signatory didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of signatory didn't meet requirements",
  },
  '1_83': {
    [LocaleCode.en_US]: "Signatory details couldn't be verified",
    [LocaleCode.ps_DO]: "Signatory details couldn't be verified",
  },
  '1_90': {
    [LocaleCode.en_US]: "Industry couldn't be verified",
    [LocaleCode.ps_DO]: "Industry couldn't be verified",
  },
  '1_91': {
    [LocaleCode.en_US]: "Proof of industry didn't meet requirements",
    [LocaleCode.ps_DO]: "Proof of industry didn't meet requirements",
  },
  '1_92': {
    [LocaleCode.en_US]: 'No web address available to perform verification',
    [LocaleCode.ps_DO]: 'No web address available to perform verification',
  },
  '2_8': {
    [LocaleCode.en_US]: 'Some information was missing',
    [LocaleCode.ps_DO]: 'Some information was missing',
  },
  '2_901': {
    [LocaleCode.en_US]: 'PCI forms are not signed',
    [LocaleCode.ps_DO]: 'PCI forms are not signed',
  },
  '2_902': {
    [LocaleCode.en_US]: 'Terms Of Service forms are not accepted',
    [LocaleCode.ps_DO]: 'Terms Of Service forms are not accepted',
  },
  '2_904': {
    [LocaleCode.en_US]: 'Integration is not fully implemented',
    [LocaleCode.ps_DO]: 'Integration is not fully implemented',
  },
  '3_10': {
    [LocaleCode.en_US]: 'Review of data is required',
    [LocaleCode.ps_DO]: 'Review of data is required',
  },
  default: {
    [LocaleCode.en_US]: 'Unknown error',
    [LocaleCode.ps_DO]: 'Unknown error',
  },
};

export const getErrorMessage = (errorCode: string, locale: LocaleCode = LocaleCode.en_US) => {
  const localizedErrorMessage = errorMessageMap[errorCode]?.[locale];
  return localizedErrorMessage || errorMessageMap.default?.[locale];
};
