import {useLingui} from '@lingui/react';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {formatLocaleDate} from '@zentact/common';
import {TruncatedText} from '../../../';

type SavedPaymentMethodCustomerDetailsProps = {
  shopper: ShopperListItemOutput | null;
};

export const SavedPaymentMethodCustomerDetails = ({
  shopper,
}: SavedPaymentMethodCustomerDetailsProps) => {
  const {i18n} = useLingui();

  if (!shopper) {
    return null;
  }
  const {updatedAt, name, merchantShopperId, email, lastCharged} = shopper;

  return (
    <div className="border-gray-100">
      <dl className="divide-y divide-gray-100">
        <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Name')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {name || i18n._('N/A')}
          </dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Customer ID')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <TruncatedText text={merchantShopperId} />
          </dd>
        </div>
        {!!email && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Email')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <TruncatedText text={email} />
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Last Updated')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {formatLocaleDate(updatedAt)}
          </dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Last Charged')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {lastCharged ? formatLocaleDate(lastCharged) : i18n._('N/A')}
          </dd>
        </div>
      </dl>
    </div>
  );
};
