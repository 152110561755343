import {Control, FieldValues} from 'react-hook-form';
import PhoneInputWithCountry, {Country} from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import {InputText} from '../input-text';

type InputPhoneProps<FormValues extends FieldValues> = {
  name: string;
  defaultCountry?: string | null;
  control: Control<FormValues>;
};

export const InputPhone = <FormValues extends FieldValues>({
  name,
  defaultCountry = 'US',
  control,
}: InputPhoneProps<FormValues>) => {
  return (
    <PhoneInputWithCountry
      name={name}
      defaultCountry={defaultCountry as Country}
      control={control}
      inputComponent={InputText}
    />
  );
};

export default InputPhone;
