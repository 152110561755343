export const AchIcon = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250 77.65"
      height="60"
      width="80"
    >
      <title>ACH</title>
      <defs>
        <style>{'.cls-1{fill:#0ac500;}.cls-2{fill:#006eff;}'}</style>
      </defs>
      <path className="cls-1" d="M88.27,30.48A7.5,7.5,0,1,0,95.76,38,7.5,7.5,0,0,0,88.27,30.48Z" />
      <path
        className="cls-1"
        d="M69.83,19.5a6.11,6.11,0,1,0,6.1,6.11A6.11,6.11,0,0,0,69.83,19.5Z"
      />
      <path className="cls-1" d="M69.83,44.24a6.1,6.1,0,1,0,6.1,6.1A6.11,6.11,0,0,0,69.83,44.24Z" />
      <circle className="cls-1" cx="54.08" cy="15.58" r="4.99" />
      <path className="cls-1" d="M40.47,3.76a4.1,4.1,0,1,0,4.09,4.1A4.11,4.11,0,0,0,40.47,3.76Z" />
      <circle className="cls-1" cx="54.08" cy="60.37" r="4.99" />
      <path className="cls-1" d="M40.47,64a4.1,4.1,0,1,0,4.09,4.09A4.1,4.1,0,0,0,40.47,64Z" />
      <path className="cls-2" d="M52.23,30.48A7.5,7.5,0,1,0,59.73,38,7.5,7.5,0,0,0,52.23,30.48Z" />
      <circle className="cls-2" cx="86.42" cy="15.58" r="4.99" />
      <path className="cls-2" d="M100,12a4.1,4.1,0,1,0-4.1-4.09A4.09,4.09,0,0,0,100,12Z" />
      <circle className="cls-2" cx="86.42" cy="60.37" r="4.99" />
      <path className="cls-2" d="M100,64a4.1,4.1,0,1,0,4.09,4.09A4.1,4.1,0,0,0,100,64Z" />
      <path d="M142.45,35.53H139l-1.47-3.35h-7.75l-1.44,3.35h-3.43l8.69-18.85h.11Zm-11.56-6h5.62l-2.83-6.42Z" />
      <path d="M158.5,19.43l-2,2.27a6.22,6.22,0,0,0-4.71-2.12c-3.65,0-6.15,2.8-6.15,6.7S148.15,33,151.8,33a6.08,6.08,0,0,0,4.79-2.22l2,2.32a8.57,8.57,0,0,1-6.75,2.79c-5.42,0-9.32-4.1-9.32-9.57s3.9-9.6,9.32-9.6A8.72,8.72,0,0,1,158.5,19.43Z" />
      <path d="M175,35.53h-3.15v-8h-8.44v8h-3.15V17h3.15v7.61h8.44V17H175Z" />
      <path d="M138.17,51.87V40.4h3.15V59.25h-.1L129.93,47.43V58.92h-3.15V40.08h.1Z" />
      <path d="M155.65,52.45v1h-9c.13,2,1.36,3.1,3.48,3.1a4,4,0,0,0,3.23-1.43l2,1.58a6.39,6.39,0,0,1-5.29,2.42,6.23,6.23,0,0,1-6.5-6.57c0-3.83,2.55-6.63,6.22-6.63C153.71,46,155.65,48.89,155.65,52.45Zm-9-1.11h6a2.78,2.78,0,0,0-2.88-3A3,3,0,0,0,146.68,51.34Z" />
      <path d="M161.67,46.22h3.83v2.57h-3.83v5.65a1.83,1.83,0,0,0,1.92,2,3.83,3.83,0,0,0,1.79-.43l.8,2.44a5.12,5.12,0,0,1-2.82.73c-2.89,0-4.73-1.66-4.73-4.73V48.79h-2.4V46.22h2.4V42h3Z" />
      <path d="M179,59.17h-.1l-3.5-7-3.53,7h-.1l-5.84-13h3.25l2.87,7,3.3-7h.1l3.27,7,2.9-7h3.23Z" />
      <path d="M198,52.6A6.6,6.6,0,1,1,191.4,46,6.39,6.39,0,0,1,198,52.6Zm-10.13,0c0,2.27,1.46,3.9,3.5,3.9A3.6,3.6,0,0,0,195,52.6c0-2.3-1.48-4-3.55-4S187.9,50.3,187.9,52.6Z" />
      <path d="M206.64,49h-.27a3.86,3.86,0,0,0-3.3,1.91v8h-3V46.22h2.72l.18,1.69A4.26,4.26,0,0,1,206.64,46Z" />
      <path d="M211.23,52l5.16-5.8h3.45l-4.25,4.89,4.68,7.81h-3.45L213.5,53.4l-2.27,2.4v3.12h-3V39.77h3Z" />
    </svg>
  );
};
