import {CheckIcon} from '@heroicons/react/20/solid';
import {ReactNode} from 'react';
import {cn} from '../../utils';

export type CircleWithLabelProps<Id extends string | number = string | number> = {
  className?: string;
  id: Id;
  onClick?: (id: Id) => void;
  disabled?: boolean;
  title?: ReactNode | string;
  type: 'complete' | 'current' | 'upcoming';
  description?: ReactNode;
};

export const CircleWithLabel = <Id extends string | number = string | number>({
  className,
  id,
  onClick,
  disabled,
  title,
  description,
  type,
}: CircleWithLabelProps<Id>) => {
  const isInteractive = Boolean(onClick) && !disabled;
  // biome-ignore lint/style/useNamingConvention: Component name convention
  const Wrapper: keyof JSX.IntrinsicElements = isInteractive ? 'button' : 'div';

  return (
    <li className={cn('list-none', className)}>
      <Wrapper
        {...(isInteractive ? {type: 'button'} : {})}
        onClick={onClick ? () => onClick(id) : undefined}
        className={cn(
          'group relative flex text-left',
          description ? 'items-start' : 'items-center',
          isInteractive ? 'cursor-pointer' : 'cursor-default'
        )}
      >
        <span className="flex h-9 items-center">
          <span
            className={cn(
              'relative z-10 flex h-8 w-8 items-center justify-center rounded-full  ',
              type === 'complete' && 'bg-primary-600',
              type === 'complete' && isInteractive && 'group-hover:bg-primary-800',
              type === 'current' && 'border-primary-600 border-2 bg-white',
              type === 'upcoming' && 'border-2 border-gray-300  bg-white',
              type === 'upcoming' && isInteractive && 'group-hover:border-gray-400'
            )}
          >
            {type === 'complete' && <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />}
            {type === 'current' && <span className="bg-primary-600 h-2.5 w-2.5 rounded-full" />}
            {type === 'upcoming' && (
              <span
                className={cn(
                  'h-2.5 w-2.5 rounded-full bg-transparent ',
                  isInteractive && 'group-hover:bg-gray-300'
                )}
              />
            )}
          </span>
        </span>
        {title && (
          <span className="ml-4 flex min-w-0 flex-col">
            <span
              className={cn(
                'text-sm font-medium',
                type === 'current' && 'text-primary-600',
                type === 'upcoming' && 'text-gray-500'
              )}
            >
              {title}
            </span>
            <span className="text-sm text-gray-500">{description}</span>
          </span>
        )}
      </Wrapper>
    </li>
  );
};
