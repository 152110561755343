export enum RefusalReason {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Refused = 'Refused',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Referral = 'Referral',
  'Acquirer Error' = 'Acquirer Error',
  'Blocked Card' = 'Blocked Card',
  'Expired Card' = 'Expired Card',
  'Invalid Amount' = 'Invalid Amount',
  'Invalid Card Number' = 'Invalid Card Number',
  'Issuer Unavailable' = 'Issuer Unavailable',
  'Not supported' = 'Not supported',
  '3D Not Authenticated' = '3D Not Authenticated',
  'Not enough balance' = 'Not enough balance',
  'Acquirer Fraud' = 'Acquirer Fraud',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Cancelled = 'Cancelled',
  'Shopper Cancelled' = 'Shopper Cancelled',
  'Invalid Pin' = 'Invalid Pin',
  'Pin tries exceeded' = 'Pin tries exceeded',
  'Pin validation not possible' = 'Pin validation not possible',
  FRAUD = 'FRAUD',
  'Not Submitted' = 'Not Submitted',
  'FRAUD-CANCELLED' = 'FRAUD-CANCELLED',
  'Transaction Not Permitted' = 'Transaction Not Permitted',
  'CVC Declined' = 'CVC Declined',
  'Restricted Card' = 'Restricted Card',
  'Revocation Of Auth' = 'Revocation Of Auth',
  'Declined Non Generic' = 'Declined Non Generic',
  'Withdrawal amount exceeded' = 'Withdrawal amount exceeded',
  'Withdrawal count exceeded' = 'Withdrawal count exceeded',
  'Issuer Suspected Fraud' = 'Issuer Suspected Fraud',
  'AVS Declined' = 'AVS Declined',
  'Card requires online pin' = 'Card requires online pin',
  'No checking account available on Card' = 'No checking account available on Card',
  'No savings account available on Card' = 'No savings account available on Card',
  'Mobile pin required' = 'Mobile pin required',
  'Contactless fallback' = 'Contactless fallback',
  'Authentication required' = 'Authentication required',
  'RReq not received from DS' = 'RReq not received from DS',
  'Current AID is in Penalty Box' = 'Current AID is in Penalty Box',
  'CVM Required Restart Payment' = 'CVM Required Restart Payment',
  '3DS Authentication Error' = '3DS Authentication Error',
  'Transaction blocked by Adyen to prevent excessive retry fees' = 'Transaction blocked by Adyen to prevent excessive retry fees',
}
