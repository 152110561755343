import {Fragment} from 'react';
import {CircleWithLabel, CircleWithLabelProps} from '../../layout/circle-with-label';
import {cn} from '../../utils';

export type StepperHorizontalCirclesItem = {id: string; title?: string; description?: string};

export type StepperHorizontalCirclesProps = {
  steps: StepperHorizontalCirclesItem[];
  selectedIndex: number;
  disableCompleted?: boolean;
  onChange?: (id: string) => void;
  className?: string;
};

const getStepType = (stepIndex: number, selectedIndex: number): CircleWithLabelProps['type'] => {
  switch (true) {
    case stepIndex < selectedIndex:
      return 'complete';
    case stepIndex === selectedIndex:
      return 'current';
    case stepIndex > selectedIndex:
      return 'upcoming';
    default:
      throw new Error('absurd');
  }
};

export const StepperHorizontalCircles = ({
  steps,
  selectedIndex,
  onChange,
  disableCompleted,
  className,
}: StepperHorizontalCirclesProps) => {
  return (
    <div
      className={cn('flex w-full justify-center overflow-auto', className)}
      aria-label="Progress"
    >
      <ol className="flex w-full items-center justify-between">
        {steps.map((step, stepIndex) => {
          const type = getStepType(stepIndex, selectedIndex);
          const isNotLast = stepIndex !== steps.length - 1;
          const isDisabled = type === 'current' || (disableCompleted && type === 'complete');
          return (
            <Fragment key={step.id}>
              <div title={step.title}>
                <CircleWithLabel
                  id={step.id}
                  onClick={onChange}
                  disabled={isDisabled}
                  type={type}
                />
              </div>
              {isNotLast && (
                <div
                  className={cn(
                    'flex h-0.5 grow basis-full items-center',
                    type === 'complete' ? 'bg-primary-600' : 'bg-gray-300'
                  )}
                  aria-hidden="true"
                />
              )}
            </Fragment>
          );
        })}
      </ol>
    </div>
  );
};
