import {useLingui} from '@lingui/react';
import type {MerchantAccountStatus} from '@prisma/client';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {InputSearchSelect} from '@zentact/ui-tailwind';
import {useEffect} from 'react';

const ALL = 'all';
type MerchantAccountOption = {
  id: string;
  businessName: string;
  status: MerchantAccountStatus | MerchantAccountPublicStatus;
};

type Props = {
  selectedMerchantAccount?: string;
  merchantAccountsOptions: MerchantAccountOption[];
  onSelectMerchantAccount: (merchantAccountId?: string) => void;
  allLabel?: string;
  // Optional name for form submission
  name?: string;
};

export const MerchantAccountsPicker = ({
  selectedMerchantAccount = ALL,
  merchantAccountsOptions,
  onSelectMerchantAccount,
  allLabel,
  name,
}: Props) => {
  const {i18n} = useLingui();
  useEffect(() => {
    if (merchantAccountsOptions.length === 1 && merchantAccountsOptions[0]) {
      onSelectMerchantAccount(merchantAccountsOptions[0].id);
    }
  }, []);
  const {activeOptions, inactiveOptions} = merchantAccountsOptions.reduce<{
    activeOptions: MerchantAccountOption[];
    inactiveOptions: MerchantAccountOption[];
  }>(
    (acc, option) => {
      if (option.status === 'ACTIVE') {
        acc.activeOptions.push(option);
      } else {
        acc.inactiveOptions.push(option);
      }
      return acc;
    },
    {activeOptions: [], inactiveOptions: []}
  );

  return (
    <InputSearchSelect
      value={
        merchantAccountsOptions.length === 1 && merchantAccountsOptions[0]
          ? merchantAccountsOptions[0].id
          : selectedMerchantAccount
      }
      name={name}
      options={[
        ...(allLabel
          ? [
              {
                id: ALL,
                label: allLabel,
              },
            ]
          : []),
        ...[...activeOptions, ...inactiveOptions].map(({id, businessName, status}) => ({
          id,
          label: businessName,
          ...(status !== 'ACTIVE' && {
            className: 'text-gray-400',
            classNameActive: 'text-gray-400',
            tooltipText: i18n._('Merchant is not active'),
          }),
        })),
      ]}
      label=""
      className="w-full mt-0"
      disabled={merchantAccountsOptions.length <= 1}
      labelClassName="gap-0"
      onChange={merchantAccountId =>
        onSelectMerchantAccount(
          merchantAccountId === ALL ? undefined : (merchantAccountId as string)
        )
      }
      placeholder={allLabel}
    />
  );
};
