import {I18n} from '@lingui/core';

export const getDefenseDocumentTypeCode = (defenseDocumentTypeCode: string, i18n: I18n) => {
  const defenseDocumentTypeCodeMap: {[key: string]: string} = {
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AcquirerRFItransactionDetails: i18n._('Automatically gathered transaction details.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AddendumDocumentation: i18n._(
      'Documentation showing that transaction is an addendum to a previous transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AdditionalTransactions: i18n._('Additional transactions connected with the disputed flight.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AlternativeDefenseMaterial: i18n._('Defense material.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ARDnotProvided: i18n._('Proof that Acquirer Reference Data (ARD) was not provided.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ATMSupportingDocuments: i18n._(
      'See Appendix A of Chargebacks – Mastercard Europe ATM Transactions.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AuthorisationLog: i18n._(
      'Authorization log or documentation showing that the authorization took place.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    AuthorisedTransaction: i18n._('Proof that the transaction was authorized.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CancellationNeverAccepted: i18n._('Rebuttal stating that the cancellation was never accepted.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CardholderIdentification: i18n._(
      'Identification documentation showing a link to the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CardholderNotReplied: i18n._(
      'Documentation showing that the cardholder was notified but did not reply.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CardholderRenewedMembership: i18n._(
      'Documentation showing that the cardholder renewed their membership after cancellation.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CardPresent: i18n._(
      'Proof that the card was present during the transaction and there is a cardholder signature.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ChargebackRemedied: i18n._(
      'Documentation showing that the chargeback was remedied. Other defenses are not applicable.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ChipLiabilityNotApplicable: i18n._('Proof that chip liability was not applicable.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ChipLiabilityShift: i18n._('Proof that the transaction was authorized.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CompellingEvidence: i18n._('Compelling evidence to defend the dispute.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CorrectedMcc: i18n._('Correct MCC.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CorrectedMerchantDetails: i18n._('Correct card acceptor location or description.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    CorrectTransactionCurrency: i18n._('Documentation proving the correct currency was provided.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    DateMerchandiseShipped: i18n._('Date when the merchandise was shipped.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    DefenseMaterial: i18n._('Defense material that remedies the chargeback.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    DisclosureAtPointOfInteraction: i18n._(
      'Rebuttal or proof that proper disclosure was given to the cardholder at the point of interaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    DuplicateChargeback: i18n._(
      'First chargeback reference number and date of original chargeback.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    EvidenceOfCardholderParticipation: i18n._(
      'Compelling evidence that the cardholder participated in the transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FlightManifest: i18n._('Flight manifest showing the cardholders name.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FlightTicket: i18n._('Flight ticket or boarding pass showing the cardholders name.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FlightTicketAtBillingAddress: i18n._(
      'Proof of receipt of the flight ticket at the cardholders billing address.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FlightTicketUsed: i18n._(
      'Documentation proving that the airline tickets were used by the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FligthTookPlace: i18n._('Documentation proving that the airline rendered the service.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FormalAffidavitOfFailure: i18n._(
      'Formal affidavit of telecommunication failure or POS device failure.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    FrequentFlyer: i18n._(
      'Credits of frequent flyer miles for the flight showing connection to the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    GoodsNotReturned: i18n._('Statement that you did not receive the goods back from the shopper.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    GoodsRepairedOrReplaced: i18n._(
      'Written rebuttal documenting that the goods were repaired or replaced.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    GoodsWereAsDescribed: i18n._('Proof showing that the goods were delivered as described.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    IncorrectTransactionDate: i18n._('Correct transaction date and incorrect transaction date.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    IncreasedTransactionAmount: i18n._(
      'Documentation to support that the cardholder is responsible for the disputed amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    InvalidCancellationCode: i18n._('Documentation to explain the invalid cancellation code.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    InvalidChargeback: i18n._(
      'Documentation to support that the chargeback is remedied or not valid.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    IssuerAuthorisedTransaction: i18n._('Proof that the issuer authorized the transaction.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    LiabilityShiftFullUCAF: i18n._('Mastercard SecureCode SL2 (Global Liability Shift)'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    LiabilityShiftMerchantUCAF: i18n._('Mastercard SecureCode SL1 (Global Liability Shift)'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    MerchandiseDescription: i18n._('A description of the merchandise or services.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    MerchandiseNeverReturned: i18n._('Rebuttal stating that the merchandise was never returned.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    MerchandiseNotCounterfeit: i18n._(
      'Documentation to support your claim that the merchandise was not counterfeit.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    MerchantProofOfRecurringTransaction: i18n._(
      'Proof that the transaction is a recurring transaction with no previous chargeback.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    MultipleAuthorisations: i18n._('Proof of multiple authorizations.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NoAuthorisationRequiredNotX2X: i18n._('Proof that the service code on the card was not X2X.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NoAuthorisationRequiredOffline: i18n._(
      'Proof that the transaction was processed on an offline POS device.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NotCancelled: i18n._('Documentation showing that the service was not cancelled.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NotChargebacked: i18n._('Documentation showing that the transaction was not charged back.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NotChargebackedAuto: i18n._(
      'Documentation showing that the transaction was not charged back. (automatic response).'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NotNotified: i18n._(
      'Documentation showing that the acquirer and your business were not notified of cancellation of membership.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    NotX2X: i18n._('Proof that the service code on the card was not X2X.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    OriginalAmount: i18n._(
      'The original transaction amount if the disputed transaction represents partial shipment.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PaperAirlineTicket: i18n._(
      'Documentation showing that the cardholder was issued a paper airline ticket.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PartialAuthorisation: i18n._(
      'Proof that the captured amount was partially authorized, and that the amount that was not authorized is higher than the amount that was charged back.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PassengerIdentification: i18n._(
      'Passenger identification documentation showing a link to the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PaymentByOtherMeans: i18n._(
      'An appropriate explanation and documentation showing two separate transactions.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PayPassTransaction: i18n._(
      'Proof that the transaction was a PayPass transaction below the chargeback protection amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PINTransaction: i18n._('Proof that the PIN was provided with the transaction'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PosDeviceFailure: i18n._(
      'Documentation showing that there was a POS failure at the time of purchase.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PositiveAVS: i18n._('Documentation showing a positive AVS response (X or Y).'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PresentmentWithinTimeLimit: i18n._(
      'Proof that the transaction was presented within the given time limit.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    PrintedSignedTerminalReceipt: i18n._('A printed and signed terminal receipt.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfAbilityToProvideServices: i18n._(
      'Proof showing that you are able to provide the services.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfAccountTakeover: i18n._(
      'Documentation showing that the transaction resulted from an account takeover.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfAttendedPOSTerminal: i18n._(
      'Documentation showing that the transaction occurred on an attended POS terminal.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfCardPresenceAndSignatureChipNoPIN: i18n._(
      'Proof that the card was present and the chip was used.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfCardPresenceAndSignatureWithTerminalReceipt: i18n._(
      'Proof that the card was present and that a signed terminal receipt is available.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfCardPresenceSignatureOrChipPIN: i18n._(
      'Proof that the card was present and that a cardholder signature is available.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfGoodsOrServicesProvided: i18n._(
      'Proof showing that the goods or services were provided to the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfNoShow: i18n._('Documentation showing that a reservation was made by the cardholder.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfRecurringTransaction: i18n._(
      'Description of goods and proof that the transaction is a recurring transaction with no previous chargeback.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ProofOfRetailSaleRatherThanCredit: i18n._(
      'A copy of the Transaction Identification Document (TID) as proof that the transaction involved a retail sale rather than a credit.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    QPSTransaction: i18n._('Proof that the QPS transaction has an invalid reason code.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ReasonForReturn: i18n._('Documentation showing that the reason for return was supplied.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    RetrievalRequestFulfilled: i18n._(
      'Automatically generated proof that a retrieval request has been fulfilled.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ReturnedMerchandiseNotReceived: i18n._(
      'Documentation showing that you did not receive the merchandise.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    SaleTermsNotMisrepresented: i18n._(
      'Documentation showing that the terms of sale of the merchandise or services were not misrepresented.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ShippedToAVS: i18n._(
      'Documentation showing that the merchandise was sent to the AVS-confirmed billing address.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    ShipToAddress: i18n._('Documentation showing the ship to address.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    SuspiciousTransaction: i18n._(
      'Documentation showing that the issuer was advised that the transaction was suspicious.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TechnicalFallback: i18n._(
      'Documentation showing that the transaction was the result of a technical fallback.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TelecomFailure: i18n._(
      'Documentation showing that there was a telecom failure at the time of purchase.'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    Telex: i18n._('Proof that the issuer authorized the transaction amount or higher by telex.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TIDorInvoice: i18n._('A copy of the Transaction Identification Document (TID) or invoice.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TransactionNotPOS: i18n._('Proof that the transaction was not a POS transaction.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TwoDifferentTIDs: i18n._(
      'Copies of two different Transaction Identification Documents (TIDs).'
    ),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TwoPINTransactions: i18n._('Copies of two different PIN transactions.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    TwoPriorFraudChargebacks: i18n._('Copies of two previous fraudulent chargeback transactions.'),
    // biome-ignore lint/style/useNamingConvention: Specific requirement for key naming
    UnreasonableAmount: i18n._(
      'Documentation showing that the transaction amount did not exceed the reasonable amount range as agreed with the cardholder.'
    ),
  };
  return defenseDocumentTypeCodeMap[defenseDocumentTypeCode];
};
