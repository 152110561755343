import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {DateTime} from 'luxon';
import DatepickerTailwind, {DateValueType} from 'react-tailwindcss-datepicker';

export const getDashboardDatepickerDefaults = () => ({
  startDate: DateTime.now().startOf('month').toJSDate(),
  endDate: DateTime.now().endOf('month').toJSDate(),
});

export const DASHBOARD_DATEPICKER_CONFIGS = (i18n: I18n) => ({
  shortcuts: {
    last7Days: {
      text: i18n._('Last 7 days'),
      period: {
        start: DateTime.now().minus({days: 7}).toJSDate(),
        end: new Date(),
      },
    },
    last30Days: {
      text: i18n._('Last 30 days'),
      period: {
        start: DateTime.now().minus({days: 30}).toJSDate(),
        end: new Date(),
      },
    },
    thisMonth: {
      text: i18n._('This month'),
      period: {
        start: DateTime.now().startOf('month').toJSDate(),
        end: DateTime.now().endOf('month').toJSDate(),
      },
    },
    lastMonth: {
      text: i18n._('Last month'),
      period: {
        start: DateTime.now().minus({months: 1}).startOf('month').toJSDate(),
        end: DateTime.now().minus({months: 1}).endOf('month').toJSDate(),
      },
    },
    thisYear: {
      text: i18n._('This year'),
      period: {
        start: DateTime.now().startOf('year').toJSDate(),
        end: DateTime.now().endOf('year').toJSDate(),
      },
    },
    lastYear: {
      text: i18n._('Last year'),
      period: {
        start: DateTime.now().minus({years: 1}).startOf('year').toJSDate(),
        end: DateTime.now().minus({years: 1}).endOf('year').toJSDate(),
      },
    },
  },
});

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

type Props = {
  dateRange: DateRange | null;
  onChange: (value: DateRange) => void;
};
export const DashboardDatepicker = ({onChange, dateRange}: Props) => {
  const {i18n} = useLingui();

  const valueChange = (value: DateValueType) => {
    if (!value?.startDate || !value.endDate) return;

    onChange({
      startDate:
        typeof value.startDate === 'string'
          ? DateTime.fromFormat(value.startDate, 'y-MM-dd').toJSDate()
          : value.startDate,
      endDate:
        typeof value.endDate === 'string'
          ? DateTime.fromFormat(value.endDate, 'y-MM-dd').endOf('day').toJSDate()
          : value.endDate,
    });
  };

  return (
    <DatepickerTailwind
      value={dateRange}
      showFooter
      primaryColor="primary"
      displayFormat="MMM DD, YYYY"
      popoverDirection="down"
      separator="-"
      placeholder={i18n._('Select dates')}
      showShortcuts
      configs={DASHBOARD_DATEPICKER_CONFIGS(i18n)}
      readOnly
      onChange={valueChange}
      toggleClassName={'hidden'}
      inputClassName={
        'relative transition-all duration-300 py-2 pl-4 pr-0 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring-2 disabled:opacity-40 disabled:cursor-not-allowed focus:border-primary-500 focus:ring-primary-500'
      }
    />
  );
};
