import {useAuth0} from '@auth0/auth0-react';
import {useLingui} from '@lingui/react';
import {useCallback} from 'react';

export const Error419 = () => {
  const {i18n} = useLingui();
  const {loginWithRedirect, isAuthenticated, logout} = useAuth0();

  const handleLogin = useCallback(() => {
    if (isAuthenticated) {
      logout({logoutParams: {returnTo: `${window.location.origin}/logout`}});
    }
    loginWithRedirect({appState: {returnTo: '/'}});
  }, [isAuthenticated, logout, loginWithRedirect]);

  return (
    <section className="bg-primary-400 dark:bg-primary-900">
      <div className="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-extrabold text-center text-white md:text-6xl">
            {i18n._('Your session has expired')}
          </h1>
          <p className="mt-10 text-xl text-center text-white">
            {i18n._('Please log in again to continue using the app.')}
          </p>
          <div className="mt-16 md:gap-x-3">
            <button
              type="button"
              onClick={handleLogin}
              className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-800 dark:hover:bg-primary-700 shrink-0 sm:w-auto"
            >
              {i18n._('Re-Login')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
