import {DateTime} from 'luxon';
// Example
// params {date: new Date(), str: 'str', strNull: null}
// return 'date=1982-05-25&str=str'
export const formatUrlQueryParams = (
  params: Record<string, string | number | Date | null | undefined | string[]>
) => {
  return Object.entries(params)
    .filter((entry): entry is [string, string | number | Date | string[]] => !!entry[1])
    .map(([key, rawValue]) => {
      return `${key}=${formatValue(rawValue)}`;
    })
    .join('&');
};

const formatValue = (value: string | number | Date | string[]) => {
  if (value instanceof Date) {
    return DateTime.fromJSDate(value).toISODate();
  }
  if (Array.isArray(value)) {
    return encodeURIComponent(value.join(','));
  }
  return encodeURIComponent(value);
};
