import {MessageDescriptor} from '@lingui/core';
import {CircleWithLabel, CircleWithLabelProps} from '../../layout/circle-with-label';
import {cn} from '../../utils';

export type StepperVerticalItem = {
  id: string;
  title: MessageDescriptor | string;
  description?: string;
};

export type StepperVerticalProps = {
  steps: StepperVerticalItem[];
  selectedIndex: number;
  disableCompleted?: boolean;
  onChange?: (id: string) => void;
  className?: string;
};

const getStepType = (stepIndex: number, selectedIndex: number): CircleWithLabelProps['type'] => {
  switch (true) {
    case stepIndex < selectedIndex:
      return 'complete';
    case stepIndex === selectedIndex:
      return 'current';
    case stepIndex > selectedIndex:
      return 'upcoming';
    default:
      throw new Error('absurd');
  }
};

export const StepperVertical = ({
  steps,
  selectedIndex,
  onChange,
  disableCompleted,
  className,
}: StepperVerticalProps) => {
  return (
    <div className={className} aria-label="Progress">
      <ol className="overflow-hidden">
        {steps.map((step, stepIndex) => {
          const type = getStepType(stepIndex, selectedIndex);
          const isNotLast = stepIndex !== steps.length - 1;
          const isDisabled = type === 'current' || (disableCompleted && type === 'complete');
          return (
            <div key={step.id} className={cn('relative', isNotLast && 'pb-10')}>
              {isNotLast && (
                <div
                  className={cn(
                    'absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5',
                    type === 'complete' ? 'bg-primary-600' : 'bg-gray-300'
                  )}
                  aria-hidden="true"
                />
              )}
              <CircleWithLabel
                id={step.id}
                onClick={onChange}
                disabled={isDisabled}
                type={type}
                title={step.title.toString()}
                description={step.description}
              />
            </div>
          );
        })}
      </ol>
    </div>
  );
};
