import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {
  ShopperListItemOutput,
  ShopperListOutput,
} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../../table';
import {getPaymentMethodsColumns} from './columns';

type Filters = {
  name?: string;
  merchantShopperId?: string;
  email?: string;
};

type Props<TFilters extends Filters> = {
  paymentMethodsList?: ShopperListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort?: TableSortValue<string> | null;
  setSort?: Dispatch<SetStateAction<TableSortValue<string>>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  openDetails: (row: ShopperListItemOutput) => void;
  navigateToTransactions: (row: ShopperListItemOutput) => void;
  isLoading?: boolean;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  refetch: () => void;
};

export const SavedPaymentMethodsList = <TFilters extends Filters>({
  paymentMethodsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  openDetails,
  navigateToTransactions,
  isLoading,
  trpc,
  refetch,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  if (!paymentMethodsList) {
    return;
  }
  return (
    <TableWrapper
      data={paymentMethodsList?.rows ?? []}
      pageCount={paymentMethodsList.pagination.pageCount ?? 0}
      columns={getPaymentMethodsColumns({
        i18n,
        openDetails,
        trpc,
        refetch,
        navigateToTransactions,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            {i18n._('No Saved Payment Methods')}
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetails}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
