import {Disclosure} from '@headlessui/react';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {WrenchIcon} from '@heroicons/react/24/outline';
import {i18n} from '@lingui/core';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {cn} from '@zentact/ui-tailwind/utils';
import {useCallback, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'usehooks-ts';
import {HeroIcon} from '../..';

export type NavigationItem = {
  name: string;
  href: string;
  parentPageExist?: boolean;
  icon?: HeroIcon;
  children?: NavigationItem[];
  limited?: boolean;
  newTab?: boolean;
};

type SidebarNavigationProps = {
  items: NavigationItem[];
  onNavigate: () => void;
};

const LimitedIcon = () => (
  <span className="p-1 rounded-sm bg-primary-200">
    <WrenchIcon className="h-3.5 w-3.5 shrink-0" />
  </span>
);

export const SidebarNavigation = ({items, onNavigate}: SidebarNavigationProps) => {
  const [lastDropdownClose, setLastDropdownClose] = useState<VoidFunction>();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const isSidebarDialogMode = useMediaQuery(`(max-width: ${tailwindDefaultBreakpoints.xl})`);

  // Close prev menu item on toggle current
  const handleLinkClick = useCallback(
    (close?: VoidFunction, item?: NavigationItem) => {
      if (
        // if dropdown is opened
        lastDropdownClose &&
        // clicking not on the same dropdown
        !(close === lastDropdownClose)
      ) {
        lastDropdownClose();
      }
      setLastDropdownClose(() => close);
      if (!close || !item) {
        return;
      }

      if (item.parentPageExist) {
        // navigate to common parent page if it exists
        navigate(item.href);
        return;
      }
      if (!isSidebarDialogMode) {
        // navigate to first child on category click only on desktop screens
        selectNearestChild(item);
      }
    },
    [lastDropdownClose, setLastDropdownClose, isSidebarDialogMode, pathname]
  );

  function selectNearestChild(item: NavigationItem) {
    const firstSelectedSubItem = item.children?.filter(childItem => !childItem?.limited)?.[0]?.href;
    if (firstSelectedSubItem) {
      navigate(firstSelectedSubItem);
    }
  }

  return (
    <nav className="flex flex-col flex-1 mt-6">
      <ul className="flex flex-col flex-1 gap-y-7">
        <li>
          <ul className="-mx-2 space-y-1">
            {items.map(item => (
              <li key={i18n._(item.name)}>
                {!item.children ? (
                  <Link
                    onClick={() => {
                      handleLinkClick();
                      onNavigate();
                    }}
                    to={item.href}
                    target={item.newTab ? '_blank' : undefined}
                    className={cn(
                      item.limited && 'pointer-events-none',
                      pathname?.includes(item.href)
                        ? 'bg-primary-600 text-white'
                        : 'text-primary-700 hover:bg-primary-200',
                      'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6'
                    )}
                  >
                    {item.icon && (
                      <item.icon
                        className={cn(
                          pathname?.includes(item.href) ? 'text-white' : 'text-primary-700',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    )}
                    {i18n._(item.name)} {item.limited && <LimitedIcon />}
                  </Link>
                ) : (
                  <Disclosure
                    as="div"
                    defaultOpen={item.children?.some(subitem => pathname?.includes(subitem.href))}
                  >
                    {({open, close}) => (
                      <>
                        <Disclosure.Button
                          onClick={() => handleLinkClick(close, item)}
                          className={cn(
                            item.limited && 'pointer-events-none',
                            pathname?.includes(item.href)
                              ? 'bg-primary-600 text-white'
                              : 'text-primary-700 hover:bg-primary-200',
                            'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm leading-6'
                          )}
                        >
                          {item.icon && (
                            <item.icon
                              className={cn(
                                pathname?.includes(item.href) ? 'text-white' : 'text-primary-700',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {i18n._(item.name)} {item.limited && <LimitedIcon />}
                          <ChevronRightIcon
                            className={cn(
                              open ? 'rotate-90' : '',
                              pathname?.includes(item.href) ? 'text-white' : 'text-primary-700',
                              'ml-auto h-5 w-5 shrink-0 transition-all'
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel as="ul" className="pl-2 mt-1">
                          {item.children?.map(subItem => (
                            <li key={i18n._(subItem.name)}>
                              <Link
                                to={subItem.href}
                                onClick={onNavigate}
                                target={subItem.newTab ? '_blank' : undefined}
                                className={cn(
                                  subItem.limited && 'pointer-events-none',
                                  pathname?.includes(subItem.href)
                                    ? 'bg-primary-200'
                                    : 'hover:bg-primary-200',
                                  'text-primary-600 group ml-3 mt-0.5 flex items-center gap-x-3 rounded-md p-1 pl-6 pr-3 text-sm leading-6'
                                )}
                              >
                                {i18n._(subItem.name)} {subItem.limited && <LimitedIcon />}
                              </Link>
                            </li>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};
