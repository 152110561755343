import {ReactNode} from 'react';
import {cn} from '../../utils';

export type LabelProps = {
  children: ReactNode;
  text?: ReactNode | string;
  className?: string;
  as?: 'label' | 'div';
};

export const Label = ({children, text, className, ...restProps}: LabelProps) => {
  // biome-ignore lint/style/useNamingConvention: Component name convention
  const Element: keyof JSX.IntrinsicElements = restProps.as || 'label';
  return (
    <Element className={cn(`flex flex-col gap-2 ${className}`)}>
      {text && <div className="text-sm font-medium leading-6 text-gray-900">{text}</div>}
      {children}
    </Element>
  );
};
