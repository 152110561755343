export type CellCollapsedDataProps = {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  hideSecondary?: boolean;
  tertiary?: React.ReactNode;
  hideTertiary?: boolean;
};

export const CellCollapsedData = ({
  primary,
  secondary,
  hideSecondary,
  tertiary,
  hideTertiary,
}: CellCollapsedDataProps) => {
  return (
    <div className="flex flex-col gap-y-3">
      <div>{primary}</div>
      {!hideSecondary && !!secondary && <div>{secondary}</div>}
      {!hideTertiary && !!tertiary && <div>{tertiary}</div>}
    </div>
  );
};
