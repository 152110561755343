import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const emailSettingsFormSchema = () =>
  z.object({
    supportEmail: z
      .string()
      .min(1, t`Please enter a support email address`)
      .email(t`Please enter a valid email address`)
      .max(50, t`Email address must not exceed 50 characters`),
  });

export type EmailSettingsFormData = z.infer<ReturnType<typeof emailSettingsFormSchema>>;

type Props = {
  defaultValues: {
    supportEmail: string;
  };
  onSave: (formData: EmailSettingsFormData) => Promise<void>;
};

export const EmailSettingsForm = ({defaultValues, onSave}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<EmailSettingsFormData>({
    resolver: zodResolver(emailSettingsFormSchema()),
    defaultValues: {
      supportEmail: defaultValues.supportEmail,
    },
  });

  useEffect(() => {
    reset({
      supportEmail: defaultValues.supportEmail,
    });
  }, [defaultValues.supportEmail]);

  const submitForm = async (values: EmailSettingsFormData) => {
    setLoading(true);
    await onSave(values);
    setLoading(false);
  };

  return (
    <form className="flex flex-col gap-5">
      <div className="flex flex-col w-full gap-6 md:w-1/2">
        <div className="sm:col-span-3">
          <div className="sm:col-span-3">
            <Label text={t`Support Email`}>
              <InputText
                type="email"
                {...register('supportEmail', {
                  required: true,
                })}
                hasError={Boolean(errors.supportEmail)}
                placeholder={t`example@mail.com`}
              />
              <ValidationError isVisible={Boolean(errors.supportEmail)}>
                {errors.supportEmail?.message}
              </ValidationError>
              <p className="text-xs leading-5 text-gray-500">
                <Trans>
                  This email will be displayed to users as a place to get customer support on the
                  checkout page and email receipt.
                </Trans>
              </p>
            </Label>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-6">
        <Button
          type="button"
          className="items-center gap-1 w-fit"
          size="xl"
          onClick={handleSubmit(submitForm)}
          isLoading={loading}
          disabled={loading}
        >
          <Trans>Save email settings</Trans>
        </Button>
      </div>
    </form>
  );
};
