import {ValidationError} from '@zentact/ui-tailwind';
import {Controller, useFormContext} from 'react-hook-form';
import {MerchantApplicationForm} from './merchant-application';

const SALES_CHANNEL_OPTIONS = {
  pos: {
    label: 'POS (Point of Sale)',
    description:
      'A system used by merchants to process in-person transactions, typically involving a physical terminal or device.',
  },
  eCommerce: {
    label: 'eCommerce',
    description:
      'Refers to online transactions where the customer completes the payment through a website or app.',
  },
  posMoto: {
    label: 'POSMoto (Mail Order/Telephone Order)',
    description:
      'Refers to transactions where the payment details are provided by the customer via mail or phone, and the merchant enters them manually, often using a virtual terminal.',
  },
  ecomMoto: {
    label: 'ecomMoto',
    description:
      'A combination of eCommerce and MOTO, likely referring to transactions where the payment is initiated online but processed like a mail/telephone order.',
  },
  payByLink: {
    label: 'Pay by Link',
    description:
      'A payment method where the merchant sends a link to the customer via email, SMS, or messaging platforms. The customer clicks the link to complete the payment on a secure page.',
  },
};

export const SalesChannels = ({
  options,
  defaultValues,
}: {
  options: string[];
  defaultValues: string[];
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext<MerchantApplicationForm>();

  return (
    <fieldset>
      <legend className="sr-only">Sales Channels</legend>
      <div className="space-y-5">
        {options.map(optionKey => {
          const option = SALES_CHANNEL_OPTIONS[optionKey as keyof typeof SALES_CHANNEL_OPTIONS];
          return (
            <div key={optionKey} className="relative flex items-start">
              <div className="flex h-6 items-center">
                <Controller
                  name="salesChannels"
                  control={control}
                  defaultValue={defaultValues as (keyof typeof SALES_CHANNEL_OPTIONS)[]}
                  render={({field}) => {
                    const {value = [], onChange} = field;
                    const isChecked = value.includes(
                      optionKey as keyof typeof SALES_CHANNEL_OPTIONS
                    );

                    return (
                      <input
                        type="checkbox"
                        id={optionKey}
                        checked={isChecked}
                        onChange={e => {
                          if (e.target.checked) {
                            onChange([...value, optionKey]);
                          } else {
                            onChange(value.filter(v => v !== optionKey));
                          }
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={optionKey} className="font-medium text-gray-900">
                  {option.label}
                </label>
                <p className="text-gray-500">{option.description}</p>
              </div>
            </div>
          );
        })}
        <ValidationError isVisible={Boolean(errors.salesChannels)}>
          {errors.salesChannels?.message}
        </ValidationError>
      </div>
    </fieldset>
  );
};
