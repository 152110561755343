import {customAlphabet} from 'nanoid';

const alphaNumericCharsAlphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

/**
 * Generates a customizable, alphanumeric Nano ID.
 *
 * @returns {() => string} A function that, when called, generates a new alphanumeric Nano ID using a custom alphabet.
 *
 * @example
 * const nanoId = alphaNumericNanoId();
 * console.log(nanoId()); // Outputs a unique alphanumeric Nano ID, e.g., '4f90d13a42'
 */
export const alphaNumericNanoId = customAlphabet(alphaNumericCharsAlphabet);
