import {useLingui} from '@lingui/react';
import {PayoutsSummaryItem} from '@zentact/api/src/trpc/routers/financeRouter';
import {CurrencyCode, LocaleCode, formatAmount, formatLocaleDate} from '@zentact/common';
import {Link} from 'react-router-dom';
import {SlideOverWithBrandedHeader} from '../../../index';

type PayoutDetailsPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  payoutRow: PayoutsSummaryItem | null;
  activityDetailsPath: string;
};

export const PayoutDetailsPanel = ({
  isOpen,
  onCancel,
  payoutRow,
  activityDetailsPath,
}: PayoutDetailsPanelProps) => {
  const {i18n} = useLingui();

  if (!payoutRow) {
    return null;
  }
  const {
    valueDate,
    amount,
    currency,
    reference,
    bankAccountLast4,
    bankAccountType,
    estimatedArrivalTime,
    batchId,
  } = payoutRow;

  const merchantAccountName =
    'merchantAccountName' in payoutRow ? payoutRow.merchantAccountName : undefined;
  const organizationName = 'organizationName' in payoutRow ? payoutRow.organizationName : undefined;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Payout')}
      closeHandler={onCancel}
    >
      <div className="border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Transfer Date')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatLocaleDate(valueDate)}
            </dd>
          </div>
          {!!merchantAccountName && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Merchant Account')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {merchantAccountName}
              </dd>
            </div>
          )}
          {!!organizationName && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Organization')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {organizationName}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Deposit Amount')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(amount, i18n.locale as LocaleCode, currency as CurrencyCode)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Reference')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {reference}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Batch ID')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {batchId ? (
                <Link
                  className="text-sm text-primary-600"
                  to={`${activityDetailsPath}?batchId=${encodeURIComponent(batchId)}`}
                >
                  {batchId}
                </Link>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Bank Account')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {`******${bankAccountLast4}`}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Account Type')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {bankAccountType}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Estimated Arrival Date')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {estimatedArrivalTime ? formatLocaleDate(estimatedArrivalTime) : 'N/A'}
            </dd>
          </div>
        </dl>
      </div>
    </SlideOverWithBrandedHeader>
  );
};
