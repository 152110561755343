import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {ShopperSavedPaymentMethodStatus} from '@zentact/db';
import {FlatFillColors, FlatPillWithDot} from '../../../../elements';
import {TruncatedText} from '../../../../other';
import {getTableMeta} from '../../../../table';
import {HighlightedText} from '../../../highlighted-text';
import {renderPaymentMethod} from '../../../payments/payment-list/columns';
import {SavedPaymentMethodsActions} from './saved-payment-methods-actions';

const columnsHelper = createColumnHelper<ShopperListItemOutput>();

type Props = {
  i18n: I18n;
  openDetails: (row: ShopperListItemOutput) => void;
  navigateToTransactions: (row: ShopperListItemOutput) => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  refetch: () => void;
};

export const savedPaymentMethodStatusToColor: {
  [_ in ShopperSavedPaymentMethodStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ACTIVE: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  EXPIRED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  DISABLED: 'red',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ARCHIVED: 'gray',
};
export const displaySavedPaymentMethodStatusMap: {
  [_ in ShopperSavedPaymentMethodStatus]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ACTIVE: 'Active',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  EXPIRED: 'Expited',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  DISABLED: 'Disabled',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ARCHIVED: 'Archived',
};
export const getPaymentMethodsColumns = ({
  i18n,
  openDetails,
  navigateToTransactions,
  trpc,
  refetch,
}: Props) => {
  return [
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error
    columnsHelper.accessor('updatedAt', {
      cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
      header: () => i18n._('Last Updated'),
      meta: {
        sort: {
          isSortable: true,
        },
        collapseAt: 'lg',
      },

      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('name', {
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.name as string;
        const textContent = props.row.original.name || '';

        return <HighlightedText text={textContent || i18n._('N/A')} highlight={searchString} />;
      },
      header: () => i18n._('Name'),
    }),
    columnsHelper.accessor('merchantShopperId', {
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.merchantShopperId as string;
        const textContent = props.row.original.merchantShopperId || '';

        if (searchString) {
          return <HighlightedText text={textContent} highlight={searchString} />;
        }
        return <TruncatedText text={textContent} />;
      },
      header: () => i18n._('Customer ID'),
      meta: {
        collapseAt: 'xl',
      },
    }),
    columnsHelper.accessor('email', {
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.email as string;
        const textContent = props.row.original.email || '';

        if (searchString) {
          return <HighlightedText text={textContent} highlight={searchString} />;
        }
        return <TruncatedText text={textContent} />;
      },
      header: () => i18n._('Email'),
      meta: {
        collapseAt: '2xl',
      },
    }),
    columnsHelper.display({
      id: 'merchantName',
      cell: props => (
        <TruncatedText text={props.row.original.merchantAccount?.businessName || i18n._('N/A')} />
      ),
      header: () => i18n._('Merchant Account'),
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.display({
      cell: props =>
        props.row.original.primaryPaymentMethod ? (
          <div className="flex items-center space-x-2">
            {renderPaymentMethod(
              props.row.original.primaryPaymentMethod.paymentMethod,
              props.row.original.primaryPaymentMethod.cardLastFour
            )}
            <FlatPillWithDot
              color={
                savedPaymentMethodStatusToColor[props.row.original.primaryPaymentMethod.status] ||
                'blue'
              }
              label={
                displaySavedPaymentMethodStatusMap[
                  props.row.original.primaryPaymentMethod.status
                ] ?? props.row.original.primaryPaymentMethod.status
              }
            />
          </div>
        ) : props.row.original.pendingPaymentMethod ? (
          <div className="flex items-center space-x-2">
            <FlatPillWithDot color={'gray'} label={i18n._('Pending')} />
          </div>
        ) : (
          i18n._('N/A')
        ),
      id: 'paymentMethod',
      header: () => i18n._('Primary Payment Method'),
    }),
    columnsHelper.accessor('lastCharged', {
      cell: props => {
        const value = props.getValue();
        return value ? formatLocaleDate(value, 'shortWithTime') : i18n._('N/A');
      },
      header: () => i18n._('Last Charged'),
      size: TableColumnSize.S,
      meta: {
        collapseAt: '2xl',
      },
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <SavedPaymentMethodsActions
            row={props.row.original}
            openDetails={openDetails}
            navigateToTransactions={navigateToTransactions}
            trpc={trpc}
            refetch={refetch}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
