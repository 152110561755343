import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0UserRole} from '@zentact/common';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {setAccessToken as setTRPCAccessToken, setCheckoutToken} from '../api/trpcClient';
import {AsyncState} from '../types/AsyncState';
import {useUrlToken} from './useUrlToken';

export const useAccessToken = () => {
  const {isAuthenticated, getAccessTokenSilently, user, isLoading, getIdTokenClaims} = useAuth0();
  const {setAccessToken: setStoreAccessToken, setAuthProfile} = useStore();
  const {token: checkoutToken} = useUrlToken({
    name: 's',
    type: 'hash',
  });
  const location = useLocation();
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<AsyncState<string | undefined>>({
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    if (!user || isLoading) {
      setAuthProfile({isLoading, data: undefined});
      return;
    }

    const role = user.user_roles?.[0];
    setAuthProfile({
      isLoading: false,
      data: {
        email: user.email,
        name: user.name,
        nickname: user.nickname,
        picture: user.picture,
        sub: user.sub,
        role,
        isOrganizationAdmin: role === Auth0UserRole.ORGANIZATION_ADMIN,
        isMerchantAdmin: role === Auth0UserRole.MERCHANT_ADMIN,
      },
    });
  }, [user, isLoading]);

  // Temp fix to log out users when we change the auth0 domain
  useEffect(() => {
    if (!user) return;

    getIdTokenClaims().then(claims => {
      if (!claims?.iss || claims.iss === `https://${import.meta.env.VITE_AUTH0_DOMAIN}/`) return;
      setTimeout(() => {
        navigate('/logout');
      }, 1000);
    });
  }, [user]);

  useEffect(() => {
    // During the checkout session, the TRPC context is set by the checkout id
    // and not from optionally logged in user. Therefore, we have to exclude access tokens.
    // There might be a left over checkout token from a previous session, therefore we need to include it for checkout path.
    if (checkoutToken && location.pathname.includes('checkout')) {
      setCheckoutToken(checkoutToken);
      setAccessToken({isLoading: false, isError: false, data: undefined});
      return;
    }

    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(token => {
          setStoreAccessToken?.(token);
          setTRPCAccessToken(token);
          setAccessToken({isLoading: false, isError: false, data: token});
        })
        .catch(error => {
          setAccessToken({
            isLoading: false,
            isError: true,
            error: {code: error.code, message: error.message},
          });
        });
    } else {
      setAccessToken({isLoading: false, isError: false, data: undefined});
    }
    // location.pathname is intentionally not included in the dependency array because we don't want
    // to update the token every time the page changes.
  }, [isAuthenticated, getAccessTokenSilently, checkoutToken]);

  return {accessToken};
};
