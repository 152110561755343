import {cn} from '../../utils';

export const SkeletonLoader = ({
  className,
  isLoading,
  children,
}: {
  className?: string;
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  return isLoading ? (
    <div className={cn('min-h-full w-full animate-pulse rounded-md bg-gray-200', className)} />
  ) : (
    children
  );
};
