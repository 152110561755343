import {useStore} from '@/store';
import {applyTenantFavicon, applyTheme} from '@zentact/ui-tailwind/theme';
import {ReactNode, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

const BrandedRoute = ({children}: {children?: ReactNode}) => {
  const location = useLocation();
  const {tenant} = useStore();

  useEffect(() => {
    if (!tenant) {
      return;
    }
    applyTheme({
      primary: tenant.brandConfiguration.primaryColorHex,
      fontFamily: tenant.brandConfiguration.fontFamily,
    });
    applyTenantFavicon(tenant.brandConfiguration.smallLogoUrl);
  }, [location, tenant]);

  return children ? children : <Outlet />;
};

export default BrandedRoute;
