import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {ProtectedLayout} from '@zentact/ui-tailwind';
import LogRocket from 'logrocket';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePortalNavigationItems, userNavigation} from './navigation';

export function PortalLayout() {
  const {user} = useAuth0(); // use isLoading also
  const {organization, tenant, authProfile} = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (organization?.activeRegistrationId) {
      navigate(`/registration/${organization.activeRegistrationId}`);
      return;
    }
  }, [organization]);

  if (user?.sub) {
    LogRocket.identify(user.sub, {
      ...(user.name && {name: user.name}),
      ...(user.email && {email: user.email}),
    });
  }

  const navigationItems = usePortalNavigationItems(
    tenant?.features?.disablePortalPayments,
    authProfile.data?.isOrganizationAdmin,
    organization?.organizationConfiguration?.savePaymentMethod
  );

  return (
    <ProtectedLayout
      brandName={tenant?.brandConfiguration.displayName}
      brandLogoUrl={tenant?.brandConfiguration.logoUrl}
      organizationName={organization?.name}
      organizationLogoUrl={organization?.brandConfiguration.logoUrl}
      profileSubtitle={organization?.name}
      navItems={navigationItems}
      profileDropDownItems={userNavigation()}
      user={user}
    />
  );
}
