import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {Fragment, useEffect, useState} from 'react';
import {Button} from '../forms';
import {cn} from '../utils';

type Props = {
  isOpen: boolean;
  title: string;
  text?: string;
  children: JSX.Element;
  applyButtonText?: string;
  closeHandler: () => unknown;
  footer?: React.ReactNode;
  scrollable?: boolean;
  panelClassName?: string;
};

export const SlideOverWithBrandedHeader = ({
  isOpen,
  title,
  text,
  children,
  closeHandler,
  footer,
  scrollable = false,
  panelClassName,
}: Props) => {
  const [isHiding, setIsHiding] = useState(false);
  const {i18n} = useLingui();

  useEffect(() => {
    if (isOpen) {
      setIsHiding(false);
    }
  }, [isOpen]);

  return (
    <Transition.Root
      show={isOpen && !isHiding}
      as={Fragment}
      appear
      afterLeave={() => closeHandler()}
    >
      <Dialog as="div" className="relative z-40" onClose={() => setIsHiding(true)}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={panelClassName ?? 'w-screen pointer-events-auto md:max-w-md'}
                >
                  <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                    <section className="flex flex-col flex-1 min-h-0 overflow-y-auto">
                      <div className="px-4 py-6 bg-primary-600 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {title}
                          </Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-white hover:text-white"
                              onClick={() => setIsHiding(true)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        {text && (
                          <div className="mt-1">
                            <p className="text-sm text-primary-300">{text}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className={cn(
                          'relative flex-1 px-4 py-6 sm:px-6',
                          scrollable && 'overflow-y-scroll'
                        )}
                      >
                        {children}
                      </div>
                    </section>
                    {footer || (
                      <footer className="flex justify-end p-4 mx-3 shrink-0">
                        <Button
                          variant="secondary"
                          size="lg"
                          className="w-fit"
                          onClick={() => setIsHiding(true)}
                        >
                          {i18n._('Close')}
                        </Button>
                      </footer>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
