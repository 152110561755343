import {SwatchIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {InputHTMLAttributes, forwardRef, useEffect, useRef, useState} from 'react';
import {Button, ValidationError} from '../..';

export const InputColor = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange?: (color: string) => void;
  }
>(({onChange, ...props}, ref) => {
  const [inputValue, setInputValue] = useState<string | number | readonly string[] | undefined>(
    '#'
  );
  const [isValidHex, setIsValidHex] = useState<boolean>();
  const colorPickerRef = useRef<HTMLInputElement>(null);
  const {i18n} = useLingui();

  const handleIconClick = () => {
    colorPickerRef.current?.click();
  };

  useEffect(() => {
    setInputValue(props.value || '#');
    setIsValidHex(props.value ? true : false);
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (value.length === 0 || value.charAt(0) !== '#') {
      value = `#${value}`;
    }

    const newValue = [...value].reduce((acc, char) => {
      if (char === '#' || /^[0-9a-fA-F]$/i.test(char)) {
        return acc + char;
      }
      return acc;
    }, '');

    if (newValue === '#' || (newValue.length <= 7 && /^#[0-9a-fA-F]*$/i.test(newValue))) {
      setInputValue(newValue);
      setIsValidHex(false);
      if (onChange && newValue.length === 7) {
        onChange(newValue);
        setIsValidHex(true);
      }
    }
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <input
          {...props}
          type="text"
          value={inputValue}
          onChange={handleChange}
          maxLength={7}
          className="w-full p-2 text-gray-900 bg-white border-0 rounded-md shadow-sm focus:ring-primary-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          ref={ref}
          placeholder="#000000"
        />
        <Button
          size="xl"
          variant="secondary"
          className="flex gap-2 ml-2 w-fit"
          onClick={handleIconClick}
        >
          <SwatchIcon onClick={handleIconClick} className="w-5 h-5" />
          <span className=" whitespace-nowrap">{i18n._('Open Color Picker')}</span>
        </Button>
        <input
          value={inputValue}
          type="color"
          ref={colorPickerRef}
          onChange={handleChange}
          className="invisible w-1 h-1 p-0 m-0"
        />
      </div>
      <ValidationError isVisible={!isValidHex}>
        {i18n._('Enter a full 6-digit HEX color code.')}
      </ValidationError>
    </>
  );
});
