import {InputHTMLAttributes, ReactNode, forwardRef} from 'react';
import {cn} from '../../utils';

export type InputCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  children: ReactNode;
  wrapperClassName?: string;
  view?: 'default' | 'list';
};

export const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxProps>(
  ({children, view = 'default', wrapperClassName, ...props}, ref) => {
    return (
      <div className="space-y-5">
        <div
          className={cn(
            'flex relative',
            view === 'list' && 'flex-row-reverse justify-between',
            view === 'default' && 'items-center',
            wrapperClassName
          )}
        >
          <div className="flex items-center h-6">
            <input
              {...props}
              id={props.name}
              ref={ref}
              type="checkbox"
              className={cn(
                'text-primary-600 focus:ring-primary-600 h-4 w-4 cursor-pointer rounded border-gray-300',
                props.className
              )}
            />
          </div>
          <div className={cn('text-sm leading-6', view === 'default' && 'ml-3')}>
            <label htmlFor={props.name} className="font-medium text-gray-900 cursor-pointer">
              {children}
            </label>
          </div>
        </div>
      </div>
    );
  }
);
