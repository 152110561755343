const specialSymbolsRegexp = /[/\-\\^$*+?.()|[\]{}]/g;

export const HighlightedText = ({
  text = '',
  highlight = '',
  className,
}: {
  text?: string;
  highlight?: string;
  className?: string;
}) =>
  highlight?.trim() ? (
    <>
      {text
        .split(new RegExp(`(${highlight.replace(specialSymbolsRegexp, '\\$&')})`, 'gi'))
        .map((part, i) => (
          <mark
            key={`text-part-${i}`}
            className={`bg-transparent ${
              part.toLowerCase() === highlight.toLowerCase() ? 'bg-yellow-400' : ''
            } ${className || ''}`}
          >
            {part}
          </mark>
        ))}
    </>
  ) : (
    text
  );
