import {ReactNode} from 'react';
import {cn} from '../../utils';

type TypographyVariant =
  | 'paragraph'
  | 'header-base'
  | 'header-lg'
  | 'header-page'
  | 'header-section'
  | 'header-subsection'
  | 'header-form-section'
  | 'field-helper';

const typographyStyles: Record<TypographyVariant, string> = {
  paragraph: cn('text-sm font-normal text-gray-500'),
  'header-lg': cn('text-lg font-semibold leading-6 text-gray-900'),
  'header-base': cn('text-base font-semibold leading-6 text-gray-900'),
  'header-page': cn(
    'text-2xl text-left font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'
  ),
  'header-section': cn('flex items-center text-2xl font-semibold'),
  'header-subsection': cn(
    'text-2xl leading-5 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight'
  ),
  'header-form-section': cn('text-primary text-base font-semibold leading-7'),
  'field-helper': cn('text-xs font-normal text-gray-400'),
};

export type TypographyProps = {
  children: ReactNode;
  as?: keyof JSX.IntrinsicElements;
  variant: TypographyVariant;
  className?: string;
};

export const Typography = ({children, as, variant, className}: TypographyProps) => {
  // biome-ignore lint/style/useNamingConvention: Component name convention
  const Element: keyof JSX.IntrinsicElements = as || 'p';
  return <Element className={cn(typographyStyles[variant], className)}>{children}</Element>;
};
