import {customAlphabet} from 'nanoid';

const distinguishableCharacterSet = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789';

/**
 * Generates a customizable Nano ID using a distinguishable character set.
 * The character set avoids characters like 'l', 'I', 'O', and '0' which are
 * often mistaken for one another.
 *
 * @returns {() => string} A function that, when called, generates a new Nano ID using the distinguishable character set.
 *
 * @example
 * const nanoId = distinguishableNanoId();
 * console.log(nanoId()); // Outputs a unique Nano ID, e.g., '4f9d13a42h'
 */
export const distinguishableNanoId = customAlphabet(distinguishableCharacterSet);
