import {Trans} from '@lingui/macro';
import {CurrencyCode, LocaleCode, formatAmount, formatAmountWithoutCurrency} from '@zentact/common';

type Props = {
  splitPercent: number;
  splitFixed?: number;
  locale: LocaleCode;
  currency: CurrencyCode;
};

export const CostPlusPricingCards = ({splitPercent, splitFixed, locale, currency}: Props) => {
  return (
    <Trans>
      <b>Credit Cards Cost-Plus Pricing</b>
      <br />
      <br />
      Each credit card transaction will incur the actual interchange fee charged by the card brands
      which can vary by card type and other transaction details including a variable processing fee
      plus an additional markup of {formatAmountWithoutCurrency(splitPercent, locale, currency)}% (
      {splitPercent} basis points) per transaction
      {splitFixed ? `, and a fixed fee of ${formatAmount(splitFixed, locale, currency)}` : ''}.
    </Trans>
  );
};
