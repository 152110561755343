import {Trans} from '@lingui/macro';
import {CurrencyCode, LocaleCode, formatAmount, formatAmountWithoutCurrency} from '@zentact/common';

type Props = {
  splitPercent: number;
  splitFixed?: number;
  locale: LocaleCode;
  currency: CurrencyCode;
};

export const FlatRatePricingCards = ({splitPercent, splitFixed, locale, currency}: Props) => {
  return (
    <Trans>
      <b>Credit Cards Flat Rate Pricing</b>
      <br />
      <br />A {formatAmountWithoutCurrency(splitPercent, locale, currency)}% processing fee will be
      applied to all credit card payments
      {splitFixed
        ? `, in addition to a fixed
              ${formatAmount(splitFixed, locale, currency)} fee per transaction`
        : ''}
      .
    </Trans>
  );
};
