import {Auth0Provider, Auth0ProviderOptions} from '@auth0/auth0-react';
import {ComponentProps} from 'react';
import {useNavigate} from 'react-router-dom';

export const Auth0ProviderWithRedirectCallback = ({children, ...props}: Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const onRedirectCallback: ComponentProps<typeof Auth0Provider>['onRedirectCallback'] =
    appState => {
      navigate(appState?.returnTo || window.location.pathname);
    };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};
