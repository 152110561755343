import {ImageUploadDimensions, MAX_UPLOAD_FILE_FIZE_MB, supportedExtensions} from '@zentact/common';
import {useState} from 'react';
import {Button, FileUpload, Typography} from '../../index';
import {cn} from '../../utils';

type UploadBrandLogoType = {
  localeText: {
    title: string;
    delete: string;
    upload: string;
    dragndrop: string;
  };
  onSelectFile: (file: File) => void;
  logoUrl?: string | null;
  logoExist: boolean;
  onDeleteLogo: () => void;
  isLoading?: boolean;
  emptyLogoClassName?: string;
  maxImageDimensions?: ImageUploadDimensions;
};

const backgroundEmpty = `url(
  "data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M0 0h10v10H0z'  strokeWidth='1' stroke='none' fill='hsla(259, 0%, 89%, 1)'/><path d='M10 10h10v10H10z'  strokeWidth='1' stroke='none' fill='hsla(259, 0%, 89%, 1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(-20,0)' fill='url(%23a)'/></svg>"
  )`;

export const UploadBrandLogo = ({
  onSelectFile,
  logoUrl,
  onDeleteLogo,
  isLoading,
  localeText,
  emptyLogoClassName,
  logoExist,
  maxImageDimensions,
}: UploadBrandLogoType) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      <div className="sm:mb-2">
        <Typography variant="header-subsection">{localeText.title}</Typography>
        <div className={cn('mt-3', emptyLogoClassName)}>
          {logoUrl && !imageError ? (
            <img
              style={{
                backgroundImage: backgroundEmpty,
              }}
              src={logoUrl}
              alt=""
              onError={() => setImageError(true)}
            />
          ) : (
            <div
              className="w-full h-full"
              style={{
                backgroundImage: backgroundEmpty,
              }}
            />
          )}
        </div>
        <div className="mt-2">
          {logoExist && (
            <Button
              variant="text-primary"
              onClick={onDeleteLogo}
              className="inline-block w-auto pl-0"
            >
              {localeText.delete}
            </Button>
          )}
        </div>
      </div>
      <FileUpload
        isLoading={isLoading}
        onSelectFile={onSelectFile}
        allowedExt={supportedExtensions}
        localeText={{
          upload: localeText.upload,
          dragndrop: localeText.dragndrop,
        }}
        type="image"
        maxSizeMb={MAX_UPLOAD_FILE_FIZE_MB}
        maxImageDimensions={maxImageDimensions}
      />
    </div>
  );
};
