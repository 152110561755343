import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import React, {useCallback} from 'react';
import {useNotification} from '../../../hooks';
import {AlertOverlayWithConfirmation} from '../../../overlays';

type RefundConfirmDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  shopper: ShopperListItemOutput;
  onSuccess: () => void;

  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const DeleteCustomerConfirmDialog = ({
  isOpen,
  onClose,
  shopper,
  trpc,
  onSuccess,
}: RefundConfirmDialogProps) => {
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const deleteShopperMutation = trpc.savedPaymentMethod.deleteShopper.useMutation({
    onError: error => {
      showErrorNotification(error.message);
    },
    onSuccess: async () => {
      onClose();
      showSuccessNotification(i18n._('Customer deleted'));
      onSuccess();
    },
  });

  const handleRefund = useCallback(async () => {
    deleteShopperMutation.mutate({
      shopperId: shopper.id,
    });
  }, [shopper]);
  return (
    <div>
      <AlertOverlayWithConfirmation
        open={isOpen}
        setOpen={onClose}
        loading={deleteShopperMutation.isLoading}
        handleAction={handleRefund}
        localeText={{
          title: i18n._('Confirm Deleting the Customer'),
          description: i18n._(
            'This will delete the customer and all their cards on file. This customer would need to recreate their account to continue doing business with you. Are you sure you want to do this?'
          ),
          confirm: i18n._('Delete Customer'),
          cancel: i18n._('Cancel'),
        }}
      />
    </div>
  );
};
