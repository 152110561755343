// auth0 deleting user is not instant
// so we need to remeber and remove deleted records from the returned user list

export const saveAuth0DeletedUserInStorage = (userId: string) => {
  const deletedAuht0UserIds = (sessionStorage.getItem('deleted_auth0_user_ids') ?? '').split(',');
  sessionStorage.setItem('deleted_auth0_user_ids', [...deletedAuht0UserIds, userId].join(','));
};

export const getAuth0DeletedUsersListFromStorage = () => {
  return (sessionStorage.getItem('deleted_auth0_user_ids') ?? '').split(',');
};
