import {DateTime} from 'luxon';

/**
 * Converts a JavaScript Date object or an ISO date string into a formatted and localized string,
 * leveraging the extensive formatting and localization capabilities of the Luxon library. This function
 * allows for a variety of date presentation styles through predefined string literals ('short', 'shortWithTime',
 * 'monthYear') and specific format types (DateTime.DATE_MED, DateTime.DATETIME_MED) from the Luxon library.
 *
 * @param {Date|string} date - The date to be formatted, which can be a JavaScript Date object or an ISO date string.
 * @param {('short' | 'shortWithTime' | 'monthYear' | typeof DateTime.DATE_MED | typeof DateTime.DATETIME_MED)} format -
 *        Specifies the desired format for the date string. Available options include:
 *          - 'short': Presents the date in a concise format, e.g., 'Jan 1'.
 *          - 'shortWithTime': Displays the date and time in a brief format, e.g., 'Jan 1, 10:00 AM'.
 *          - 'monthYear': Shows only the month and year, e.g., 'Jan 2024'.
 *          - DateTime.DATE_MED: Applies a medium date format as defined by the Luxon library.
 *          - DateTime.DATETIME_MED: Uses a medium date and time format as specified by the Luxon library.
 *
 *        If no format is specified, defaults to DateTime.DATETIME_MED.
 * @returns {string} A string representation of the date, formatted and localized according to the specified style and locale.
 *
 * @example
 * // Default formatting with DATETIME_MED
 * formatLocaleDate(new Date('2024-01-01T00:00:00Z'));
 * // Example return 'Jan 1, 2024, 12:00 AM' for an English (United States) locale.
 *
 * @example
 * // Formatting with 'short' format
 * formatLocaleDate('2024-01-01T00:00:00Z', 'short');
 * // Example return '1 de jan.' for a Portuguese (Brazil) locale.
 *
 * @example
 * // Formatting with 'monthYear' format
 * formatLocaleDate('2024-01-01T00:00:00Z', 'monthYear');
 * // Example return 'Jan 2024' for an English (United States) locale.
 */
export const formatLocaleDate = (
  date: Date | string,
  format:
    | 'short'
    | 'shortWithTime'
    | 'monthYear'
    | typeof DateTime.DATE_MED
    | typeof DateTime.DATETIME_MED = DateTime.DATETIME_MED
) => {
  const dateToFormat =
    typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
  switch (format) {
    case 'short':
      return dateToFormat.toLocaleString({
        month: 'short',
        day: 'numeric',
      });
    case 'shortWithTime':
      return dateToFormat.toLocaleString({
        month: 'short',
        day: 'numeric',
        ...DateTime.TIME_SIMPLE,
      });
    case 'monthYear':
      return dateToFormat.toLocaleString({month: 'short', year: 'numeric'});
    case DateTime.DATE_MED:
      return dateToFormat.toLocaleString(DateTime.DATE_MED);
    default:
      return dateToFormat.toLocaleString(DateTime.DATETIME_MED);
  }
};
