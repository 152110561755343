import {cn} from '../../utils';

export const ColorCard = ({
  value,
  color,
  className,
}: {
  value: string;
  color: string;
  className?: string;
}) => (
  <div
    key={value}
    className={cn(
      className,
      'group relative flex flex-col overflow-hidden rounded-lg bg-white p-2 pb-0 shadow-[0_0_60px_-15px_rgba(0,0,0,0.3)]'
    )}
  >
    <div
      style={{backgroundColor: color}}
      className={'sm:aspect-h-4 aspect-h-2 aspect-w-3 sm:aspect-none rounded-md sm:h-20'}
    />
    <div className="my-1 flex flex-1 flex-col space-y-2">
      <span>
        <span className="block text-sm text-gray-500">{value}</span>
      </span>
    </div>
  </div>
);
