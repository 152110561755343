import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {isValidPhoneNumber} from '@zentact/common';
import {Button, InputPhone, Label, ValidationError} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const supportPhoneNumberSettingsFormSchema = () =>
  z.object({
    supportPhoneNumber: z
      .string()
      .nullable()
      .refine(
        phoneNumber => {
          if (phoneNumber) {
            return isValidPhoneNumber(phoneNumber);
          }
          return true;
        },
        {
          message: t`Phone number is invalid`,
        }
      ),
  });

export type SupportPhoneNumberSettingsFormData = z.infer<
  ReturnType<typeof supportPhoneNumberSettingsFormSchema>
>;

type Props = {
  defaultValues: {
    supportPhoneNumber?: string | null;
  };
  onSave: (formData: SupportPhoneNumberSettingsFormData) => Promise<void>;
};

export const SupportPhoneNumberSettingsForm = ({defaultValues, onSave}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm<SupportPhoneNumberSettingsFormData>({
    resolver: zodResolver(supportPhoneNumberSettingsFormSchema()),
    defaultValues: {
      supportPhoneNumber: defaultValues.supportPhoneNumber || '',
    },
  });

  useEffect(() => {
    reset({
      supportPhoneNumber: defaultValues.supportPhoneNumber || '',
    });
  }, [defaultValues.supportPhoneNumber]);

  const submitForm = async (values: SupportPhoneNumberSettingsFormData) => {
    setLoading(true);
    await onSave(values);
    setLoading(false);
  };

  return (
    <form className="flex flex-col gap-5">
      <div className="flex flex-col w-full gap-6 md:w-1/2">
        <div className="sm:col-span-3">
          <div className="sm:col-span-3">
            <Label text={t`Support Phone Number`}>
              <InputPhone name="supportPhoneNumber" control={control} />
              <ValidationError isVisible={Boolean(errors.supportPhoneNumber)}>
                {errors.supportPhoneNumber?.message}
              </ValidationError>
              <p className="text-xs leading-5 text-gray-500">
                <Trans>
                  This organziation's phone number will be displayed to users on the checkout page
                  and in emails.
                </Trans>
              </p>
            </Label>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-6">
        <Button
          type="button"
          className="items-center gap-1 w-fit"
          size="xl"
          onClick={handleSubmit(submitForm)}
          isLoading={loading}
          disabled={loading}
        >
          <Trans>Save support phone number settings</Trans>
        </Button>
      </div>
    </form>
  );
};
