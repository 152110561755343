import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {OrganizationUserItem} from '@zentact/api/src/trpc/routers/usersRouter';
import {Auth0UserRole, TableColumnSize, formatLocaleDate} from '@zentact/common';
import {FlatPillWithDot} from '../../../../elements';
import {TruncatedText} from '../../../../other';
import {UserActions} from './users-actions';

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;

const userStatusToColor: Record<string, PillProps['color']> = {
  // biome-ignore lint/style/useNamingConvention: UI Mapping
  Active: 'green',
};

const getLocalizedStatusMap = (i18n: I18n): Record<string, string> => ({
  // biome-ignore lint/style/useNamingConvention: UI Mapping
  Active: i18n._('Active'),
});

export const getLocalizedUserRoleMap = (i18n: I18n): {[Role in Auth0UserRole]: string} => ({
  [Auth0UserRole.ORGANIZATION_ADMIN]: i18n._('Organization Admin'),
  [Auth0UserRole.TENANT_ADMIN]: i18n._('Admin'),
  [Auth0UserRole.MERCHANT_ADMIN]: i18n._('Merchant Admin'),
});

const columnsHelper = createColumnHelper<OrganizationUserItem>();

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  activeUsersRefetch: () => void;
  i18n: I18n;
  mode: 'organizationUser' | 'tenantUser';
  openUserEditPanel: (row: OrganizationUserItem) => void;
};
export const getActiveUsersColumns = ({
  trpc,
  activeUsersRefetch,
  i18n,
  mode,
  openUserEditPanel,
}: Props) => {
  const localizedUserRoleMap = getLocalizedUserRoleMap(i18n);
  return [
    columnsHelper.accessor('name', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Name'),
    }),
    columnsHelper.accessor('email', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Contact'),
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor('roles', {
      cell: info => {
        const roles = (info.getValue() as string[]).map((role: string) => {
          const localizedRole = localizedUserRoleMap[role as Auth0UserRole];
          if (localizedRole) {
            return localizedRole;
          }

          return role;
        });
        return roles.join(', ') || '-';
      },
      header: () => i18n._('Role'),
      size: TableColumnSize.XXS,
      meta: {
        collapseAt: 'lg',
      },
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={userStatusToColor[info.getValue() as string] || 'blue'}
          label={getLocalizedStatusMap(i18n)[info.getValue() as string] || 'No info'}
        />
      ),
      header: () => i18n._('Status'),
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime') || '-',
      header: () => i18n._('Last Active'),
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: 'sm',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => {
        return (
          <div className="flex justify-center">
            <UserActions
              userRow={props.row.original}
              refetchActiveUsersList={activeUsersRefetch}
              trpc={trpc}
              mode={mode}
              openUserEditPanel={openUserEditPanel}
            />
          </div>
        );
      },
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
