import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {useEffect} from 'react';
import {InitiateAdyenAchComponentFunction} from '../adyen-components/use-adyen-ach';

type AchPaymentMethodFormProps = {
  checkout: GetCheckoutV2Output;
  initiateAdyenAchComponent: InitiateAdyenAchComponentFunction;
};

export const AchPaymentMethodForm = ({
  checkout,
  initiateAdyenAchComponent,
}: AchPaymentMethodFormProps) => {
  useEffect(() => {
    if (!checkout) {
      return;
    }

    const domNode = document.getElementById('ach-container');
    if (!domNode) {
      return;
    }

    initiateAdyenAchComponent(domNode, checkout);
  }, [checkout, initiateAdyenAchComponent]);

  return (
    <div className="mt-2">
      <div id="ach-container" />
    </div>
  );
};
