import {ButtonHTMLAttributes, forwardRef} from 'react';
import {IconSpinner} from '../../icons/spinner';
import {cn} from '../../utils';

type ButtonSize = 'xs' | 'sm' | 'md' | 'mds' | 'lg' | 'xl';
type ButtonVariant =
  | 'primary'
  | 'primary-confirm'
  | 'secondary'
  | 'soft'
  | 'soft-confirm'
  | 'text-primary'
  | 'text-secondary';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  isLoading?: boolean;
  showLoadingIcon?: boolean;
  loadingIconClassName?: string;
};

const buttonSizeStyles: Record<ButtonSize, string> = {
  xs: cn('rounded px-2 py-1 text-xs'),
  sm: cn('rounded px-2 py-1 text-sm'),
  mds: cn('rounded-md px-2 py-1.5 text-sm'),
  md: cn('rounded-md px-2.5 py-1.5 text-sm'),
  lg: cn('rounded-md px-3 py-2 text-sm'),
  xl: cn('rounded-md px-3.5 py-2.5 text-sm'),
};

const buttonVariantStyles: Record<ButtonVariant, {default: string; disabled: string}> = {
  primary: {
    default: cn(
      'bg-primary-600 text-white shadow-sm hover:bg-primary-500',
      'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
    ),
    disabled: cn('cursor-not-allowed bg-primary-600 opacity-50 hover:bg-primary-600'),
  },
  'primary-confirm': {
    default: cn(
      'bg-green-400 text-white shadow-sm hover:bg-green-500',
      'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400'
    ),
    disabled: cn('cursor-not-allowed bg-green-400 opacity-50 hover:bg-green-400'),
  },
  secondary: {
    default: cn(
      'bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
    ),
    disabled: cn('cursor-not-allowed bg-white opacity-50 hover:bg-white'),
  },
  soft: {
    default: cn('bg-primary-100 text-primary-800 hover:bg-primary-200'),
    disabled: cn('cursor-not-allowed bg-primary-100 opacity-50 hover:bg-primary-100'),
  },
  'text-primary': {
    default: cn('text-primary-600'),
    disabled: cn('cursor-not-allowed opacity-50'),
  },
  'text-secondary': {
    default: cn('text-gray-600'),
    disabled: cn('cursor-not-allowed opacity-50'),
  },
  'soft-confirm': {
    default: cn(
      'bg-green-50 text-green-800 hover:bg-green-100',
      'focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'
    ),
    disabled: cn('cursor-not-allowed bg-green-50 opacity-50 hover:bg-green-50'),
  },
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    size = 'md',
    variant = 'primary',
    isLoading,
    showLoadingIcon = true,
    loadingIconClassName,
    ...restProps
  } = props;

  return (
    // biome-ignore lint/a11y/useButtonType: TODO
    <button
      {...restProps}
      ref={ref}
      className={cn(
        'flex w-full items-center justify-center',
        'font-semibold leading-6',
        buttonSizeStyles[size],
        buttonVariantStyles[variant].default,
        props.disabled && buttonVariantStyles[variant].disabled,
        isLoading && 'animate-pulse',
        props.className
      )}
    >
      {showLoadingIcon && isLoading && (
        <IconSpinner className={cn('ml-1 mr-3', loadingIconClassName)} />
      )}
      {children}
    </button>
  );
});
