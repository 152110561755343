import {cn} from '@zentact/ui-tailwind/utils';
import {Logo} from '../logo';
import {LogoutLink} from '../logout-link';
import {MenuMobile} from '../menu-mobile';

export const TopBar = ({className}: {className?: string}) => {
  return (
    <header>
      <nav
        className={cn(
          'mx-auto flex max-w-7xl items-center justify-between px-6 py-5 lg:px-8',
          className
        )}
        aria-label="Global"
      >
        <Logo className="pr-5" />
        <MenuMobile />
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <LogoutLink />
        </div>
      </nav>
    </header>
  );
};
