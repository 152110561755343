import {LocaleCode} from '../../constants';

/**
 * Formats a fraction of a total as a percentage string according to the given locale and decimal precision.
 *
 * @param {number} total - The total value.
 * @param {number} fraction - The fraction of the total value.
 * @param {LocaleCode} locale - The locale code used for formatting.
 * @param {number} [decimals=2] - The number of decimal places to use. Defaults to 2.
 * @returns {string} The formatted percentage string.
 *
 * @example
 * formatPercentage(200, 50, 'en-US'); // '25%'
 * formatPercentage(200, 50, 'en-US', 0); // '25%'
 */
export const formatPercentage = (
  total: number,
  fraction: number,
  locale: LocaleCode,
  decimals: number | undefined = 2
): string => {
  if (total === 0 || Number.isNaN(total) || Number.isNaN(fraction)) {
    const zeros = new Array(decimals)
      .fill(0)
      .map(_v => '0')
      .join('');
    return `0.${zeros}%`; // Return '0.00%' instead of 'NaN%'
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(fraction / total);
};
