import {useStore} from '@/store';
import {ArrowUpRightIcon} from '@heroicons/react/24/outline';
import {Trans} from '@lingui/macro';
import {Button, ContentCard, Typography} from '@zentact/ui-tailwind';
import {useEffect} from 'react';

export const RegistrationComplete = ({onComplete}: {onComplete: () => void}) => {
  const {organizationRefetch, merchantAccountsRefetch} = useStore();
  // Need to update global with update list of organizations
  // because application requires at least one active merchant account.
  useEffect(() => {
    organizationRefetch?.();
    merchantAccountsRefetch?.();
  }, []);

  return (
    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <Typography as="h3" variant="header-lg">
          <Trans>You're Approved</Trans>
        </Typography>
      </div>
      <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
        <Typography variant="paragraph">
          <Trans>We've recieved your status from Adyen.</Trans>
        </Typography>
        <ContentCard type="confirm" title="Your merchant application is succesfuly verified">
          <Trans>
            Your account has been approved and you are ready to get started. You can see real time
            transactions and such in your merchant portal
          </Trans>
        </ContentCard>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 p-4 sm:px-8">
        <Button className="w-fit items-center gap-1" size="xl" onClick={() => onComplete()}>
          <Trans>Launch the Merchant Portal</Trans>
          <ArrowUpRightIcon className="h-4 w-4 stroke-[3px]" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
};
