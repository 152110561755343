import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {
  OrganizationUserItem,
  OrganizationUsersOutput,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {TableSortValue, TableWrapper} from '../../../../table';
import {getAuth0DeletedUsersListFromStorage} from '../../../../utils';
import {getActiveUsersColumns} from './columns';

type Props = {
  usersList?: OrganizationUsersOutput;
  filters: {
    status: [];
  };
  noDataMessage?: JSX.Element;
  isLoading?: boolean;
  isFetching?: boolean;
  setFilters: Dispatch<SetStateAction<{status: []}>>;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  activeUsersRefetch: () => void;
  openUserEditPanel: (row: OrganizationUserItem) => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
};

export const ActiveUsersList = ({
  usersList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  noDataMessage,
  isLoading,
  isFetching,
  activeUsersRefetch,
  trpc,
  mode,
  openUserEditPanel,
}: Props) => {
  const {i18n} = useLingui();
  const filteredUserList = useMemo(() => {
    const auth0DeletedUserIds = getAuth0DeletedUsersListFromStorage();
    return (usersList?.users ?? []).filter(u => !auth0DeletedUserIds.includes(u.id));
  }, [usersList, isFetching]);

  return (
    <TableWrapper
      columns={getActiveUsersColumns({activeUsersRefetch, i18n, trpc, mode, openUserEditPanel})}
      data={filteredUserList}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        setFilters(prev => ({...prev, [id]: value}));
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={noDataMessage}
      isLoading={isLoading}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={
        typeof usersList?.total === 'number' && typeof usersList?.limit === 'number'
          ? Math.ceil(usersList?.total / usersList?.limit)
          : 0
      }
      onRowClick={openUserEditPanel}
      excludeLastColumnFromRowClick
    />
  );
};
