import {ErrorCode} from './errorCode';
import {UserErrorParams} from './userError';
export class FormattedTrpcError {
  // we can only change "message" and "data" properties, other props will be cut/replaced by trpc.
  constructor(
    readonly message: string,
    readonly data: {
      isFormattedTrpcError: true;
      code?: string;
      httpStatus?: number;
      errorCode: ErrorCode;
      brandConfiguration?: UserErrorParams['brandConfiguration'];
      // schema validation error details
      issues?: Array<{
        code: string;
        message: string;
        path: Array<string | number>;
      }>;
    },
    readonly code: number
  ) {}
}

export const isFormattedTrpcError = (error: unknown): error is FormattedTrpcError => {
  // trpc error  is always TRPCClientError on the frontend side so "error instanceof FormattedTrpcError" may have negative result.
  // that is why we have second check
  return (
    error instanceof FormattedTrpcError ||
    !!(error as FormattedTrpcError)?.data?.isFormattedTrpcError
  );
};
