import {Tooltip} from '../overlays';
import {truncateText} from '../utils';

type Props = {
  text: string;
  maxLength?: number;
};
export const TruncatedText = ({text, maxLength = 20}: Props) => {
  if (text.length <= maxLength) {
    return text;
  }
  return <Tooltip text={text}>{truncateText(text, maxLength)}</Tooltip>;
};
