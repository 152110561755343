import {ArrowUpRightIcon} from '@heroicons/react/20/solid';
import {i18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {Badge, Button, Typography} from '@zentact/ui-tailwind';
import {trpc} from '../../../../api/trpcClient';
import {merchantRegistrationStepTitles} from '../../constants';

export const MerchantVerificationPending = ({
  onBackToApplication,
  registrationSessionId,
}: {
  onBackToApplication: (redirectUrl: string) => void;
  registrationSessionId: string;
}) => {
  const adyenOnboardingLinkMutation = trpc.merchantRegistration.adyenOnboardingLink.useMutation({
    onSuccess(redirectUrl) {
      onBackToApplication(redirectUrl);
    },
  });

  return (
    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
        <Typography as="h3" variant="header-lg">
          {i18n._(merchantRegistrationStepTitles().step4)}
        </Typography>
      </div>
      <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
        <Badge className="w-fit">
          <Trans>Waiting to hear back</Trans>
        </Badge>
        <Typography variant="paragraph">
          <Trans>We're waiting to hear back on your application status from Adyen.</Trans>
        </Typography>
        <Typography variant="paragraph">
          <Trans>
            Once you've submitted the forms, verification can take from a few minutes to a few
            hours. You can close this window, and we'll notify you by email once the verification is
            complete.
          </Trans>
        </Typography>
      </div>
      <div className="flex items-center justify-end p-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
        <Button
          disabled={adyenOnboardingLinkMutation.isLoading}
          className="items-center gap-1 w-fit"
          size="xl"
          onClick={() =>
            adyenOnboardingLinkMutation.mutate({
              registrationSessionId,
            })
          }
        >
          <Trans>Back to Merchant App</Trans>
          <ArrowUpRightIcon className="h-4 w-4 stroke-[3px]" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
};
