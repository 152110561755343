import {dinero, toDecimal} from 'dinero.js';
import {CurrencyCode} from '../../constants';
import {supportedCurrency} from './supportedCurrency';

export const fromMinorUnits = (
  amountInMinorUnits: number | null,
  currency: CurrencyCode,
  scale?: number
) => {
  if (!amountInMinorUnits) {
    return 0;
  }

  const _currency = supportedCurrency[currency];
  const decimalAmount = toDecimal(
    dinero({
      amount: amountInMinorUnits,
      currency: _currency,
      scale,
    })
  );
  return Number(decimalAmount);
};
