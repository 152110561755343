import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {BaseTheme, Button, InputColor} from '@zentact/ui-tailwind';
import {ReactNode, useEffect, useState} from 'react';
import {hex} from 'wcag-contrast';

export const GeneratePalette = ({
  baseColor = BaseTheme.primary,
  onGenerateColors,
  onResetColors,
  localeText,
  children,
}: {
  baseColor: string;
  onGenerateColors: (color: string) => void;
  onResetColors: () => void;
  localeText: {
    apply: string;
    reset: string;
    cancel: string;
  };
  children: ReactNode;
}) => {
  const [color, setColor] = useState('');
  const [isAccessible, setIsAccessible] = useState(true);
  const {i18n} = useLingui();

  useEffect(() => {
    setColor(baseColor);
  }, [baseColor]);

  useEffect(() => {
    const contrast = hex(color, '#ffffff');
    setIsAccessible(contrast >= 3 ? true : false);
  }, [color]);

  return (
    <div className="relative flex flex-wrap items-center">
      <div className="flex flex-row w-full">
        <div className="md:w-1/2 md:pr-2">
          <InputColor value={color} onChange={setColor} />
        </div>
        {!isAccessible && (
          <div className="flex my-auto md:w-1/2">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="w-6 h-6 text-red-500" aria-hidden="true" />
            </div>
            <div className="ml-2">
              <p className="text-sm whitespace-nowrap">{i18n._('Low contrast!')}</p>
            </div>
          </div>
        )}
      </div>
      {children}
      <div className="flex flex-col justify-between w-full mt-5 sm:flex-row">
        <div className="flex mb-2 sm:w-auto sm:mb-0">
          <Button
            size="xl"
            className="inline-block w-1/2 text-white bg-red-600 hover:bg-red-500 md:w-auto"
            onClick={onResetColors}
          >
            {localeText.reset}
          </Button>
        </div>
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
          <Button
            size="xl"
            variant="secondary"
            className="inline-block w-1/2 md:w-auto"
            onClick={() => {
              setColor('');
              //setTimeout is used here to set base color if input color is empty
              //setTimeout set to 0 to defer the execution until the stack is clear
              setTimeout(() => {
                setColor(baseColor);
              }, 0);
            }}
          >
            {localeText.cancel}
          </Button>
          <Button
            style={{backgroundColor: color}}
            size="xl"
            className="inline-block w-1/2 md:w-auto"
            onClick={() => onGenerateColors(color)}
          >
            {localeText.apply}
          </Button>
        </div>
      </div>
    </div>
  );
};
