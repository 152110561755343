import {t} from '@lingui/macro';
import {InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';
import z from 'zod';

export const getMerchantAccountFormSchema = () =>
  z.object({
    name: z
      .string()
      .min(1, t`Merchants account name is required`)
      .max(80, t`Merchant account name cannot be longer than 80 characters`),
    email: z.string().min(1, t`Email is required`).email(t`Email is not valid`),
  });

export type MerchantAccountFormData = z.infer<ReturnType<typeof getMerchantAccountFormSchema>>;

type MerchantAccountFormProps = {
  form: UseFormReturn<MerchantAccountFormData>;
  initialValues?: {email?: string};
  children?: JSX.Element;
};

export const AddMerchantAccountForm = ({
  form,
  initialValues,
  children,
}: MerchantAccountFormProps) => {
  const {
    register,
    formState: {errors},
  } = form;

  useEffect(() => {
    if (initialValues?.email) {
      form.setValue('email', initialValues.email);
    }
  }, [initialValues]);

  return (
    <div className="flex flex-col gap-3">
      <Label text={t`Merchant Account Name`}>
        <InputText {...register('name', {required: true})} hasError={Boolean(errors.name)} />
        <ValidationError isVisible={Boolean(errors.name)}>{errors.name?.message}</ValidationError>
      </Label>
      <Label text={t`Email`}>
        <InputText
          {...register('email', {required: true})}
          placeholder="example@mail.com"
          hasError={Boolean(errors.email)}
          readOnly={Boolean(initialValues?.email)}
          className="read-only:bg-gray-100"
          disabled={Boolean(initialValues?.email)}
        />
        <ValidationError isVisible={Boolean(errors.email)}>{errors.email?.message}</ValidationError>
      </Label>
      {children}
    </div>
  );
};
