import {isURL} from 'validator';
import {z} from 'zod';

export const isValidUrl = (errorMessage: string) =>
  z
    .string()
    .trim()
    // biome-ignore lint/style/useNamingConvention: Validator convention
    .refine(url => isURL(url, {require_protocol: false, require_tld: true}), {
      message: errorMessage,
    })
    .transform(val =>
      val.startsWith('http://') || val.startsWith('https://') ? val : `https://${val}`
    );
