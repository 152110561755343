export const ApplePayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="60" width="80" fill="none" viewBox="0 0 40 26">
      <title>ApplePay</title>
      <path
        fill="#fff"
        d="M36.42.87h.73c.2 0 .42.02.62.06a1.67 1.67 0 0 1 .88.44 1.58 1.58 0 0 1 .44.89 4.38 4.38 0 0 1 .06.97v19.55a14.67 14.67 0 0 1-.06.96 1.7 1.7 0 0 1-.44.89 1.54 1.54 0 0 1-.87.44 4.27 4.27 0 0 1-.96.06H2.85a3.7 3.7 0 0 1-.63-.06 1.66 1.66 0 0 1-.87-.45 1.56 1.56 0 0 1-.44-.88 4.35 4.35 0 0 1-.06-.97V2.9c.01-.2.02-.42.06-.63.03-.18.08-.34.16-.49A1.56 1.56 0 0 1 2.22.93a4.2 4.2 0 0 1 .96-.06h33.24"
      />
      <path
        fill="#000"
        d="M10.92 8.61c.34-.43.57-1 .51-1.59a2.21 2.21 0 0 0-1.99 2.3c.56.04 1.12-.3 1.48-.7Zm.51.81c-.82-.05-1.52.46-1.9.46-.4 0-1-.43-1.64-.42-.84 0-1.62.48-2.05 1.24-.88 1.52-.23 3.76.62 5 .42.6.92 1.27 1.58 1.25.62-.02.86-.4 1.62-.4.75 0 .97.4 1.63.39.69-.01 1.11-.61 1.53-1.22.47-.7.67-1.37.68-1.4-.01-.02-1.32-.52-1.33-2.02-.01-1.26 1.03-1.85 1.07-1.9a2.34 2.34 0 0 0-1.81-.98Zm7.11-1.7a2.87 2.87 0 0 1 3.02 3c0 1.8-1.27 3.03-3.06 3.03h-1.97v3.12h-1.42V7.72h3.43Zm-2 4.83h1.62c1.24 0 1.94-.66 1.94-1.82 0-1.15-.7-1.81-1.93-1.81h-1.64v3.63Zm5.39 2.43c0-1.17.9-1.89 2.48-1.98l1.83-.1v-.52c0-.74-.5-1.18-1.34-1.18-.8 0-1.3.38-1.41.97h-1.3c.08-1.2 1.1-2.09 2.76-2.09 1.62 0 2.65.86 2.65 2.2v4.6h-1.31v-1.1h-.04a2.38 2.38 0 0 1-2.1 1.2c-1.3 0-2.22-.8-2.22-2Zm4.3-.6v-.53l-1.64.1c-.82.06-1.28.42-1.28.99 0 .58.48.96 1.22.96.96 0 1.7-.66 1.7-1.52Zm2.61 4.95v-1.11c.1.03.33.03.44.03.64 0 .98-.27 1.19-.96l.12-.4-2.41-6.69h1.48l1.7 5.43h.02l1.69-5.43h1.44l-2.5 7.02c-.57 1.62-1.23 2.14-2.61 2.14a5.3 5.3 0 0 1-.56-.03Z"
      />
    </svg>
  );
};
