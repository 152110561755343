// Public facing merchant account status that is based on a combination of a merchant account and its registration status
export enum MerchantAccountPublicStatus {
  // Account statuses after onboarding
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  REJECTED = 'REJECTED',
  // Account statuses before boarding
  ONBOARDING = 'ONBOARDING',
  ONBOARDING_CANCELLED = 'ONBOARDING_CANCELLED',
  INITIATED = 'INITIATED', // created without sending an inivte
  INVITED = 'INVITED', // created with invite sending or invite was sent later
  INVITE_EXPIRED = 'INVITE_EXPIRED',
  INVITE_REVOKED = 'INVITE_REVOKED',
  AWAITING_INFO = 'AWAITING_INFO',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  REVIEW_REJECTED = 'REVIEW_REJECTED',
  NEEDS_ORGANIZATION = 'NEEDS_ORGANIZATION',
}
