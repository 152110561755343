import {MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {useEffect, useRef, useState} from 'react';
import {InputSearch, cn} from '../..';
import {FilterOnChange} from '../types';

type Props = {
  filterId: string;
  filterValues?: Record<string, unknown> | undefined;
  onChange: FilterOnChange<string, unknown>;
  inputClassName?: string;
};
export const TableSearchFilter = ({filterId, filterValues, onChange, inputClassName}: Props) => {
  const [open, setOpen] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);
  const filterValue = filterValues?.[filterId] as string;

  useEffect(() => {
    if (open) {
      searchInput.current?.focus();
    }
  }, [open]);

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: StopPropagation is used to prevent the click event from bubbling up to the parent element(TableCellWrapper sort click event)
    <div className="relative" onClick={e => e.stopPropagation()}>
      {!open ? (
        <MagnifyingGlassIcon
          className={cn(
            'ml-3 h-4 w-4 cursor-pointer',
            filterValues?.[filterId] ? 'text-primary-600' : 'text-gray-500'
          )}
          onClick={() => setOpen(true)}
        />
      ) : (
        <InputSearch
          value={filterValue || ''}
          ref={searchInput}
          onChange={e => onChange?.({id: filterId, value: e.currentTarget.value})}
          onClear={() => (filterValue ? onChange?.({id: filterId, value: ''}) : setOpen(false))}
          placeholder="Search..."
          className={inputClassName ?? 'absolute -mt-4 w-40 -translate-x-20 transform'}
        />
      )}
    </div>
  );
};
