import {trpc} from '@/api/trpcClient';
import {t} from '@lingui/macro';
import {ShopperPrimaryPaymentMethodOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {Typeahead} from '@zentact/ui-tailwind';
import {Controller, UseFormReturn} from 'react-hook-form';
import {useDebounce} from 'use-debounce';
import {TakePaymentFormData} from './get-take-payment-form-schema';

type Props = {
  form: UseFormReturn<TakePaymentFormData>;
  isL2DataRequired: boolean;
  setPaymentMethod: (d: ShopperPrimaryPaymentMethodOutput | null) => void;
};
export const CustomerIdTypeahead = ({form, isL2DataRequired, setPaymentMethod}: Props) => {
  const {watch, setError, control} = form;
  const customerIdWatch = watch('customerId');
  const merchantAccountId = watch('merchantAccountId');

  const [customerId] = useDebounce(customerIdWatch, 300);

  const {data: shopperIdPredictions} =
    trpc.savedPaymentMethod.getShopperWithPrimaryPaymentMethodPredictions.useQuery(
      {
        merchantAccountId,
        merchantShopperId: customerId,
      },
      {
        enabled: !!merchantAccountId && !!customerId,
        onSuccess: res => {
          if (
            customerId === res?.query.merchantShopperId &&
            !res?.rows?.find(row => row.merchantShopperId === customerId)
          ) {
            setPaymentMethod(null);
          }
        },
      }
    );

  const onSelectTypeaheadCustomerId = (value: string) => {
    if (value && !merchantAccountId) {
      setError('customerId', {
        message: t`Please choose merchant account before selecting a customer`,
      });
      return;
    }

    const row = shopperIdPredictions?.rows?.find(
      prediction => prediction.merchantShopperId === value
    );
    if (!row) {
      return;
    }
    setPaymentMethod(row);
  };
  return (
    <Controller
      name="customerId"
      control={control}
      render={({field}) => (
        <Typeahead
          {...field}
          onSelect={onSelectTypeaheadCustomerId}
          label={
            isL2DataRequired
              ? t`Customer ID / Account Number`
              : t`Customer ID / Account Number (Optional)`
          }
          options={
            shopperIdPredictions?.rows?.map(item => ({
              id: item.merchantShopperId,
              label: item.merchantShopperId,
            })) ?? []
          }
        />
      )}
    />
  );
};
