import {RoutePath} from '@/components/layout/navigation';
import {Trans} from '@lingui/macro';
import {Link} from 'react-router-dom';
import bgImage from '../../assets/images/rupixen-com-Q59HmzK38eQ-unsplash.jpg';

export const Customize = () => {
  return (
    <Link to={RoutePath.CONFIGURE}>
      <div className="relative flex flex-col h-auto overflow-hidden bg-white rounded-lg shadow group sm:h-full">
        <div className="bg-gray-200 aspect-h-3 aspect-w-3 sm:aspect-none group-hover:opacity-75">
          <img
            src={bgImage}
            alt={''}
            className="object-cover object-center w-full h-full sm:h-full sm:w-full"
          />
        </div>
        <div className="flex flex-col flex-1 p-4 space-y-2">
          <h3 className="text-sm font-medium text-gray-900">
            <span aria-hidden="true" className="absolute inset-0" />
            <Trans>Customize Your Brand</Trans>
          </h3>
          <p className="text-sm text-gray-500">
            <Trans>
              Upload your logos and brand colors to keep a consistent experience for your customers
            </Trans>
          </p>
        </div>
      </div>
    </Link>
  );
};
