import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type Options = {name: string; type: 'hash' | 'search'; clearUrl: boolean};

export const useUrlToken = ({name = 'sid', type = 'hash', clearUrl = true}: Partial<Options>) => {
  const _options: Options = {
    name,
    type,
    clearUrl,
  };
  const location = useLocation();

  const [token, setToken] = React.useState<string | null>(
    location[_options.type].split(`${_options.name}=`)[1] ||
      window.localStorage.getItem(_options.name)
  );

  useEffect(() => {
    if (!token) {
      window.localStorage.removeItem(_options.name);
      return;
    }

    window.localStorage.setItem(_options.name, token);
    if (
      _options.clearUrl &&
      _options.type === 'hash' &&
      location.hash &&
      location.hash.split('=')[0] === `#${_options.name}`
    ) {
      window.history.pushState('', document.title, window.location.pathname);
    }
    //location.pathname is intentionally not included in the dependency array because we don't want
    //to update the token every time the page changes.
  }, [token]);

  const clearSession = () => setToken(null);

  return {token, clearSession};
};
