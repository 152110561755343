import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import {ReactNode} from 'react';
import {cn} from '../../utils';

type CardType = 'info' | 'warn' | 'error' | 'confirm';

const icons: Record<CardType, ReactNode> = {
  info: <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />,
  warn: <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
  error: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
  confirm: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
};

const bgColorClassNameClassNames: Record<CardType, string> = {
  info: cn('bg-blue-50'),
  warn: cn('bg-yellow-50'),
  error: cn('bg-red-50'),
  confirm: cn('bg-green-50'),
};

const headerTextColors: Record<CardType, string> = {
  info: cn('text-blue-800'),
  warn: cn('text-yellow-800'),
  error: cn('text-red-800'),
  confirm: cn('text-green-800'),
};

const contentTextColors: Record<CardType, string> = {
  info: cn('text-blue-700'),
  warn: cn('text-yellow-700'),
  error: cn('text-red-700'),
  confirm: cn('text-green-700'),
};

export type ContentCardProps = {
  children: ReactNode;
  title?: ReactNode;
  type: CardType;
  className?: string;
  childrenWrapperClassName?: string;
};

export const ContentCard = ({
  children,
  title,
  type,
  className,
  childrenWrapperClassName,
}: ContentCardProps) => {
  return (
    <div className={cn(`rounded-md ${bgColorClassNameClassNames[type]} p-4`, className)}>
      <div className="flex">
        <div className="shrink-0">{icons[type]}</div>
        <div className="ml-3 flex flex-col gap-2">
          {title && <h3 className={`text-sm font-medium ${headerTextColors[type]}`}>{title}</h3>}
          <div className={cn(`text-sm ${contentTextColors[type]}`, childrenWrapperClassName)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
