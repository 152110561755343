import {useSnackbar} from 'notistack';
import {useCallback} from 'react';

export const useNotification = () => {
  const {enqueueSnackbar} = useSnackbar();

  const showNotification = useCallback(
    (variant: 'success' | 'error' | 'info', title: string, message?: string) => {
      enqueueSnackbar({
        title,
        message,
        variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
    []
  );

  const showInfoNotification = useCallback(
    (title: string, message?: string) => {
      return showNotification('info', title, message);
    },
    [showNotification]
  );

  const showSuccessNotification = useCallback(
    (titleOrConfig: string | {title: string; message?: string}, message?: string) => {
      let title: string;
      let messageText: string | undefined = message;
      if (typeof titleOrConfig === 'string') {
        title = titleOrConfig;
      } else {
        title = titleOrConfig.title;
        messageText = titleOrConfig.message;
      }

      return showNotification('success', title, messageText);
    },
    [showNotification]
  );

  const showErrorNotification = useCallback(
    (title: string, message?: string) => {
      return showNotification('error', title, message);
    },
    [showNotification]
  );

  return {
    showInfoNotification,
    showSuccessNotification,
    showErrorNotification,
  };
};
