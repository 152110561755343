import {CurrencyCode, LocaleCode} from '../../constants';
import {fromMinorUnits} from './fromMinorUnits';

export const formatAmount = (
  amount: number | undefined | null,
  locale: LocaleCode,
  currency: CurrencyCode,
  decimals: number | undefined = 2
) => {
  // todo:
  // ensure amount is in minor units and use toIntlFormat
  // return toIntlFormat({amountMinorUnits: amount ?? 0, currencyCode: currency}, locale);
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(fromMinorUnits(amount ?? 0, currency));
};

export const formatAmountRegularNumber = (
  amount: number | undefined | null,
  locale: LocaleCode,
  currency: CurrencyCode,
  decimals: number | undefined = 2
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(amount ?? 0);
};
