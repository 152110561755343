import {MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {ReactNode, useMemo, useState} from 'react';
import {HighlightedText} from '../../layout';
import {InputCheckbox} from '../input-checkbox';
import {InputText} from '../input-text';

type Props<T> = {
  value?: string[];
  options: T[];
  onChange: (value: string[]) => void;
  getLabel: (option: T) => string;
  getOptionId: (option: T) => string;
  filterable?: boolean;
  searchPlaceholder?: string;
  className?: string;
  children?: ReactNode;
};

export const InputCheckboxList = <T,>({
  value = [],
  options,
  onChange,
  getLabel,
  getOptionId,
  filterable,
  searchPlaceholder,
  className,
  children,
}: Props<T>) => {
  const {i18n} = useLingui();
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => {
    if (!filterable) {
      return options;
    }

    const searchQuery = search.trim().toLowerCase();

    return options.filter(option => {
      return getLabel(option).toLowerCase().includes(searchQuery);
    });
  }, [options, search]);

  return (
    <div className={className}>
      {filterable && (
        <>
          <InputText
            className="mb-2"
            value={search}
            onChange={event => setSearch(event.target.value)}
            placeholder={searchPlaceholder}
            addonBefore={<MagnifyingGlassIcon className="w-4 h-4 mr-4 text-gray-500" />}
          />
          {children}
        </>
      )}

      {filterable && filteredOptions.length === 0 && (
        <div className="py-2 text-sm text-gray-500">
          {i18n._('No results for "{search}"', {search})}
        </div>
      )}

      <ul className="flex flex-col gap-1">
        {filteredOptions.map(option => {
          const optionId = getOptionId(option);

          return (
            <li key={optionId} className="py-2 border-b border-gray-200">
              <InputCheckbox
                name={getOptionId(option)}
                checked={value.includes(getOptionId(option))}
                onChange={event => {
                  const newSelection = event.target.checked
                    ? [...value, optionId]
                    : value.filter(id => id !== optionId);
                  onChange(newSelection);
                }}
                view="list"
              >
                <HighlightedText text={getLabel(option)} highlight={search} />
              </InputCheckbox>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
