import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline';
import {Tooltip, cn} from '..';

type Props = {
  text: React.ReactNode;
  className?: string;
};

export const QuestionTooltip = ({text, className}: Props) => {
  return (
    <Tooltip text={text} contentClassName={'font-normal text-gray-600 max-w-md py-2 px-3'}>
      <QuestionMarkCircleIcon className={cn('w-5 h-5 text-gray-400', className)} />
    </Tooltip>
  );
};
