import {cn} from '@zentact/ui-tailwind';
import React, {InputHTMLAttributes, ReactNode, forwardRef} from 'react';

type SaleTypeRadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label: ReactNode;
  description?: ReactNode;
  selectedValue: string;
};

export const SaleTypeRadio = forwardRef<HTMLInputElement, SaleTypeRadioProps>(
  ({label, description, selectedValue, ...props}, ref) => {
    return (
      <label
        className={cn(
          'w-full flex justify-between bg-white shadow-sm rounded-md ring-gray-300 ring-1 cursor-pointer p-3',
          selectedValue === props.value && 'ring-primary-600 ring-2'
        )}
        htmlFor={`field-${props.name}-${props.value}`}
      >
        <div>
          <label className="text-sm font-medium text-gray-900 cursor-pointer">{label}</label>
          <div className="text-xs text-gray-600">{description}</div>
        </div>
        <input
          {...props}
          id={`field-${props.name}-${props.value}`}
          type="radio"
          ref={ref}
          className="w-4 h-4 bg-gray-100 border-gray-300 cursor-pointer text-primary-600 focus:ring-white focus:ring-0 disabled:opacity-70"
        />
      </label>
    );
  }
);
