import {I18n} from '@lingui/core';
import {CurrencyCode, LocaleCode, formatAmount, fromMinorUnits} from '@zentact/common';
import {z} from 'zod';

export const takeRefundFormSchema = (i18n: I18n, remainedAmount: number, currency: CurrencyCode) =>
  z.object({
    refundAmount: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod convention
        invalid_type_error: i18n._('Refund amount must be a number'),
        // biome-ignore lint/style/useNamingConvention: Zod convention
        required_error: i18n._('Refund amount is required'),
      })
      .multipleOf(0.01, i18n._('Refund amount must be a multiple of 0.01'))
      .min(
        fromMinorUnits(1, currency),
        i18n._('Refund amount must be greater than or equal to {amount}', {
          amount: formatAmount(100, i18n.locale as LocaleCode, currency),
        })
      )
      .max(
        fromMinorUnits(remainedAmount, currency),
        i18n._('Refund amount must be less than or equal to {amount}', {
          amount: formatAmount(remainedAmount, i18n.locale as LocaleCode, currency),
        })
      ),
  });

export type RefundFormDataType = z.infer<ReturnType<typeof takeRefundFormSchema>>;
