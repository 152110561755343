export enum CurrencyCode {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
  NZD = 'NZD',
}

export const DEFAULT_CURRENCY = CurrencyCode.USD;
