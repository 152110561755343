import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ErrorCode, LocaleCode, formatAmount, isFormattedTrpcError} from '@zentact/common';
import {CurrencyCode} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  FlatPillWithDot,
  FullPaymentDetailsSection,
  Loading,
  PaymentEventsList,
  RefundConfirmDialog,
  Typography,
  displayPaymentStatusMap,
  paymentStatusToColor,
  useRequiredParams,
} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const REFUND_AVAILABLE_STATUSES = ['SETTLED', 'AUTHORIZED', 'PARTIALLY_REFUNDED'];

const getBreadCrumbs = (pspReferenceId: string) => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Payments`, href: RoutePath.PAYMENTS, current: false},
  {name: pspReferenceId, href: '#', current: true},
];

export const PaymentDetails = () => {
  const navigate = useNavigate();
  const {i18n} = useLingui();
  const {tenant} = useStore();

  const {pspReferenceId} = useRequiredParams<{pspReferenceId: string}>();
  const [refundConfirmationOpen, setRefundConfirmationOpen] = useState(false);

  const payment = trpc.payment.getPaymentByPspReferenceId.useQuery(
    {pspReferenceId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  if (payment.isLoading || !payment.data) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }
  const formattedAuthorizedAmount = formatAmount(
    payment.data.authorizedAmount,
    i18n.locale as LocaleCode,
    payment.data.currency as CurrencyCode
  );
  const refundButtonVisible =
    REFUND_AVAILABLE_STATUSES.includes(payment.data.status) &&
    !tenant?.features?.disablePortalRefund;

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs(pspReferenceId)} />
      <div className="my-4">
        <Typography variant="header-page" className="flex">
          <Trans># {pspReferenceId}</Trans>
        </Typography>
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <div className="text-3xl leading-normal text-gray-700">{formattedAuthorizedAmount}</div>
            <div>
              <FlatPillWithDot
                color={paymentStatusToColor[payment.data.status] || 'blue'}
                label={displayPaymentStatusMap[payment.data.status] ?? payment.data.status}
              />
            </div>
          </div>
          <div>
            {refundButtonVisible && (
              <Button
                variant="primary"
                size="lg"
                className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                onClick={() => setRefundConfirmationOpen(true)}
              >
                {payment.data.status === 'AUTHORIZED' ? i18n._('Void') : i18n._('Refund')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 font-medium leading-6 border-b border-gray-200 sm:px-5">
          <div>
            <Trans>Payment Details</Trans>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <FullPaymentDetailsSection
            paymentRow={payment.data}
            merchantAccountName={payment.data.merchantAccount.businessName}
            merchantPath={RoutePath.MERCHANT_ACCOUNT_DETAILS}
            organizationName={payment.data.organization.name}
            shopperRoute={RoutePath.SAVED_PAYMENT_METHODS}
          />
        </div>
      </div>
      <div className="py-5 bg-white rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 font-medium leading-6 border-b border-gray-200 sm:px-5">
          <div>
            <Trans>Activity</Trans>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <PaymentEventsList payment={payment.data} />
        </div>
      </div>
      {refundButtonVisible && (
        <RefundConfirmDialog
          isOpen={refundConfirmationOpen}
          paymentDetailsRow={payment.data}
          onClose={() => setRefundConfirmationOpen(false)}
          onSuccess={() => payment.refetch()}
          trpc={trpc}
        />
      )}
    </div>
  );
};
