import {I18n} from '@lingui/core';

export const INVOICE_ID_ATTRIBUTE = 'INVOICE_ID';

export const getCustomAttributeTranslation = (attributeName: string, i18n: I18n) => {
  if (attributeName === INVOICE_ID_ATTRIBUTE) {
    return i18n._('Invoice ID');
  }
  return attributeName
    .toLowerCase()
    .split('_')
    .map(word => `${(word[0] ?? '').toUpperCase()}${word.slice(1)}`)
    .join(' ');
};
