import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {DeclinedPaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  RefusalReason,
  capitalizeCustomAttribute,
  formatAmount,
  formatLocaleDate,
  paymentMethodVariantTranslationMap,
} from '@zentact/common';
import {PaymentMethodIcon, SlideOverWithBrandedHeader} from '../../../';

export const getRefusalReasonTranslation = (i18n: I18n, refusalReason: string): string => {
  const translations: {[Key in RefusalReason]: string} = {
    // biome-ignore lint/style/useNamingConvention: <explanation>
    Refused: i18n._('The transaction was refused.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    Referral: i18n._('Referrals.'),
    'Acquirer Error': i18n._(
      "The transaction did not go through due to an error that occurred on the acquirer's end."
    ),
    'Blocked Card': i18n._('The card used for the transaction is blocked, therefore unusable.'),
    'Expired Card': i18n._(
      'The card used for the transaction has expired. Therefore it is unusable.'
    ),
    'Invalid Amount': i18n._('An amount mismatch occurred during the transaction process.'),
    'Invalid Card Number': i18n._('The specified card number is incorrect or invalid.'),
    'Issuer Unavailable': i18n._(
      "It is not possible to contact the shopper's bank to authorise the transaction."
    ),
    'Not supported': i18n._(
      "The shopper's bank does not support or does not allow this type of transaction."
    ),
    '3D Not Authenticated': i18n._(
      '3D Secure authentication was not executed, or it did not execute successfully.'
    ),
    'Not enough balance': i18n._(
      'The card does not have enough money to cover the payable amount.'
    ),
    'Acquirer Fraud': i18n._('Possible fraud.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    Cancelled: i18n._('The transaction was cancelled.'),
    'Shopper Cancelled': i18n._('The shopper cancelled the transaction before completing it.'),
    'Invalid Pin': i18n._('The specified PIN is incorrect or invalid.'),
    'Pin tries exceeded': i18n._(
      'The shopper specified an incorrect PIN more than three times in a row.'
    ),
    'Pin validation not possible': i18n._(
      'It is not possible to validate the specified PIN number.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    FRAUD: i18n._(
      'The pre-authorisation risk checks resulted in a fraud score of 100 or more. Therefore, the transaction was flagged as fraudulent, and was refused.'
    ),
    'Not Submitted': i18n._('The transaction was not submitted correctly for processing.'),
    'FRAUD-CANCELLED': i18n._(
      'The sum of pre-authorisation and post-authorisation risk checks resulted in a fraud score of 100 or more. Therefore, the transaction was flagged as fraudulent, and was refused.'
    ),
    'Transaction Not Permitted': i18n._(
      `The transaction was not permitted for the specified reason. The following declined codes are mapped to this refusal reason: "57: Transaction not permitted to issuer/cardholder", "57: Transaction not allowed for this merchant", "58: Transaction not permitted to acquirer/terminal"`
    ),
    'CVC Declined': i18n._('The specified CVC (card security code) is invalid.'),
    'Restricted Card': i18n._(
      `The card is restricted or invalid in this country. The following decline codes are mapped to this refusal reason: "62: Restricted Card", "62: Invalid card in this country"`
    ),
    'Revocation Of Auth': i18n._(
      `Indicates that the shopper requested to stop a subscription. Decline codes such as the following are mapped to this refusal reason: "R1: Revocation of Authorization Order", "R3: Revocation of All Authorizations Order", "R0: Stop Payment Order"`
    ),
    'Declined Non Generic': i18n._(
      'This response maps all those response codes that cannot be reliably mapped.'
    ),
    'Withdrawal amount exceeded': i18n._(
      "The withdrawal amount permitted for the shopper's card has exceeded."
    ),
    'Withdrawal count exceeded': i18n._(
      "The number of withdrawals permitted for the shopper's card has exceeded."
    ),
    'Issuer Suspected Fraud': i18n._('Issuer reported the transaction as suspected fraud.'),
    'AVS Declined': i18n._('The address data the shopper entered is incorrect.'),
    'Card requires online pin': i18n._(
      "The shopper's bank requires the shopper to enter an online PIN."
    ),
    'No checking account available on Card': i18n._(
      "The shopper's bank requires a checking account to complete the purchase."
    ),
    'No savings account available on Card': i18n._(
      "The shopper's bank requires a savings account to complete the purchase."
    ),
    'Mobile pin required': i18n._("The shopper's bank requires the shopper to enter a mobile PIN."),
    'Contactless fallback': i18n._(
      'The shopper abandoned the transaction after attempting a contactless payment and was prompted to try a different card entry method (PIN or swipe).'
    ),
    'Authentication required': i18n._(
      'The issuer declined the authentication exemption request and requires authentication for the transaction. Retry with 3D Secure.'
    ),
    'RReq not received from DS': i18n._(
      "The issuer or the scheme wasn't able to communicate the outcome via RReq."
    ),
    'Current AID is in Penalty Box': i18n._(
      "The payment network can't be reached. Retry the transaction with a different payment method."
    ),
    'CVM Required Restart Payment': i18n._(
      'A PIN or signature is required. Retry the transaction.'
    ),
    '3DS Authentication Error': i18n._(
      'The 3D Secure authentication failed due to an issue at the card network or issuer. Retry the transaction, or retry with a different payment method.'
    ),
    'Transaction blocked by Adyen to prevent excessive retry fees': i18n._(
      "Adyen's excessive retry prevention service blocked the transaction to make sure that you are not charged penalty fees."
    ),
  };

  return translations[refusalReason as RefusalReason] || i18n._('Unknown refusal reason.');
};

type ChargebackDetailsPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  declinedPaymentRow: DeclinedPaymentListItemOutput | null;
};

export const DeclinedPaymentDetailsPanel = ({
  isOpen,
  onCancel,
  declinedPaymentRow,
}: ChargebackDetailsPanelProps) => {
  const {i18n} = useLingui();

  if (!declinedPaymentRow) {
    return null;
  }
  const {
    pspReferenceId,
    reference,
    amount,
    currency,
    refusalReason,
    paymentMethod,
    paymentMethodVariant,
    merchantAccount,
    organization,
    cardSummary,
    createdAt,
    ownerName,
  } = declinedPaymentRow;

  const formattedAuthorizedAmount = formatAmount(
    amount,
    i18n.locale as LocaleCode,
    currency as CurrencyCode
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Declined Payment')}
      closeHandler={onCancel}
    >
      <div className="border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('PSP Transaction ID')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {pspReferenceId}
            </dd>
          </div>
          <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Reference')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {reference}
            </dd>
          </div>
          {!!organization?.name && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Organization')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {organization.name}
              </dd>
            </div>
          )}
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Merchant Account')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {merchantAccount.businessName}
            </dd>
          </div>
          {!!ownerName && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Owner Name')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ownerName}
              </dd>
            </div>
          )}
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Refusal Reason')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {refusalReason}
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Refusal Description')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getRefusalReasonTranslation(i18n, refusalReason)}
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Authorized Amount')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formattedAuthorizedAmount}
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Payment Method')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="flex items-center gap-2">
                <PaymentMethodIcon paymentType={paymentMethod} />
                {cardSummary ? (
                  <span>*{cardSummary}</span>
                ) : paymentMethod === 'ach' ? (
                  <span>ACH</span>
                ) : (
                  <span />
                )}
              </div>
            </dd>
          </div>
          {!!paymentMethodVariant && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">
                {i18n._('Payment Method Variant')}
              </dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {paymentMethodVariantTranslationMap.get(paymentMethodVariant) ??
                  capitalizeCustomAttribute(paymentMethodVariant)}
              </dd>
            </div>
          )}
          {declinedPaymentRow.terminalId && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Terminal Id')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {declinedPaymentRow.terminalId}
              </dd>
            </div>
          )}
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Created')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatLocaleDate(createdAt)}
            </dd>
          </div>
        </dl>
      </div>
    </SlideOverWithBrandedHeader>
  );
};
