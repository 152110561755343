import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {Trans} from '@lingui/macro';
import {Loading} from '@zentact/ui-tailwind';
import {useEffect} from 'react';

export const LogoutPage = () => {
  const {loginWithRedirect, isAuthenticated, logout} = useAuth0();
  const {tenant} = useStore();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!isAuthenticated) return;

    if (tenant?.logoutUri && searchParams.get('referrer') !== 'oidc') {
      window.location.href = tenant.logoutUri;
      return;
    }

    logout({logoutParams: {returnTo: `${window.location.origin}/logout`, federated: true}}).catch(
      console.error
    );
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <Loading />;
  }

  return (
    <section className="bg-white dark:bg-gray-900 ">
      <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
        <div>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">
            <Trans>You have been logged out.</Trans>
          </h1>
          <div className="flex items-center mt-6 gap-x-3">
            <button
              type="button"
              onClick={() => loginWithRedirect({appState: {returnTo: '/'}})}
              className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-600 dark:hover:bg-primary-500 shrink-0 sm:w-auto"
            >
              <Trans>Re-Login</Trans>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
