import {zodResolver} from '@hookform/resolvers/zod';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {AllMerchantAccountListItem} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {Auth0UserRole} from '@zentact/common';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../..';
import {useNotification} from '../../../../hooks';
import {SlideOverWithBrandedHeader} from '../../../../overlays';
import {UserForm, UserFormData, getUserFormSchema} from '../../user-form';

type InviteUserProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  organizationOrTenantName?: string;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  merchantAccountsOptions?: AllMerchantAccountListItem[];
};

export const InviteUser = ({
  isOpen,
  onSuccess,
  onCancel,
  organizationOrTenantName,
  trpc,
  mode,
  merchantAccountsOptions,
}: InviteUserProps) => {
  const {i18n} = useLingui();
  const form = useForm<UserFormData>({resolver: zodResolver(getUserFormSchema(i18n))});
  const {handleSubmit, setError} = form;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const inviteOrganizationUser = trpc.users.inviteOrganizationUser.useMutation({
    onSuccess: () => {
      showSuccessNotification(i18n._('User invite sent'), i18n._('User has been invited.'));
      onSuccess();
      form.reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      switch (errorCode) {
        case ErrorCode.USER_ALREADY_EXIST:
          setError('email', {
            type: 'manual',
            message: i18n._('User already exist'),
          });
          break;
        case ErrorCode.USER_BELONGS_TO_ANOTHER_ORGANIZATION:
          setError('email', {
            type: 'manual',
            message: i18n._('User already belongs to another organization'),
          });
          break;
        default:
          showErrorNotification(
            i18n._('Failed to invite user'),
            i18n._('Something went wrong. Please try again later.')
          );
      }
    },
  });

  const inviteTenantUserMutation = trpc.users.inviteTenantUser.useMutation({
    onSuccess: () => {
      showSuccessNotification(i18n._('User invite sent'), i18n._('User has been invited.'));
      onSuccess();
      form.reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      if (errorCode === ErrorCode.USER_ALREADY_EXIST) {
        setError('email', {
          type: 'manual',
          message: i18n._('User already exist'),
        });
      } else {
        showErrorNotification(
          i18n._('Failed to invite user'),
          i18n._('Something went wrong. Please try again later.')
        );
      }
    },
  });

  const onSubmit = useCallback(
    (data: UserFormData) => {
      if (mode === 'organizationUser') {
        inviteOrganizationUser.mutate({
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          role: data.role as Auth0UserRole.ORGANIZATION_ADMIN | Auth0UserRole.MERCHANT_ADMIN,
          merchantAccountIds: data.merchantAccountIds,
        });
      } else {
        inviteTenantUserMutation.mutate({
          email: data.email,
          name: `${data.firstName} ${data.lastName}`,
        });
      }
    },
    [inviteOrganizationUser, inviteTenantUserMutation]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Invite User')}
      text={i18n._('Add Users to your team to help you manage { name }', {
        name: organizationOrTenantName || 'your organization',
      })}
      closeHandler={onCancel}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={inviteOrganizationUser.isLoading || inviteTenantUserMutation.isLoading}
            >
              {i18n._('Send Invite')}
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancel}>
            {i18n._('Close')}
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserForm form={form} mode={mode} merchantAccountsOptions={merchantAccountsOptions} />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
