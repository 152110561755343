import {ArrowUpTrayIcon, CheckCircleIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {
  DEFAULT_IMAGE_UPLOAD_DIMENSIONS,
  ImageUploadDimensions,
  supportedExtensions,
} from '@zentact/common';
import {InputHTMLAttributes, forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Loading, useNotification} from '../..';
import {cn} from '../../utils';

type FileUploadProps = InputHTMLAttributes<HTMLInputElement> & {
  accept?: string;
  isLoading?: boolean;
  onSelectFile: (f: File) => void;
  localeText: {
    upload: string;
    dragndrop: string;
  };
  type?: 'file' | 'image';
  maxSizeMb: number;
  allowedExt?: string[];
  maxImageDimensions?: ImageUploadDimensions;
  isUploaded?: boolean;
};

const dimensionsIsValid = (file: File, maxImageDimensions: ImageUploadDimensions) => {
  const objectUrl = URL.createObjectURL(file);
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve(!(img.width > maxImageDimensions.width || img.height > maxImageDimensions.height));
    };
    img.onerror = () => resolve(false);
    img.src = objectUrl;
  });
};

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      onSelectFile,
      isLoading = false,
      localeText,
      type = 'file',
      maxSizeMb,
      allowedExt = supportedExtensions,
      maxImageDimensions = DEFAULT_IMAGE_UPLOAD_DIMENSIONS,
      isUploaded,
    },
    forwardedRef
  ) => {
    const {i18n} = useLingui();
    const {showErrorNotification} = useNotification();
    const [dragActive, setDragActive] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      const file = e?.dataTransfer?.files[0];
      handleSelectFile(file);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (ref.current) {
        ref.current.value = '';
      }
      handleSelectFile(file);
    };

    const handleSelectFile = async (file?: File) => {
      if (!file) return;

      if (file.size > megabytesToBytes(maxSizeMb)) {
        showErrorNotification(
          i18n._('The uploaded file is larger than {size}MB', {size: maxSizeMb})
        );
        return;
      }

      const fileExt = file.name.split('.').pop();
      const isSupported = allowedExt.includes(`.${fileExt}`);
      if (!isSupported) {
        showErrorNotification(i18n._('The uploaded file has an unsupported extension'));
        return;
      }
      if (type === 'image') {
        const validSize = await dimensionsIsValid(file, maxImageDimensions);
        if (!validSize) {
          showErrorNotification(i18n._('The uploaded file has wrong dimensions'));
          return;
        }
      }

      onSelectFile(file);
    };

    return (
      <div className="grid">
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: TODO */}
          <div
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onClick={() => ref.current?.click()}
            className={cn(
              'flex max-w-2xl cursor-pointer justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
              dragActive ? 'bg-primary-100' : 'bg-gray-100'
            )}
          >
            <div className="text-center">
              {isUploaded ? (
                <CheckCircleIcon
                  className="mx-auto h-14 w-14 text-primary-600"
                  aria-hidden="true"
                />
              ) : (
                <span className="inline-flex items-center justify-center w-12 h-12 bg-white rounded-full">
                  {isLoading ? (
                    <Loading mode="inline" size="medium" />
                  ) : (
                    <ArrowUpTrayIcon
                      className="w-6 h-6 mx-auto text-primary-600"
                      aria-hidden="true"
                    />
                  )}
                </span>
              )}
              <div className="flex flex-col mt-4 text-sm leading-6 text-gray-600 sm:flex-row">
                <label className="relative font-semibold rounded-md cursor-pointer pointer-events-none text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2">
                  <span>{localeText.upload}</span>
                  <input
                    disabled={isLoading}
                    ref={ref}
                    onChange={e => handleFileChange(e)}
                    type="file"
                    accept={allowedExt.join(',')}
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">{localeText.dragndrop}</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                {allowedExt
                  .join(', ')
                  .toUpperCase()
                  .replace(/, ([^,]*)$/, ' or $1')}
              </p>
              <p className="text-xs leading-5 text-gray-600">
                {i18n._('Max size {size}MB', {size: maxSizeMb})}
              </p>
              {type === 'image' && (
                <p className="text-xs leading-5 text-gray-600">
                  (max {maxImageDimensions.width} x {maxImageDimensions.height}px)
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const megabytesToBytes = (mb: number) => mb * 1024 * 1024;
