// mc -> mc
// mc_applepay -> applepay
// interac_card -> interac_card -- exception
export const getPrimaryPaymentMethodPart = (paymentMethod: string): string => {
  const exceptions = ['interac_card', 'directdebit_GB'];
  if (exceptions.includes(paymentMethod)) {
    return paymentMethod;
  }

  const split = paymentMethod.split('_');
  if (split.length > 1 && split[1] !== undefined) {
    return split[1];
  }

  return paymentMethod;
};
