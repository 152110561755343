import {zodResolver} from '@hookform/resolvers/zod';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {AllMerchantAccountListItem} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  InvitedOrganizationUserItem,
  InvitedTenantUserItem,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {Auth0UserRole, ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../..';
import {useNotification} from '../../../../hooks';
import {SlideOverWithBrandedHeader} from '../../../../overlays';
import {UserForm, UserFormData, getUserFormSchema} from '../../user-form';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  userRow: InvitedOrganizationUserItem | InvitedTenantUserItem;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  merchantAccountsOptions?: AllMerchantAccountListItem[];
};

const parseName = (fullName: string) => {
  const nameParts: string[] = fullName.split(' ');
  const firstName = nameParts[0];
  let lastName = '';

  if (nameParts.length > 1) {
    lastName = nameParts.slice(1).join(' ');
  }

  return {firstName, lastName};
};

export const EditInvitedUserPanel = ({
  userRow,
  onSuccess,
  onClose,
  trpc,
  mode,
  merchantAccountsOptions,
}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isClosing, setIsClosing] = useState<{success: boolean} | null>(null);

  const {i18n} = useLingui();

  const editInvitationForm = useForm<UserFormData>({
    resolver: zodResolver(getUserFormSchema(i18n)),
    defaultValues: {
      email: userRow.email,
      role: userRow.roles?.[0] ?? Auth0UserRole.ORGANIZATION_ADMIN,
      ...(userRow.name ? parseName(userRow.name) : {}),
    },
  });
  const organizationUserMerchantsVisibility =
    trpc.users.getOrganizationUserMerchantsVisibility.useQuery(userRow.zentactOrganizationUserId, {
      enabled: mode === 'organizationUser',
      onSuccess: data => {
        if (data.merchantAccountIds) {
          editInvitationForm.setValue('merchantAccountIds', data.merchantAccountIds);
        }
      },
    });

  const {handleSubmit, setError} = editInvitationForm;

  const inviteUser = trpc.users.inviteOrganizationUser.useMutation({
    onSuccess: () => {
      showSuccessNotification(i18n._('User invite sent'), i18n._('User has been invited.'));
      setIsClosing({success: true});
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      switch (errorCode) {
        case ErrorCode.USER_ALREADY_EXIST:
          setError('email', {
            type: 'manual',
            message: i18n._('User already exist'),
          });
          break;
        case ErrorCode.USER_BELONGS_TO_ANOTHER_ORGANIZATION:
          setError('email', {
            type: 'manual',
            message: i18n._('User already belongs to another organization'),
          });
          break;
        default:
          showErrorNotification(
            i18n._('Failed to invite user'),
            i18n._('Something went wrong. Please try again later.')
          );
      }
    },
  });

  const editTenantUserMutation = trpc.users.editTenantUserAccount.useMutation({
    onSuccess: () => {
      setIsClosing({success: true});
      showSuccessNotification(
        i18n._('User information updated!'),
        i18n._('You have successfully updated user information.')
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const onSubmit = useCallback(
    (data: UserFormData) => {
      if (mode === 'organizationUser') {
        inviteUser.mutate({
          existingInvitationId: userRow.id,
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          role: data.role as Auth0UserRole.ORGANIZATION_ADMIN | Auth0UserRole.MERCHANT_ADMIN,
          merchantAccountIds: data.merchantAccountIds,
        });
      } else {
        editTenantUserMutation.mutate({
          userId: userRow.id,
          firstName: data.firstName,
          lastName: data.lastName,
        });
      }
    },
    [inviteUser, editTenantUserMutation]
  );

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={!isClosing}
        title={i18n._(
          mode === 'organizationUser' ? 'Edit and Resend Invitation' : 'Edit User Information'
        )}
        text={i18n._(
          mode === 'organizationUser'
            ? 'Edit the invitation and resend it to the user.'
            : 'Edit user personal information.'
        )}
        closeHandler={() => {
          if (isClosing?.success) {
            onSuccess();
          }
          onClose();
        }}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  mode === 'organizationUser' && organizationUserMerchantsVisibility.isLoading
                }
                isLoading={inviteUser.isLoading || editTenantUserMutation.isLoading}
              >
                {i18n._(mode === 'organizationUser' ? 'Send Invite' : 'Edit User')}
              </Button>
            </div>
            <Button
              variant="secondary"
              size="lg"
              className="w-fit"
              onClick={() => setIsClosing({success: false})}
            >
              {i18n._('Close')}
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            form={editInvitationForm}
            mode={mode}
            emailReadonly={mode === 'tenantUser'}
            merchantAccountsOptions={merchantAccountsOptions}
          />
        </form>
      </SlideOverWithBrandedHeader>
    </>
  );
};
