import {I18n} from '@lingui/core';

export const getDefenseReasonCode = (defenseReasonCode: string, i18n: I18n) => {
  const defenseReasonCodeMap: {[key: string]: string} = {
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AccountTakeover: i18n._('The transaction resulted from an account takeover.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AdditionalInformation: i18n._('You can supply additional information.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AirlineFlightProvided: i18n._(
      'The cardholder has or could have used the provided airline ticket.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AirlineRFIresponse: i18n._('The response to a Request for Information (RFI) for airlines.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AirlineCompellingEvidence: i18n._('You can supply evidence to support the dispute.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ATMtransaction: i18n._('The transaction was an ATM transaction.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AuthorisedTransaction: i18n._(
      'The issuer authorised the transaction amount, or an amount greater than the transaction amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    AutomaticRFIresponse: i18n._('The automatic response to a Request for Information (RFI).'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CancellationOrReturns: i18n._(
      'The cardholder never returned the merchandise or did not accept the cancellation.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CancellationTermsFailed: i18n._(
      'The cardholder failed to meet the cancellation terms of the contract.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardNotPresentRFIresponse: i18n._(
      'The response to a Request for Information (RFI) for a Card Not Present (CNP) transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardPresentFraud: i18n._('Card present fraud.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardPresentRFIresponse: i18n._(
      'The response to a Request for Information (RFI) for a transaction where the card is present.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardholderNotReplied: i18n._('The cardholder was notified but did not reply.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardholderParticipated: i18n._('The cardholder participated in the transaction.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardholderRenewedMembership: i18n._('The cardholder renewed membership after cancellation.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CardNotOnExceptionFile: i18n._('The account number was not listed on the exception file.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CarRentalRFIresponse: i18n._(
      'The response to a Request for Information (RFI) for car rentals.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ChargebackRemedied: i18n._('The chargeback was remedied, evidence provided in documents.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ChipLiabilityShift: i18n._(
      'The transaction falls under the Chip Liability Shift and occurred on a non-EMV chip card.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CompellingEvidence: i18n._('You can supply evidence to support the dispute.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectedMcc: i18n._(
      'The Merchant Category Code (MCC) was incorrect in the first presentment. With the correct MCC, authorization is not required.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectedMerchantDetails: i18n._(
      'The merchant location or description was incorrect in the first presentment. With the corrected details, authorization is not required.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectTransactionAmount: i18n._(
      'The Transaction Identification Document (TID) shows that the amount was processed correctly.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectTransactionAmountSms: i18n._(
      'The Transaction Identification Document (TID) shows that the amount was processed correctly.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectTransactionCurrency: i18n._('The correct transaction currency was provided.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CorrectTransactionDate: i18n._('The correct transaction date was provided.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CreditOrCancellationPolicyProperlyDisclosed: i18n._(
      'You can document that the credit or cancellation policy was properly disclosed to the cardholder at the point of interaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    CVC2ValidationProgram: i18n._(
      'The CVC 2 Validation Program (US region only) applies to this transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    DeficiencyCorrected: i18n._('The deficiency was corrected.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    DisputedSurcharge: i18n._(
      'The cardholder disputed only a part of the transaction amount, and the issuer must prorate the surcharge amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    DisputedSurchargeIncorrectProratedCalculation: i18n._(
      'The issuer incorrectly calculated the surcharge amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    DuplicateChargeback: i18n._('Duplicate chargeback.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    EmergencyPaymentAuthorizationService: i18n._(
      'The transactions was authorized through the Emergency Payment Authorization Service.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    EMVLiabilityShift: i18n._('There was an EMV liability shift.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    FaceToFace: i18n._(
      'There is no conflicting information in the authorization and clearing messages.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    FaceToFaceConflictingMessages: i18n._(
      'There is conflicting information in the authorization and clearing messages.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    GoodsNotReturned: i18n._('The cardholder did not return the goods to you.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    GoodsOrServicesProvided: i18n._(
      'The goods or services were delivered, or there was a delay in delivery.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    GoodsRepairedOrReplaced: i18n._('The goods were repaired or replaced.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    GoodsWereAsDescribed: i18n._('The goods were delivered as described.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    IdentifiedAddendum: i18n._("The addendum transaction is the cardholder's responsibility."),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    IllegibleDocumentationReceived: i18n._(
      'The provided documentation cannot be read, and it cannot be established that the chargeback is valid.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    IncorrectTransactionDate: i18n._(
      'The transaction was authorized, but was processed with an incorrect transaction date.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    IncreasedTransactionAmount: i18n._('The increased debit to the account is valid.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    InvalidARD: i18n._(
      'The Acquirer Reference Data (ARD) on the chargeback is not valid; documentation was provided or not required.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    InvalidCancellationCode: i18n._('The cancellation code was not valid.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    InvalidChargeback: i18n._(
      'The issuers chargeback was not valid, for example non-supporting documentation.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    InvalidReasonCodeForMCC: i18n._(
      'The reason code is not valid for the Merchant Category Code (MCC).'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    LiabilityShiftFullUCAF: i18n._('There was a global liability shift.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    LiabilityShiftMerchantUCAF: i18n._('There was a merchant-only liability shift.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    MerchandiseNotCounterfeit: i18n._('The merchandise was not counterfeit.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    MerchandiseReceived: i18n._('The shopper received the merchandise.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    MissingInformation: i18n._(
      'Provide missing information, or provide information that can be read.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    MultipleAuthorisations: i18n._(
      'Multiple authorizations were obtained for this transaction, or the authorization amount was greater than or equal to the captured amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NoARDprovidedInMessageText: i18n._(
      'The issuer did not provide the Acquirer Reference Data (ARD) of the second transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NoAuthorisationRequiredManualImprint: i18n._(
      'The card data was captured through a manual imprint.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NoAuthorisationRequiredNotX2X: i18n._('The service code on the card was not X2X.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NoAuthorisationRequiredOffline: i18n._(
      'The transaction was processed on an offline point-of-sale (POS) device, and authorization was not required.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NonReceiptOfRequiredDocuments: i18n._(
      'The issuer did not provide the required documents. This reason can only be used 9 to 45 days after you received the notification of chargeback.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NoShowTransaction: i18n._('The transaction was the result of Guaranteed Reservation Service.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NotCancelled: i18n._('The cardholder did not cancel the transaction.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NotNotified: i18n._('You or the acquirer were not notified of the cancellation of membership.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NotRecurring: i18n._('The transaction was not a recurring transaction.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NotPaidByOtherMeans: i18n._('Additional information.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    NotReportedToSafe: i18n._('The issuer did not properly report the transaction to SAFE.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PartialAuthorisation: i18n._(
      'The captured amount was partially authorized. The amount that was charged back is greater than the amount that was not authorized.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PaymentByOtherMeans: i18n._(
      'You can provide an explanation that supports the validity of the transaction charged.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PayPassTransaction: i18n._(
      'The transaction was a PayPass transaction below chargeback protection amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PINTransaction: i18n._('The PIN was entered for the transaction.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PosDeviceFailure: i18n._(
      'The transaction was under the bottom limit and there was a POS failure at the time of purchase.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PresentmentWithinTimeLimit: i18n._(
      'The transaction was presented within the given time limit.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PreviousTransactionNotChargebacked: i18n._('The previous transaction was not charged back.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PreviousTransactionNotChargebackedARNprovided: i18n._(
      'The previous transaction was not charged back, and an Acquirer Reference Number (ARN) is present to match.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ProofOfCardPresenceAndSignatureWithTerminalReceipt: i18n._(
      'The card was present and a signed terminal receipt is available.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ProofOfCardPresenceAndSignatureWithoutTerminalReceipt: i18n._(
      'The card was present and documentation is available to prove this.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ProofOfCardPresenceAndSignatureChipNoPIN: i18n._('The card was present and chip was used.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ProofOfCardPresenceAndSignatureLossTheftOrDamage: i18n._(
      'The card was present and a signed terminal receipt is available. The transaction involves loss, theft, or damage. You should provide the receipt that contains the items that were additionally billed.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ProofOfCardPresenceAndSignatureNotMasterCardWorldWideNetwork: i18n._(
      'The card was present and a signature exists for a transaction that was not processed via the MasterCard Worldwide Network.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PurchaseProperlyPosted: i18n._(
      'The issuer claimed that the transaction was not for an installment billing, and the transaction was properly identified as an installment transaction in the authorization request.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    QPSTransaction: i18n._(
      'The transaction was a properly identified QPS transaction of an amount equal to or less than the chargeback protection amount.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    RecurringTransactionsCompellingEvidence: i18n._(
      'The transaction was a non face-to-face recurring transaction.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    RecurringTransactionsCompellingMerchantEvidence: i18n._(
      'The transaction was a non face-to-face recurring transaction, of which you have compelling evidence.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    RetrievalRequestFulfilled: i18n._('The retrieval request has been fulfilled.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    SaleTermsNotMisrepresented: i18n._('The terms of sale were not misrepresented.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ScanningError: i18n._(
      'The provided documentation is incomplete or is about a wrong transaction probably due to a scanning error.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ServicesProvided: i18n._('You have provided services.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ServicesProvidedAfterCancellation: i18n._(
      'You have provided services after the cancellation data used by the cardholder.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ShippedToAVS: i18n._('Address Verification Service (AVS).'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ShippedToAVSAdditionalInformation: i18n._(
      'Address Verification Service (AVS) additional information.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    SupplyDefenseMaterial: i18n._('You can supply defense material to defend the chargeback.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    SuspiciousTransaction: i18n._('The issuer was advised that the transaction was suspicious.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TechnicalFallback: i18n._('The transaction was the result of a technical fallback.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TelecomFailure: i18n._(
      'The transaction was under the bottom limit, and there was a telecom failure at the time of purchase.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    Telex: i18n._(
      'The acquirer proves that the card acceptor received authorization for the transaction amount or greater as a result of a telex to the issuer.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TimeshareTransactionCancellationPeriodExpired: i18n._(
      'The transaction was not cancelled within 14 calendar days of the contract date or receipt date of the contract or related documents.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TransactionNotPOS: i18n._('The transaction was not an in-person payment.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TransactionAuthorisedOnline: i18n._('The transaction was authorized online.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoDifferentMagStripeTelCalls: i18n._(
      'Proof that the time of call for each Magnetic-Stripe Telephone call differs.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoDifferentTIDs: i18n._(
      'Two different Transaction Identification Documents (TIDs) with the same card number are present.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoDifferentTIDsATM: i18n._(
      'Two different Transaction Identification Documents (TIDs) with the same card number are present (ATM).'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoDifferentTIDsPOS: i18n._(
      'Two different Transaction Identification Documents (TIDs) with the same card number are present (POS).'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoPINTransactions: i18n._('A PIN was present for one or both transactions.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    TwoPriorFraudChargebacks: i18n._('There were two or more previous fraud-related chargebacks.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    UnreasonableAmount: i18n._(
      'The cardholder agreed to an amount range as reasonable, and the transaction amount did not exceed this amount range.'
    ),
  };

  return defenseReasonCodeMap[defenseReasonCode];
};
