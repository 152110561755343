type SurchargeConfig = {
  fixedFee: number; // minor units
  percentageFee: number; // 1%
};
export const calculateAmountWithSurcharge = (
  originalAmountInMinorUnits: number,
  surchargeConfig: SurchargeConfig
) => {
  // Rounds to the nearest cent

  const percentageFee = Math.round(
    (originalAmountInMinorUnits * surchargeConfig.percentageFee) / 100
  );
  const surchargeAmount = percentageFee + surchargeConfig.fixedFee;

  return {
    authorizedAmount: originalAmountInMinorUnits + surchargeAmount,
    surchargeAmount,
    surchargeConfiguration: surchargeConfig,
  };
};
