import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {formatUrlQueryParams, savedPaymentMethodsSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  SavedPaymentMethodFilters,
  SavedPaymentMethodsList,
  SelectInputFilter,
  SlideOverWithBrandedHeader,
  TableSort,
  TableSortValue,
  Typography,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {AddNewSavedPaymentMethod} from './add-new-saved-payment-method';
const getBreadCrumbs = () => [
  {name: t`Payments`, href: RoutePath.TAKE_PAYMENT, current: false},
  {name: t`Saved Payment Methods`, href: RoutePath.SAVED_PAYMENT_METHODS, current: true},
];

export const SavedPaymentMethods = () => {
  const [isAddNewSidePanelOpen, setAddNewSidePanelOpen] = useState(false);
  const [paymentMethodRow, setPaymentMethodRow] = useState<ShopperListItemOutput | null>(null);
  const {i18n} = useLingui();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const {merchantAccounts, tenant} = useStore();
  const navigate = useNavigate();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(savedPaymentMethodsSchema);

  const name = typedSearchParams?.name;
  const merchantShopperId = typedSearchParams?.merchantShopperId;
  const email = typedSearchParams?.email;
  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'updatedAt', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 20});

  const paymentMethodsFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    where: {
      ...(name && {name}),
      ...(merchantShopperId && {merchantShopperId}),
      ...(email && {email}),
      ...(selectedMerchantAccount && {
        merchantAccountId: selectedMerchantAccount,
      }),
    },
  };
  const shopperList = trpc.savedPaymentMethod.shopperList.useQuery(
    {
      ...paymentMethodsFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
      onSuccess(data) {
        if (!paymentMethodRow) {
          return;
        }

        const activePaymentMethod = data.rows.find(row => row.id === paymentMethodRow.id);

        if (activePaymentMethod) {
          setPaymentMethodRow(activePaymentMethod);
        }
      },
    }
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const handleOpenDetails = useCallback(
    (row: ShopperListItemOutput) => {
      navigate(
        generatePath(RoutePath.SAVED_PAYMENT_METHODS_DETAILS, {
          merchantAccountId: row.merchantAccount.id,
          merchantShopperId: encodeURIComponent(row.merchantShopperId),
        })
      );
    },
    [navigate]
  );
  const handleNavigateToTransactions = useCallback(
    (row: ShopperListItemOutput) => {
      navigate(`${RoutePath.PAYMENTS}?${formatUrlQueryParams({shopperId: row.merchantShopperId})}`);
    },
    [navigate]
  );

  const nextSort: Record<string, TableSort | null> = {
    asc: null,
    desc: 'asc',
    null: 'desc',
  };

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Saved Payment Methods</Trans>
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          className="w-fit max-sm:w-full"
          onClick={() => setAddNewSidePanelOpen(true)}
          disabled={!merchantAccounts || merchantAccounts?.length === 0}
        >
          <Trans>Add New</Trans>
        </Button>
      </div>
      <div className="mt-4 overflow-x-auto">
        <div className="mb-4 text-sm leading-6 text-gray-500">
          <Trans>
            When customers authorize the storage of their billing information, their saved billing
            options will appear here. Merchants can use these for recurring monthly charges or
            one-time transactions.
          </Trans>
          <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
            <div className="w-36">
              <SelectInputFilter
                options={[
                  {
                    id: 'updatedAt',
                    label: i18n._('Last Updated'),
                  },
                ]}
                sortValue={sort}
                onChange={columnId => {
                  setSort({
                    columnId,
                    value: nextSort[String(sort.value)] as TableSort | null,
                  });
                }}
                placeholder={i18n._('Sort')}
              />
            </div>
            <Button
              type="button"
              size="sm"
              onClick={() => setIsFiltersOpen(true)}
              className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
            >
              <Trans>Filter</Trans>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Button>
            <div className="hidden lg:block">
              <SavedPaymentMethodFilters
                typedSearchParams={typedSearchParams}
                setTypedSearchParams={setTypedSearchParams}
                name={name}
                merchantShopperId={merchantShopperId}
                email={email}
                selectedMerchantAccount={selectedMerchantAccount}
                merchantAccounts={merchantAccounts}
              />
            </div>
            <SlideOverWithBrandedHeader
              isOpen={isFiltersOpen}
              title={'Filters'}
              closeHandler={() => setIsFiltersOpen(false)}
            >
              <SavedPaymentMethodFilters
                typedSearchParams={typedSearchParams}
                setTypedSearchParams={setTypedSearchParams}
                name={name}
                merchantShopperId={merchantShopperId}
                email={email}
                selectedMerchantAccount={selectedMerchantAccount}
                merchantAccounts={merchantAccounts}
              />
            </SlideOverWithBrandedHeader>
          </div>
        </div>
        <SavedPaymentMethodsList
          paymentMethodsList={shopperList.data}
          openDetails={handleOpenDetails}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          trpc={trpc}
          refetch={shopperList.refetch}
          navigateToTransactions={handleNavigateToTransactions}
        />
      </div>
      <AddNewSavedPaymentMethod
        isOpen={isAddNewSidePanelOpen}
        onCancel={() => setAddNewSidePanelOpen(false)}
        refetch={shopperList.refetch}
        trpc={trpc}
        expectedOrigin={tenant?.portalUrl}
        setPaymentMethodRow={setPaymentMethodRow}
      />
    </div>
  );
};
