import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {AllMerchantAccountListItem} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {Auth0UserRole} from '@zentact/common';
import {useEffect} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {z} from 'zod';
import {InputCheckboxList, InputSelect, InputText} from '../../../forms';
import {Label} from '../../label';
import {Typography} from '../../typography';
import {ValidationError} from '../../validation-error';

export const getOrganizationSelectRoleOptions = (i18n: I18n) => [
  {
    id: Auth0UserRole.ORGANIZATION_ADMIN as string,
    label: i18n._('Organization Admin'),
  },
  {
    id: Auth0UserRole.MERCHANT_ADMIN as string,
    label: i18n._('Merchant Admin'),
  },
];
export const getTenantSelectRoleOptions = (i18n: I18n) => [
  {
    id: Auth0UserRole.TENANT_ADMIN as string,
    label: i18n._('Admin'),
  },
];

export const getUserFormSchema = (i18n: I18n) =>
  z
    .object({
      email: z
        .string()
        .min(1, i18n._('Email is required'))
        .max(70, i18n._(`The length of the email address can't exceed 70 characters`))
        .email(i18n._('Email is not valid')),
      firstName: z
        .string()
        .min(2, i18n._('First Name must contain at least 2 character(s)'))
        .max(18, i18n._('First Name must contain at most 18 character(s)')),
      lastName: z
        .string()
        .min(2, i18n._('Last Name must contain at least 2 character(s)'))
        .max(18, i18n._('Last Name must contain at most 18 character(s)')),
      role: z
        .enum(
          [
            Auth0UserRole.MERCHANT_ADMIN,
            Auth0UserRole.ORGANIZATION_ADMIN,
            Auth0UserRole.TENANT_ADMIN,
          ],
          {
            // biome-ignore lint/style/useNamingConvention: Zod Convention
            required_error: i18n._('Role is required'),
          }
        )
        .or(z.string().min(1, {message: i18n._('Role is required')})),

      // OrganizationUser only
      merchantAccountIds: z.array(z.string()).optional(),
    })
    .superRefine((data, ctx) => {
      if (
        data.role === Auth0UserRole.MERCHANT_ADMIN &&
        (!data.merchantAccountIds || data.merchantAccountIds.length === 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n._('At least 1 merchant account should be selected'),
          path: ['merchantAccountIds'],
        });
      }
      return z.NEVER;
    });

export type UserFormData = z.infer<ReturnType<typeof getUserFormSchema>>;

export type UserFormProps = {
  form: UseFormReturn<UserFormData>;
  initialValues?: UserFormData;
  emailReadonly?: boolean;
  mode: 'organizationUser' | 'tenantUser';
  merchantAccountsOptions?: AllMerchantAccountListItem[];
};

export const UserForm = ({
  form,
  initialValues,
  emailReadonly,
  mode,
  merchantAccountsOptions,
}: UserFormProps) => {
  const {
    register,
    formState: {errors},
    control,
  } = form;

  useEffect(() => {
    if (initialValues) {
      form.setValue('email', initialValues.email);
      form.setValue('firstName', initialValues.firstName);
      form.setValue('lastName', initialValues.lastName);
      form.setValue('role', initialValues.role);
    }
  }, [initialValues]);

  const {i18n} = useLingui();
  const role = form.watch('role');

  return (
    <div className="flex flex-col gap-3">
      <Label text={i18n._('Email')}>
        <InputText
          {...register('email', {required: true})}
          placeholder="example@mail.com"
          readOnly={emailReadonly}
          disabled={emailReadonly}
          className={emailReadonly ? 'bg-gray-100 text-gray-400' : ''}
        />
        <ValidationError isVisible={Boolean(errors.email)}>{errors.email?.message}</ValidationError>
      </Label>
      <Label text={i18n._('First Name')}>
        <InputText {...register('firstName', {required: true})} />
        <ValidationError isVisible={Boolean(errors.firstName)}>
          {errors.firstName?.message}
        </ValidationError>
      </Label>
      <Label text={i18n._('Last Name')}>
        <InputText {...register('lastName', {required: true})} />
        <ValidationError isVisible={Boolean(errors.lastName)}>
          {errors.lastName?.message}
        </ValidationError>
      </Label>
      <Controller
        control={control}
        defaultValue=""
        name="role"
        render={({field}) => (
          <>
            <InputSelect
              label={i18n._('Role')}
              value={field.value}
              options={
                mode === 'organizationUser'
                  ? getOrganizationSelectRoleOptions(i18n)
                  : getTenantSelectRoleOptions(i18n)
              }
              onChange={value => {
                field.onChange(value);
              }}
              placeholder={i18n._('Role')}
            />
            <ValidationError isVisible={Boolean(errors.role)}>
              {errors.role?.message}
            </ValidationError>
            <p className="mt-1 text-xs leading-5 text-gray-500">
              {field.value === Auth0UserRole.ORGANIZATION_ADMIN &&
                i18n._('An Organization Admin can perform all actions for the organization')}
              {field.value === Auth0UserRole.MERCHANT_ADMIN && (
                <>
                  {i18n._('A Merchant Admin can perform all actions for the merchant.')}
                  <br />
                  {i18n._('A Merchant Admin can only see the merchants they are assigned to.')}
                  <br />
                  {i18n._('A Merchant Admin can not manage users.')}
                </>
              )}

              {field.value === Auth0UserRole.TENANT_ADMIN &&
                i18n._("An Admin can perform all actions for the tenant and it's organizations")}
            </p>
          </>
        )}
      />

      {mode === 'organizationUser' &&
        role === Auth0UserRole.MERCHANT_ADMIN &&
        merchantAccountsOptions && (
          <div>
            <Typography variant="header-form-section" className="mb-3 font-normal">
              {i18n._('Merchant Visibility')}
            </Typography>

            <div>
              <Controller
                name="merchantAccountIds"
                control={control}
                render={({field}) => (
                  <InputCheckboxList
                    options={merchantAccountsOptions}
                    getLabel={merchant => merchant.businessName}
                    getOptionId={merchant => merchant.id}
                    value={field.value}
                    onChange={field.onChange}
                    filterable
                    searchPlaceholder={i18n._('Merchant Account')}
                    className="mb-2"
                  >
                    <ValidationError isVisible={Boolean(errors.merchantAccountIds)}>
                      {errors.merchantAccountIds?.message}
                    </ValidationError>
                  </InputCheckboxList>
                )}
              />
            </div>
          </div>
        )}
    </div>
  );
};
