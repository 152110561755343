import type {PaymentRequest} from '@adyen/api-library/lib/src/typings/payment/models';
import {zodResolver} from '@hookform/resolvers/zod';
import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {
  CurrencyCode,
  LocaleCode,
  formatAmount,
  fromMinorUnits,
  toMinorUnits,
} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  InputText,
  Label,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

type Options = {
  i18n: I18n;
  minAmount: number;
  maxAmount: number;
  locale: LocaleCode;
  currency: CurrencyCode;
};
export const getPreauthorizeWithPaymentMethodDialogSchema = ({
  i18n,
  minAmount,
  maxAmount,
  locale,
  currency,
}: Options) =>
  z.object({
    preauthorizeAmount: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Amount must be a multiple of 0.01'))
      .positive()
      .min(
        fromMinorUnits(minAmount, currency),
        i18n._('Amount must be greater than or equal to ${amount}', {
          amount: formatAmount(minAmount, locale, currency),
        })
      )
      .max(
        fromMinorUnits(maxAmount, currency),
        i18n._('Amount must be less than or equal to {amount}', {
          amount: formatAmount(maxAmount, locale, currency),
        })
      ),
  });
type PreauthorizeWithPaymentMethodDialogData = z.infer<
  ReturnType<typeof getPreauthorizeWithPaymentMethodDialogSchema>
>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  minAmount: number;
  maxAmount: number;
  locale: LocaleCode;
  currency: CurrencyCode;
  shopper: ShopperListItemOutput;
  paymentMethodIdToken: string;
  triggerRefetch: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const PreauthorizeWithPaymentMethodDialog = ({
  isOpen,
  onClose,
  minAmount,
  maxAmount,
  locale,
  currency,
  triggerRefetch,
  trpc,
  shopper,
  paymentMethodIdToken,
}: Props) => {
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {
    handleSubmit,
    formState: {errors},
    register,
    reset,
  } = useForm<PreauthorizeWithPaymentMethodDialogData>({
    resolver: zodResolver(
      getPreauthorizeWithPaymentMethodDialogSchema({i18n, minAmount, maxAmount, locale, currency})
    ),
  });

  const paymentWithShopperTokenMutation = trpc.payment.paymentWithShopperToken.useMutation();

  const onPreauthorize = useCallback(
    ({preauthorizeAmount}: PreauthorizeWithPaymentMethodDialogData) => {
      const amount = toMinorUnits(preauthorizeAmount, currency);
      paymentWithShopperTokenMutation.mutate(
        {
          merchantAccountId: shopper.merchantAccount.id,
          amount,
          currency,
          shopperId: shopper.merchantShopperId,
          paymentMethodIdToken,
          authorizationType: 'PRE_AUTH_CANCELLED',
          recurringProcessingModel:
            'UnscheduledCardOnFile' as PaymentRequest.RecurringProcessingModelEnum.UnscheduledCardOnFile,
        },
        {
          onSuccess: () => {
            reset();
            onClose();
            triggerRefetch();
            showSuccessNotification(i18n._('Preauthorization is in progress!'));
          },
          onError: error => {
            showErrorNotification(i18n._('Preauthorization Failed'), error.message);
          },
        }
      );
    },
    []
  );

  return (
    <AlertOverlayWithConfirmation
      open={isOpen}
      setOpen={() => onClose()}
      handleAction={handleSubmit(onPreauthorize)}
      localeText={{
        title: i18n._('Preauthorize'),
        description: `Preauthorization allows you to verify if a card can cover the charge without completing the transaction. This confirms the card is currently valid for the specified amount but doesn't guarantee future transactions. Card balance and other factors will affect whether future transactions will succeed later. Preauthorized funds are automatically released after 5 minutes.`,
        confirm: i18n._('Preauthorize'),
        cancel: i18n._('Cancel'),
      }}
      mode="error"
      buttonMode="success"
      loading={paymentWithShopperTokenMutation.isLoading}
      disableConfirmButton={false}
    >
      <form onSubmit={handleSubmit(onPreauthorize)}>
        <div className="mx-4 mb-4 sm:mx-20 sm:w-96">
          <Label
            text={i18n._('Amount {minAmount}-{maxAmount}', {
              minAmount: formatAmount(minAmount, locale, currency),
              maxAmount: formatAmount(maxAmount, locale, currency),
            })}
          >
            <InputText
              className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              type={'number'}
              {...register('preauthorizeAmount', {
                required: true,
                valueAsNumber: true,
              })}
              hasError={Boolean(errors.preauthorizeAmount)}
            />
            <ValidationError isVisible={Boolean(errors.preauthorizeAmount)}>
              {errors.preauthorizeAmount?.message}
            </ValidationError>
          </Label>
        </div>
      </form>
    </AlertOverlayWithConfirmation>
  );
};
