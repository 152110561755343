import {trpc} from '@/api/trpcClient';

import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {
  Button,
  InputCheckbox,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  AddMerchantAccountForm,
  MerchantAccountFormData,
  getMerchantAccountFormSchema,
} from '../add-merchant-account-form';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

export const SendMerchantRegistrationInvite = ({isOpen, onCancel, onSuccess}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const form = useForm<MerchantAccountFormData>({
    resolver: zodResolver(getMerchantAccountFormSchema()),
  });
  const {handleSubmit, setError} = form;
  const addMerchant = trpc.merchantAccount.inviteMerchantToMyOrganization.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Successfully sent invitation to ${form.getValues('email')}`);
      onSuccess();
      form.reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      switch (errorCode) {
        case ErrorCode.MERCHANT_REGISTRATION_WITH_EMAIL_ALREADY_EXISTS:
          setError('email', {
            type: 'manual',
            message: t`Another registration with the same email already exists`,
          });
          break;
        case ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS:
          setError('name', {
            type: 'manual',
            message: t`Merchant registration with the same business name already exists`,
          });
          break;
        case ErrorCode.USER_BELONGS_TO_ANOTHER_ORGANIZATION:
          setError('email', {
            type: 'manual',
            message: t`User already belongs to another organization`,
          });
          break;
        default:
          showErrorNotification(
            t`Failed to add new merchant account`,
            t`Something went wrong. Please try again later.`
          );
      }
    },
  });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [actionLoading, setActionLoading] = useState(false);

  const onSubmit = useCallback(async (data: MerchantAccountFormData) => {
    if (buttonRef.current?.disabled) return;
    if (buttonRef.current) {
      buttonRef.current.disabled = true;
    }
    setActionLoading(true);
    await addMerchant.mutateAsync({
      merchant: {businessName: data.name},
      inviteeEmail: data.email,
    });
    setActionLoading(false);
  }, []);

  const onCancelClick = useCallback(
    (_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, isCancelClick?: boolean) => {
      onCancel();
      if (isCancelClick) {
        form.reset();
      }
    },
    []
  );

  const {organization} = useStore();

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Invite Merchant`}
      text={t`This process will invite another merchant to your organziation. Make sure the contact is an authorized signer on the account.`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={actionLoading}
              ref={buttonRef}
            >
              <Trans>Send Invite</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddMerchantAccountForm form={form}>
          <>
            <InputCheckbox checked={true}>
              <Trans>Create Admin Account for {organization?.name}</Trans>
            </InputCheckbox>
            <p className="text-xs text-gray-600">
              {t`Note: The user invited above will gain access to all merchant accounts.`}
            </p>
          </>
        </AddMerchantAccountForm>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
