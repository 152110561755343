import {XMarkIcon} from '@heroicons/react/20/solid';
import {CheckCircleIcon, ExclamationCircleIcon, XCircleIcon} from '@heroicons/react/24/outline';
import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import {forwardRef, useCallback} from 'react';

interface NotificationProps extends CustomContentProps {
  title: string;
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(({id, ...props}, ref) => {
  const {closeSnackbar} = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  return (
    <SnackbarContent
      ref={ref}
      aria-live="assertive"
      className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div className="z-500 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {props.variant === 'success' && (
              <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            )}
            {props.variant === 'error' && (
              <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
            )}
            {props.variant === 'info' && (
              <ExclamationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
            )}
          </div>
          <div className="ml-3 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{props.title}</p>
            <p className="mt-1 text-sm text-gray-500">{props.message}</p>
          </div>
          <div className="ml-4 mt-0.5 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleDismiss}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </SnackbarContent>
  );
});
