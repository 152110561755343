import {LocaleCode} from '../constants/enums';

const paymentSourceNameMap: {[key: string]: {[K in LocaleCode]: string}} = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  TERMINAL: {
    [LocaleCode.en_US]: 'Terminal',
    [LocaleCode.ps_DO]: 'Terminal',
  },
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ECOMMERCE: {
    [LocaleCode.en_US]: 'Ecommerce',
    [LocaleCode.ps_DO]: 'Ecommerce',
  },
  // biome-ignore lint/style/useNamingConvention: <explanation>
  SAVED_PAYMENT_METHOD: {
    [LocaleCode.en_US]: 'Saved Payment Method',
    [LocaleCode.ps_DO]: 'Saved Payment Method',
  },
};

export const getPaymentSourceName = (
  paymentSource: string,
  locale: LocaleCode = LocaleCode.en_US
) => {
  const localizedName = paymentSourceNameMap[paymentSource]?.[locale];
  return localizedName || paymentSource;
};
