import {
  BuildingStorefrontIcon,
  CalculatorIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  PresentationChartLineIcon,
  TableCellsIcon,
  UserIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import {t} from '@lingui/macro';
import {NavigationItem} from '@zentact/ui-tailwind';

export enum RoutePath {
  DASHBOARD = '/dashboard',
  TRANSACTIONS = '/transactions',
  PAYMENTS = '/transactions/payments',
  DECLINED_PAYMENTS = '/transactions/declined-payments',
  PAYMENT_DETAILS = '/transactions/payments/:pspReferenceId',
  DISPUTES = '/transactions/disputes',
  REFUNDS = '/refunds',
  TAXES = '/taxes',
  CONFIGURE = '/configure',
  FEES = '/fees',
  USERS = '/users',
  MERCHANT_ACCOUNTS = '/merchant-accounts',
  MERCHANT_ACCOUNT_DETAILS = '/merchant-accounts/:merchantAccountId',
  INVITED_USERS = '/users/invited',
  ACTIVE_USERS = '/users/active',
  FINANCE = '/finance',
  FINANCE_ACCOUNT_ACTIVITY = '/finance/account-activity',
  PAYOUTS = '/finance/payouts',
  STATEMENTS = '/finance/statements',
  REPORTS = 'finance/reports',
  MANAGE_PAYMENTS = '/manage-payments',
  SAVED_PAYMENT_METHODS = '/manage-payments/saved-payment-methods',
  SAVED_PAYMENT_METHODS_DETAILS = '/saved-payment-methods/:merchantAccountId/:merchantShopperId',
  TERMINALS = 'teminals',
  REGISTRATION = '/registration/:registrationSessionId',
  SIGNUP = '/signup',
  USER_INVITE = '/user-invite',
  LOGOUT = '/logout',
  AUTH_CALLBACK = '/auth/callback',
  CHECKOUT = '/checkout',
  CHECKOUT_V2 = '/checkout-v2',
  TAKE_PAYMENT = '/manage-payments/take-payment',
  ERROR = '/error',
  SESSION_EXPIRED = '/session-expired',
  USER_PROFILE = '/user/:id/profile',
  PAYMENT_METHOD_ADDED = '/saved-payment-method/added',
}

export const usePortalNavigationItems = (
  disablePortalPayments?: boolean,
  isOrganizationAdmin?: boolean,
  savePaymentMethodOption?: string
): NavigationItem[] => [
  {name: t`Dashboard`, href: RoutePath.DASHBOARD, icon: ChartPieIcon},
  {
    name: t`Transactions`,
    href: RoutePath.TRANSACTIONS,
    icon: TableCellsIcon,
    children: [
      {name: t`Payments`, href: RoutePath.PAYMENTS},
      {name: t`Declined Payments`, href: RoutePath.DECLINED_PAYMENTS},
      {name: t`Disputes`, href: RoutePath.DISPUTES},
    ],
  },
  ...(!disablePortalPayments ||
  savePaymentMethodOption === 'OPTIONAL' ||
  savePaymentMethodOption === 'FORCE' ||
  savePaymentMethodOption === 'ADD_EMBED'
    ? [
        {
          name: t`Payments`,
          href: RoutePath.MANAGE_PAYMENTS,
          icon: WalletIcon,
          children: [
            ...(!disablePortalPayments
              ? [
                  {
                    name: t`Virtual Terminal`,
                    href: RoutePath.TAKE_PAYMENT,
                  },
                ]
              : []),
            ...(savePaymentMethodOption === 'OPTIONAL' ||
            savePaymentMethodOption === 'FORCE' ||
            savePaymentMethodOption === 'ADD_EMBED'
              ? [
                  {
                    name: t`Stored Payment Methods`,
                    href: RoutePath.SAVED_PAYMENT_METHODS,
                  },
                ]
              : []),
          ],
        },
      ]
    : []),
  {
    name: t`Finance`,
    href: RoutePath.FINANCE,
    icon: PresentationChartLineIcon,
    children: [
      {name: t`Account Activity`, href: RoutePath.FINANCE_ACCOUNT_ACTIVITY},
      {
        name: t`Payouts`,
        href: RoutePath.PAYOUTS,
      },
      {
        name: t`Statements`,
        href: RoutePath.STATEMENTS,
      },
      ...(import.meta.env.VITE_SHOW_IN_PROGRESS
        ? [
            {
              name: t`Taxes`,
              href: RoutePath.TAXES,
              limited: true,
            },
            {
              name: t`Reports`,
              href: RoutePath.REPORTS,
              limited: true,
            },
          ]
        : []),
    ],
  },
  ...(import.meta.env.VITE_SHOW_IN_PROGRESS
    ? [
        {
          name: t`Terminals`,
          href: RoutePath.TERMINALS,
          icon: CalculatorIcon,
          limited: true,
        },
      ]
    : []),
  {
    name: t`Merchant Accounts`,
    href: RoutePath.MERCHANT_ACCOUNTS,
    icon: BuildingStorefrontIcon,
  },
  ...(isOrganizationAdmin
    ? [
        {
          name: t`Users`,
          href: RoutePath.USERS,
          icon: UserIcon,
          children: [
            {name: t`Active`, href: RoutePath.ACTIVE_USERS},
            {name: t`Invited`, href: RoutePath.INVITED_USERS},
          ],
        },
        {
          name: t`Configure`,
          href: RoutePath.CONFIGURE,
          icon: Cog6ToothIcon,
        },
      ]
    : []),
];

export const userNavigation = () => [
  // {name: t`Your profile`, href: ROUTES.USER_PROFILE},
  {name: t`Sign out`, href: RoutePath.LOGOUT},
];
