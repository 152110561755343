import {useLingui} from '@lingui/react';
import {
  PayoutsSummaryItem,
  PayoutsSummaryOutput,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {LocaleCode} from '@zentact/common';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getPayoutsColumns} from './columns';

type Props = {
  payoutsList?: PayoutsSummaryOutput;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string>>>;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  openDetailsPanel: (row: PayoutsSummaryItem) => void;
  showOrganizationColumn?: boolean;
  showMerchantColumn?: boolean;
  isLoading?: boolean;
  activityDetailsPath: string;
};

export const PayoutList = ({
  payoutsList,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  showOrganizationColumn,
  showMerchantColumn,
  openDetailsPanel,
  activityDetailsPath,
  isLoading,
}: Props) => {
  const {i18n} = useLingui();

  return (
    <TableWrapper
      data={payoutsList?.rows ?? []}
      pageCount={payoutsList?.pagination?.pageCount ?? 0}
      columns={getPayoutsColumns({
        i18n,
        locale: i18n.locale as LocaleCode,
        showOrganizationColumn,
        showMerchantColumn,
        openDetailsPanel,
        activityDetailsPath,
      })}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            {i18n._('No Transactions Recorded')}
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetailsPanel}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
