import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {StatementListOutput} from '@zentact/api/src/trpc/routers/statementRouter';
import {MonthIndex, TableColumnSize, formatLocaleDate} from '@zentact/common';
import {TruncatedText} from '../../../other';
import {getStartOfTheMonth} from '../../../utils/get-start-of-the-month';
import {StatementActions} from './statement-actions';

const columnsHelper = createColumnHelper<StatementListOutput['rows'][0]>();

type Props = {
  i18n: I18n;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};
export const getStatementColumns = ({i18n, trpc}: Props) => {
  return [
    columnsHelper.display({
      id: 'date',
      header: () => i18n._('Date'),
      cell: props =>
        formatLocaleDate(
          getStartOfTheMonth(
            props.row.original.year,
            props.row.original.month as MonthIndex
          ).toJSDate(),
          'monthYear'
        ),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('merchantAccount.businessName', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Merchant Account'),
    }),
    columnsHelper.accessor('organization.name', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Organization'),
    }),
    columnsHelper.display({
      id: 'type',
      header: () => i18n._('Type'),
      cell: () => i18n._('Statement'),
      meta: {
        visibleAt: 'sm',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => {
        return <StatementActions statementRow={props.row.original} trpc={trpc} />;
      },
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
