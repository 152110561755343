import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {DropDownMinimalMenuIcon} from '../../../dropdowns';

type Props = {
  row: PaymentListItemOutput;
  openDetailsPanel: (row: PaymentListItemOutput) => void;
};

export const PaymentActions = ({row, openDetailsPanel}: Props) => {
  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {name: 'View Details', onClick: () => openDetailsPanel(row)},
  ];
  return <DropDownMinimalMenuIcon items={options} />;
};
