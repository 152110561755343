import {useLingui} from '@lingui/react';
import {
  DeclinedPaymentListItemOutput,
  DeclinedPaymentsListOutput,
} from '@zentact/api/src/trpc/routers/paymentRouter';
import {LocaleCode} from '@zentact/common';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../table';
import {getDeclinedPaymentColumns} from './columns';

type Filters = {
  pspReferenceId?: string;
  paymentMethod?: string[];
  shopperEmail?: string;
};

type Props<TFilters extends Filters> = {
  paymentsList?: DeclinedPaymentsListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort?: TableSortValue<string> | null;
  setSort?: Dispatch<SetStateAction<TableSortValue<string>>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  openDetailsPanel?: (row: DeclinedPaymentListItemOutput) => void;
  showOrganizationColumn?: boolean;
  customAttributes?: string[];
  isLoading?: boolean;
};

export const DeclinedPaymentsList = <TFilters extends Filters>({
  paymentsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  openDetailsPanel,
  showOrganizationColumn,
  customAttributes,
  isLoading,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={paymentsList?.rows ?? []}
      pageCount={paymentsList?.pagination.pageCount ?? 0}
      columns={getDeclinedPaymentColumns({
        locale: i18n.locale as LocaleCode,
        i18n,
        customAttributes,
        openDetailsPanel,
        showOrganizationColumn,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">{i18n._('No Declined Payments')}</h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetailsPanel}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
