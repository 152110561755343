import {XCircleIcon} from '@heroicons/react/24/outline';
import {InputHTMLAttributes, forwardRef} from 'react';
import {cn} from '../../utils';

type InputSearchProps = InputHTMLAttributes<HTMLInputElement> & {
  onClear?: () => void;
};

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  ({className, onClear, ...props}, ref) => (
    <div
      className={cn(
        'flex w-full overflow-hidden rounded-md border-0  bg-white p-1 shadow-sm ring-1 ring-inset ring-gray-300',
        className
      )}
    >
      <input
        className="h-7 w-full border-0 pl-2 text-gray-900 outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
        ref={ref}
        {...props}
      />
      <button type="button" className="shrink-0 px-2" onClick={onClear}>
        <XCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" />
      </button>
    </div>
  )
);
