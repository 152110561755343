import {ColorCards, GeneratePalette, getPalleteFromPrimaryColor} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';

export const BrandColors = ({
  primaryDefaultColor,
  onUpdateColors,
  onResetColors,
  localeText,
}: {
  primaryDefaultColor: string;
  onUpdateColors: (color: string) => void;
  onResetColors: () => void;
  localeText: {
    apply: string;
    reset: string;
    cancel: string;
  };
}) => {
  const [colorPalette, setColorPalette] = useState<{[key: string]: string}>();

  useEffect(() => {
    const primary = getPalleteFromPrimaryColor(primaryDefaultColor);
    setColorPalette(primary);
  }, [primaryDefaultColor]);

  const onGenerateColors = (baseColor: string) => {
    const primary = getPalleteFromPrimaryColor(baseColor);
    setColorPalette(primary);
    if (baseColor) {
      onUpdateColors(baseColor);
    }
  };

  return (
    <div className="mt-5">
      <GeneratePalette
        onGenerateColors={onGenerateColors}
        onResetColors={onResetColors}
        baseColor={primaryDefaultColor}
        localeText={localeText}
      >
        <div className="mt-5">{!!colorPalette && <ColorCards colorPalette={colorPalette} />}</div>
      </GeneratePalette>
    </div>
  );
};
