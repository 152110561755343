import {AddressLookupItem} from '@adyen/adyen-web/dist/types/components/internal/Address/types';
import {Loader} from '@googlemaps/js-api-loader';
import {useCallback, useMemo, useRef, useState} from 'react';

export const useAddressAutocomplete = (countryRestrictions?: string[] | readonly string[]) => {
  const [isInitialized, setInitialized] = useState(false);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | undefined>();

  const googleMapsApiLoader: Loader = useMemo(
    () =>
      new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
      }),
    [import.meta.env.VITE_GOOGLE_MAPS_API_KEY]
  );
  const googleMapsServices = useMemo(() => {
    if (!isInitialized) {
      return {};
    }

    return {
      placesService: new google.maps.places.PlacesService(document.createElement('div')),
      autocompleteService: new google.maps.places.AutocompleteService(),
    };
  }, [isInitialized]);

  const getAddressPredictions = useCallback(
    async (input: string) => {
      if (!('placesService' in googleMapsServices)) {
        throw new Error('Google maps api is not loaded');
      }
      const {placesService, autocompleteService} = googleMapsServices;

      const getAddress = (placeId: string) =>
        new Promise<AddressLookupItem>((resolve, reject) => {
          // biome-ignore lint/style/noNonNullAssertion: TODO
          placesService!.getDetails(
            {
              placeId: placeId,
              fields: ['address_components', 'formatted_address'],
              sessionToken: sessionToken.current,
            },
            res => {
              if (!res) {
                return reject();
              }
              // biome-ignore lint/style/noNonNullAssertion: TODO
              const addressComponentsMap = res.address_components!.reduce((acc, item) => {
                for (const type of item.types) {
                  acc.set(type, item);
                }
                return acc;
                // biome-ignore lint/style/useNamingConvention: Google maps convention
              }, new Map<string, {long_name: string; short_name: string}>());

              const joinAddressParts = (...parts: Array<string | undefined>) => {
                const joined = parts.filter(v => !!v).join(' ');
                return joined.length ? joined : undefined;
              };

              // Used in US
              const locality = addressComponentsMap.get('locality');
              // Used in UK
              const postalTown = addressComponentsMap.get('postal_town');
              const administrativeAreaLevel1 = addressComponentsMap.get(
                'administrative_area_level_1'
              );
              // Germany might use sublocality_level_1 instead of locality
              const subLocalityLevel1 = addressComponentsMap.get('sublocality_level_1');

              resolve({
                // biome-ignore lint/style/noNonNullAssertion: TODO
                id: res.formatted_address!,
                // biome-ignore lint/style/noNonNullAssertion: TODO
                name: res.formatted_address!,
                city: locality
                  ? locality.long_name
                  : postalTown
                    ? postalTown.long_name
                    : administrativeAreaLevel1
                      ? administrativeAreaLevel1.long_name
                      : subLocalityLevel1?.long_name,
                street: joinAddressParts(
                  addressComponentsMap.get('street_number')?.long_name,
                  addressComponentsMap.get('route')?.long_name
                ),
                houseNumberOrName: addressComponentsMap.get('subpremise')?.long_name,
                postalCode: addressComponentsMap.get('postal_code')?.long_name,
                stateOrProvince: addressComponentsMap.get('administrative_area_level_1')
                  ?.short_name,
                country: addressComponentsMap.get('country')?.short_name,
              });
            }
          );
        });

      // biome-ignore lint/style/noNonNullAssertion: TODO
      const {predictions} = await autocompleteService!.getPlacePredictions({
        input,
        sessionToken: sessionToken.current,
        ...(countryRestrictions && {componentRestrictions: {country: [...countryRestrictions]}}),
      });

      const addresses = await Promise.all(predictions.map(p => getAddress(p.place_id)));

      return addresses;
    },
    [googleMapsServices.autocompleteService, googleMapsServices.placesService, countryRestrictions]
  );
  return {
    initialize: async () => {
      await googleMapsApiLoader.importLibrary('places');
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();
      setInitialized(true);
    },
    getAddressPredictions,
  };
};
