import {NumberFormat, format} from 'libphonenumber-js';

/**
 * Formats a phone number string into a specified format.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @param {NumberFormat} [numberFormat='INTERNATIONAL'] - The format to use for the phone number.
 *        Options include 'INTERNATIONAL', 'NATIONAL', 'E.164', 'RFC3966', or 'IDD'.
 * @returns {string} The formatted phone number.
 *
 * @example
 * // Returns '+1 650-555-1234' for a US phone number in international format
 * formatPhone('16505551234');
 *
 * @example
 * // Returns '650-555-1234' for a US phone number in national format
 * formatPhone('16505551234', 'NATIONAL');
 */
export const formatPhone = (
  phoneNumber: string,
  numberFormat: NumberFormat = 'INTERNATIONAL'
): string => {
  return format(phoneNumber, numberFormat);
};
