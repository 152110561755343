import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {
  CurrencyCode,
  LocaleCode,
  MerchantAccountPublicStatus,
  formatAmount,
  fromMinorUnits,
} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db/src';
import {useEffect} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {z} from 'zod';
import {MerchantAccountsPicker} from '../../../dropdowns';
import {InputText} from '../../../forms';
import {Label} from '../../label';
import {ValidationError} from '../../validation-error';

type Options = {
  i18n: I18n;
  minAmount: number;
  maxAmount: number;
  locale: LocaleCode;
  currency: CurrencyCode;
};

export const getAddNewSavedPaymentMethodFormSchema = ({
  i18n,
  minAmount,
  maxAmount,
  locale,
  currency,
}: Options) =>
  z.object({
    merchantAccountId: z.string({
      // biome-ignore lint/style/useNamingConvention: Zod uses snake case
      required_error: i18n._('Please select a merchant account'),
    }),
    customerEmail: z
      .string()
      .min(1, i18n._('Email is required'))
      .email(i18n._('Email is not valid'))
      .max(50, i18n._('Email must contain at most 50 character(s)')),
    customerId: z
      .string()
      .transform(value => {
        return value.trim() || undefined;
      })
      .pipe(
        z
          .string()
          .min(3, i18n._('Shopper id must be at least 3 characters length'))
          .max(50, i18n._('Shopper id contain at most 50 character(s)'))
          .optional()
      )
      .optional(),
    preauthorizeAmount: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Amount must be a multiple of 0.01'))
      .positive()
      .min(
        fromMinorUnits(minAmount, currency),
        i18n._('Amount must be greater than or equal to ${amount}', {
          amount: formatAmount(minAmount, locale, currency),
        })
      )
      .max(
        fromMinorUnits(maxAmount, currency),
        i18n._('Amount must be less than or equal to {amount}', {
          amount: formatAmount(maxAmount, locale, currency),
        })
      )
      .or(z.nan().transform(() => 0)),
  });

export type AddNewSavedPaymentMethodFormData = z.infer<
  ReturnType<typeof getAddNewSavedPaymentMethodFormSchema>
>;

export type AddNewSavedPaymentMethodFormProps = {
  form: UseFormReturn<AddNewSavedPaymentMethodFormData>;
  merchantAccounts: {
    id: string;
    businessName: string;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
  minAmount: number;
  maxAmount: number;
  locale: LocaleCode;
  currency: CurrencyCode;
};

export const AddNewSavedPaymentMethodForm = ({
  form,
  merchantAccounts,
  minAmount,
  maxAmount,
  locale,
  currency,
}: AddNewSavedPaymentMethodFormProps) => {
  const {i18n} = useLingui();

  const {
    register,
    formState: {errors},
    control,
    watch,
    setValue,
  } = form;
  const merchantAccountId = watch('merchantAccountId');

  useEffect(() => {
    if (!merchantAccountId && merchantAccounts.length === 1 && merchantAccounts[0]) {
      setValue('merchantAccountId', merchantAccounts[0].id);
    }
  }, [merchantAccountId, merchantAccounts]);

  return (
    <div className="flex flex-col gap-3">
      <Label text={i18n._('Merchant')}>
        <div className="sm:col-span-3">
          <Controller
            control={control}
            defaultValue={undefined}
            name="merchantAccountId"
            render={({field}) => (
              <MerchantAccountsPicker
                selectedMerchantAccount={field.value}
                onSelectMerchantAccount={value => {
                  field.onChange(value);
                }}
                merchantAccountsOptions={merchantAccounts}
                allLabel={
                  merchantAccounts.length < 1
                    ? i18n._('There are no active merchants available')
                    : i18n._('Select merchant account')
                }
              />
            )}
          />
          <ValidationError isVisible={Boolean(errors.merchantAccountId)}>
            {errors.merchantAccountId?.message}
          </ValidationError>
        </div>
      </Label>
      <Label text={i18n._('Email')}>
        <InputText
          {...register('customerEmail', {required: true})}
          placeholder="example@mail.com"
        />
        <ValidationError isVisible={Boolean(errors.customerEmail)}>
          {errors.customerEmail?.message}
        </ValidationError>
      </Label>
      <Label text={i18n._('Customer ID (Optional)')}>
        <InputText {...register('customerId')} />
        <ValidationError isVisible={Boolean(errors.customerId)}>
          {errors.customerId?.message}
        </ValidationError>
      </Label>
      <Label text={i18n._('Preauthorize Amount (Optional)')}>
        <InputText
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type={'number'}
          {...register('preauthorizeAmount', {
            required: true,
            valueAsNumber: true,
          })}
          hasError={Boolean(errors.preauthorizeAmount)}
        />
        <ValidationError isVisible={Boolean(errors.preauthorizeAmount)}>
          {errors.preauthorizeAmount?.message}
        </ValidationError>
        <p className="mt-1 text-xs leading-5 text-gray-500">
          {i18n._(
            "Preauthorization a dollar amount from {minAmount}-{maxAmount}. This allows you to verify if a card can cover the charge without completing the transaction. This confirms the card is currently valid for the specified amount but doesn't guarantee future transactions. Card balance and other factors will affect whether future transactions will succeed later. Preauthorized funds are automatically released after 5 minutes.",
            {
              minAmount: formatAmount(minAmount, locale, currency),
              maxAmount: formatAmount(maxAmount, locale, currency),
            }
          )}
        </p>
      </Label>
    </div>
  );
};
