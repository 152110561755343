import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import type {PaymentRequest} from '@adyen/api-library/lib/src/typings/payment/models';
import {Switch} from '@headlessui/react';
import {ArrowUpRightIcon} from '@heroicons/react/20/solid';
import {BanknotesIcon, ClipboardIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ShopperPrimaryPaymentMethodOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {
  ErrorCode,
  calculateAmountWithSurcharge,
  formatAmount,
  getCustomAttributeTranslation,
  getPaymentMethodName,
  isFormattedTrpcError,
  toMinorUnits,
} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  Breadcrumbs,
  Button,
  InputText,
  Label,
  Loading,
  MerchantAccountsPicker,
  Typography,
  ValidationError,
  cn,
  copyTextToClipboard,
  useNotification,
} from '@zentact/ui-tailwind';
import {isEqual} from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, FieldErrors, useForm} from 'react-hook-form';
import {CustomerIdTypeahead} from './customer-id-typeahead';
import {EmailTypeahead} from './email-typeahead';
import {TakePaymentFormData, getTakePaymentFormSchema} from './get-take-payment-form-schema';
import {PrimarySavedMethod} from './primary-saved-method';
import {SaleTypeRadio} from './sale-type-radio';

const minTaxPercentage = 0.1;
const maxTaxPercentage = 20;

enum FormEventType {
  COPY_LINK = 'copyLink',
  OPEN_LINK = 'openLink',
}

const getBreadCrumbs = () => [
  {name: t`Payments`, href: RoutePath.TAKE_PAYMENT, current: false},
  {name: t`Virtual Terminal`, href: RoutePath.TAKE_PAYMENT, current: true},
];

export const VirtualTerminal = () => {
  const {tenant, merchantAccounts, currency} = useStore();
  const {locale} = useLocale();
  const [paymentLink, setPaymentLink] = useState('');
  const [submittedFields, setSubmittedFields] = useState({});
  const [isPayConfirmationOpen, setPayConfirmationOpen] = useState(false);
  const [isPayWithSavedMethodLoading, setPayWithSavedMethodLoading] = useState(false);
  const [savedPaymentMethodData, setSavedPaymentMethodData] =
    useState<ShopperPrimaryPaymentMethodOutput | null>(null);

  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {
    minAmount = 100,
    maxAmount = 100000,
    customAttributesNames = [],
  } = tenant?.checkoutConfiguration || {};
  const requireL2DataInPortalPaymentFeature = !!tenant?.features?.requireL2DataInPortalPayment;
  const surchargeFeatureEnabled = !!tenant?.features?.enableSurcharges;

  const {i18n} = useLingui();
  const form = useForm<TakePaymentFormData>({
    resolver: zodResolver(
      getTakePaymentFormSchema({
        minAmount,
        maxAmount,
        locale,
        currency,
        requireL2DataInPortalPaymentFeature,
        minTaxPercentage,
        maxTaxPercentage,
      })
    ),
    defaultValues: {
      saleType: 'retail',
      taxPercentage: 0,
      customAttributes: {},
      customerId: '',
      email: '',
      allowSurcharge: true,
    },
  });
  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    control,
    trigger,
    setValue,
    reset,
    clearErrors,
    formState: {errors},
  } = form;

  const checkoutMutation = trpc.checkout.startCheckout.useMutation();
  const paymentWithShopperTokenMutation = trpc.payment.paymentWithShopperToken.useMutation();
  const [saleType, merchantAccountId, emailWatch] = watch([
    'saleType',
    'merchantAccountId',
    'email',
  ]);

  const amountWatch = watch('amount');
  const allowSurchargeWatch = watch('allowSurcharge');

  const amountWithSurcharge = useMemo(() => {
    if (
      !allowSurchargeWatch ||
      !savedPaymentMethodData?.surchargeConfiguration ||
      !amountWatch ||
      Number.isNaN(amountWatch)
    ) {
      return toMinorUnits(amountWatch, currency);
    }
    const {authorizedAmount} = calculateAmountWithSurcharge(
      toMinorUnits(amountWatch, currency),
      savedPaymentMethodData.surchargeConfiguration
    );
    return authorizedAmount;
  }, [amountWatch, savedPaymentMethodData, allowSurchargeWatch, currency]);

  const activeMerchantAccounts = merchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [];

  useEffect(() => {
    if (!merchantAccountId && activeMerchantAccounts.length === 1 && activeMerchantAccounts[0]) {
      setValue('merchantAccountId', activeMerchantAccounts[0].id);
    }
  }, [merchantAccountId, activeMerchantAccounts]);

  const isL2DataRequired = !!requireL2DataInPortalPaymentFeature && saleType === 'retail';
  const onCopyToClipboard = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        showSuccessNotification(t`Payment Link Copied!`);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const onOpenLink = (link: string) => {
    window.open(link, '_blank');
  };

  const onCheckoutCreate = (
    {
      customAttributes,
      customerId,
      saleType,
      taxPercentage,
      allowSurcharge,
      ...data
    }: TakePaymentFormData,
    eventType: FormEventType
  ) => {
    const amount = toMinorUnits(data.amount, currency);
    const totalTaxAmount =
      saleType === 'retail' && taxPercentage > 0 && Math.round((amount / 100) * taxPercentage);
    checkoutMutation.mutate(
      {
        ...data,
        amount,
        currency,
        shopper: {
          ...(customerId && {id: customerId}),
          email: data.email,
        },
        customAttributes: Object.fromEntries(
          Object.entries(customAttributes).filter((entry): entry is [string, string] => !!entry[1])
        ),
        ...(totalTaxAmount && customerId
          ? {
              additionalData: {
                enhancedSchemeData: {
                  customerReference: customerId,
                  totalTaxAmount: totalTaxAmount.toString(),
                },
              },
            }
          : {}),
        ...(surchargeFeatureEnabled ? {allowSurcharge} : {}),
      },
      {
        onSuccess: ({checkoutUrl}) => {
          if (eventType === FormEventType.COPY_LINK) {
            setPaymentLink(checkoutUrl);
            setSubmittedFields(watch());
            onCopyToClipboard(checkoutUrl);
          } else {
            setPaymentLink(checkoutUrl);
            setSubmittedFields(watch());
            onOpenLink(checkoutUrl);
          }
        },
        onError: error => {
          setError('root', {
            message:
              isFormattedTrpcError(error) &&
              error.data.errorCode === ErrorCode.CHECKOUT_REFERENCE_ID_ALREADY_EXISTS
                ? t`The transaction was refused as a result of a duplicate Order / Invoice ID supplied`
                : error.message,
          });
        },
      }
    );
  };

  const onPayWithSavedMethod = ({
    customAttributes,
    customerId,
    saleType,
    taxPercentage,
    ...data
  }: TakePaymentFormData) => {
    if (!savedPaymentMethodData) {
      console.error('No savedPaymentMethodData');
      return;
    }
    setPayWithSavedMethodLoading(true);

    const amount = toMinorUnits(data.amount, currency);
    const totalTaxAmount =
      saleType === 'retail' && taxPercentage > 0 && Math.round((amount / 100) * taxPercentage);
    paymentWithShopperTokenMutation.mutate(
      {
        ...data,
        amount,
        currency,
        shopperId: savedPaymentMethodData.merchantShopperId,
        customAttributes: Object.fromEntries(
          Object.entries(customAttributes).filter((entry): entry is [string, string] => !!entry[1])
        ),
        recurringProcessingModel:
          'UnscheduledCardOnFile' as PaymentRequest.RecurringProcessingModelEnum.UnscheduledCardOnFile,
        ...(totalTaxAmount && customerId
          ? {
              additionalData: {
                enhancedSchemeData: {
                  customerReference: customerId,
                  totalTaxAmount: totalTaxAmount.toString(),
                },
              },
            }
          : {}),
      },
      {
        onSuccess: () => {
          reset();
          setSavedPaymentMethodData(null);
          setPayWithSavedMethodLoading(false);
          setPayConfirmationOpen(false);
          showSuccessNotification(t`Payment Complete!`);
        },
        onError: error => {
          setPayWithSavedMethodLoading(false);
          setPayConfirmationOpen(false);
          showErrorNotification(t`Payment Failed`, error.message);
        },
      }
    );
  };

  const handleCopyClick = useCallback(
    (data: TakePaymentFormData) => {
      if (paymentLink && isEqual(submittedFields, watch())) {
        onCopyToClipboard(paymentLink);
      } else {
        onCheckoutCreate(data, FormEventType.COPY_LINK);
      }
    },
    [paymentLink, submittedFields, watch]
  );

  const handleLaunchClick = useCallback(
    (data: TakePaymentFormData) => {
      if (paymentLink && isEqual(submittedFields, watch())) {
        onOpenLink(paymentLink);
      } else {
        onCheckoutCreate(data, FormEventType.OPEN_LINK);
      }
    },
    [paymentLink, submittedFields, currency, watch]
  );

  const handlePayWithSavedMethodClick = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      setPayConfirmationOpen(true);
    }
  }, [trigger]);

  const onInvalidValidationSubmit = (errors: FieldErrors<TakePaymentFormData>) => {
    if (errors.customerId?.type === 'custom' || errors.taxPercentage?.type === 'custom') {
      showErrorNotification(
        'Additional data is required',
        'Please enter the highlighted information to proceed with retail sale, or switch to service sale if the required information is not available.'
      );
    }
  };

  const acceptTypeaheadSelect = (row: ShopperPrimaryPaymentMethodOutput | null) => {
    if (!row) {
      setSavedPaymentMethodData(null);
      return;
    }
    setValue('customerId', row.merchantShopperId, {shouldValidate: true});
    if (row.email) {
      setValue('email', row.email, {shouldValidate: true});
    }
    setSavedPaymentMethodData(row);
  };

  const {onChange: onChangeToServiceSaleType, ...registerServiceSaleType} = register('saleType');
  if (!merchantAccounts?.length) {
    return <Loading />;
  }

  return (
    <>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        <Trans>Take Payment</Trans>
      </Typography>
      <form className="mt-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Payment</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>
              Use this page to generate a payment link for a customer or pay from their primary
              stored payment method
            </Trans>
          </div>
        </div>
        <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
          <div className="flex flex-col w-full gap-6 md:w-1/2">
            <Typography variant="header-subsection">
              <Trans>Merchant</Trans>
            </Typography>
            <div className="sm:col-span-3">
              <Controller
                control={control}
                defaultValue={undefined}
                name="merchantAccountId"
                render={({field}) => (
                  <MerchantAccountsPicker
                    selectedMerchantAccount={field.value}
                    onSelectMerchantAccount={value => {
                      field.onChange(value);
                      clearErrors(['email', 'customerId']);
                    }}
                    merchantAccountsOptions={activeMerchantAccounts}
                    allLabel={
                      activeMerchantAccounts.length < 1
                        ? t`There are no active merchants available`
                        : t`Select merchant account`
                    }
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.merchantAccountId)}>
                {errors.merchantAccountId?.message}
              </ValidationError>
            </div>
            <Typography variant="header-subsection" className="mt-3">
              <Trans>Transaction Details</Trans>
            </Typography>
            <div className="flex flex-col gap-6 sm:col-span-3">
              {requireL2DataInPortalPaymentFeature && (
                <div className="flex flex-col gap-2 sm:col-span-3 ">
                  <div className="flex items-stretch gap-x-4 justify-items-stretch">
                    <SaleTypeRadio
                      {...register('saleType')}
                      value="retail"
                      label={<Trans>Retail</Trans>}
                      description={<Trans>Includes tax</Trans>}
                      selectedValue={saleType}
                    />
                    <SaleTypeRadio
                      {...registerServiceSaleType}
                      onChange={e => {
                        onChangeToServiceSaleType(e);
                        resetField('taxPercentage');
                      }}
                      value="service"
                      label={<Trans>Service</Trans>}
                      selectedValue={saleType}
                    />
                  </div>
                </div>
              )}
              {...customAttributesNames.map(attribute => (
                <div key={attribute} className="sm:col-span-3">
                  <Label text={t`${getCustomAttributeTranslation(attribute, i18n)} (Optional)`}>
                    <InputText
                      {...register(`customAttributes.${attribute}`)}
                      hasError={Boolean(errors.customAttributes?.[attribute])}
                    />
                    <ValidationError isVisible={Boolean(errors.customAttributes?.[attribute])}>
                      {errors.customAttributes?.[attribute]?.message}
                    </ValidationError>
                  </Label>
                </div>
              ))}
              <div className="sm:col-span-3">
                <Label
                  text={t`Amount (${formatAmount(minAmount, locale, currency)}-${formatAmount(
                    maxAmount,
                    locale,
                    currency
                  )})`}
                >
                  <InputText
                    className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    type={'number'}
                    {...register('amount', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    hasError={Boolean(errors.amount)}
                  />
                  <ValidationError isVisible={Boolean(errors.amount)}>
                    {errors.amount?.message}
                  </ValidationError>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    <Trans>Amount should be entered as a number, for example “123.55”</Trans>
                  </p>
                </Label>
              </div>
              {surchargeFeatureEnabled && (
                <div>
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={allowSurchargeWatch}
                      onChange={checked => {
                        setValue('allowSurcharge', checked);
                      }}
                      className={cn(
                        allowSurchargeWatch ? 'bg-primary-500' : 'bg-gray-200',
                        'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={cn(
                          allowSurchargeWatch ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                      <span className="font-medium text-gray-900">
                        <Trans>Enable Surcharge</Trans>
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                  <p className="mt-2 text-xs leading-5 text-gray-500">
                    <Trans>
                      Adds a surcharge to the final payment amount based on the selected payment
                      method
                    </Trans>
                  </p>
                </div>
              )}
              {isL2DataRequired && (
                <div className="sm:col-span-3">
                  <Label text={t`Tax Percentage (${minTaxPercentage}% - ${maxTaxPercentage}%)`}>
                    <InputText
                      {...register('taxPercentage', {
                        valueAsNumber: true,
                      })}
                      addonBefore="%"
                      hasError={Boolean(errors.taxPercentage)}
                    />
                    <ValidationError isVisible={Boolean(errors.taxPercentage)}>
                      {errors.taxPercentage?.message}
                    </ValidationError>
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      <Trans>
                        The tax percentage serves as an informational field and does not alter the
                        final amount.
                      </Trans>
                    </p>
                  </Label>
                </div>
              )}
            </div>
            <Typography variant="header-subsection" className="mt-3">
              <Trans>Customer</Trans>
            </Typography>
            <div className="flex flex-col gap-2 sm:col-span-3">
              <EmailTypeahead form={form} setPaymentMethod={acceptTypeaheadSelect} />
              <ValidationError isVisible={Boolean(errors.email)}>
                {errors.email?.message}
              </ValidationError>
            </div>
            <div className="flex flex-col gap-2 sm:col-span-3">
              <CustomerIdTypeahead
                isL2DataRequired={isL2DataRequired}
                form={form}
                setPaymentMethod={acceptTypeaheadSelect}
              />
              <ValidationError isVisible={Boolean(errors.customerId)}>
                {errors.customerId?.message}
              </ValidationError>
            </div>

            {!!savedPaymentMethodData && (
              <PrimarySavedMethod paymentMethod={savedPaymentMethodData} />
            )}
          </div>
          <ValidationError isVisible={Boolean(errors.root)}>{errors.root?.message}</ValidationError>
        </div>
        <div className="flex items-center p-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
          <Button
            isLoading={checkoutMutation.isLoading}
            type="button"
            disabled={
              checkoutMutation.isLoading ||
              paymentWithShopperTokenMutation.isLoading ||
              activeMerchantAccounts.length < 1
            }
            className="items-center gap-1 w-fit"
            size="xl"
            onClick={handleSubmit(handleCopyClick, onInvalidValidationSubmit)}
          >
            <Trans>Copy Payment Link</Trans>
            <ClipboardIcon className="h-4 w-4 stroke-[3px]" aria-hidden="true" />
          </Button>
          <Button
            type="button"
            isLoading={checkoutMutation.isLoading || paymentWithShopperTokenMutation.isLoading}
            disabled={
              checkoutMutation.isLoading ||
              paymentWithShopperTokenMutation.isLoading ||
              activeMerchantAccounts.length < 1
            }
            className="items-center gap-1 w-fit"
            size="xl"
            onClick={handleSubmit(handleLaunchClick, onInvalidValidationSubmit)}
          >
            <Trans>Launch Payment Page</Trans>
            <ArrowUpRightIcon className="h-4 w-4 stroke-[3px]" aria-hidden="true" />
          </Button>
          {savedPaymentMethodData?.status === 'ACTIVE' && (
            <Button
              isLoading={checkoutMutation.isLoading || paymentWithShopperTokenMutation.isLoading}
              type="button"
              disabled={checkoutMutation.isLoading || paymentWithShopperTokenMutation.isLoading}
              className="items-center gap-1 w-fit"
              size="xl"
              onClick={handlePayWithSavedMethodClick}
            >
              <Trans>Pay with Saved Method</Trans>
              <BanknotesIcon className="h-4 w-4 stroke-[2px]" aria-hidden="true" />
            </Button>
          )}
        </div>
        {!!savedPaymentMethodData && (
          <AlertOverlayWithConfirmation
            mode="success"
            setOpen={setPayConfirmationOpen}
            handleAction={handleSubmit(onPayWithSavedMethod, onInvalidValidationSubmit)}
            loading={isPayWithSavedMethodLoading}
            localeText={{
              title: t`Confirm Payment`,
              description: t`Are you sure you want to charge ${formatAmount(
                amountWithSurcharge,
                locale,
                currency
              )} to ${emailWatch} using ${getPaymentMethodName(
                savedPaymentMethodData.paymentMethod,
                locale
              )} ***${savedPaymentMethodData.cardLastFour}?`,
              cancel: t`Cancel`,
              confirm: t`Pay Now`,
            }}
            open={isPayConfirmationOpen}
          />
        )}
      </form>
    </>
  );
};
