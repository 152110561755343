import {isPossiblePhoneNumber} from 'libphonenumber-js';

/**
 * The rationale for using isPossiblePhoneNumber:
 *   telephone numbering plans can and sometimes do change, meaning that libphonenumber-js.isValidPhoneNumber() function may one day
 *   become outdated on a website that isn't actively maintained anymore.
 *   Imagine a "promo-site" or a "personal website" being deployed once and then running
 *   for years without any maintenance, where a client may be unable to submit
 *   a simple "Contact Us" form just because this newly allocated pool of
 *   mobile phone numbers wasn't present in that old version of libphonenumber-js bundled in it.
 * @param phoneNumber the phone number to validate
 * @returns true if the phone number is valid, false otherwise
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  return isPossiblePhoneNumber(phoneNumber);
};
