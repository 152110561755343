import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import type {
  MerchantAccountsListItem,
  MerchantAccountsListOutput,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getMerchantAccountColumns} from './columns';
import {MerchantAccountActionsProps} from './merchant-account-actions';

type Filters = {
  status?: MerchantAccountPublicStatus[];
};

type Props = {
  merchantAccountsList?: MerchantAccountsListOutput;
  filters?: Filters;
  setFilters?: (params: Filters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  merchantAccountsRefetch: () => void;
  openDetails: (row: MerchantAccountsListItem | null) => void;
  openEditAndResendPanel: MerchantAccountActionsProps['openEditAndResendPanel'];
  openUpdateCustomerSupportPanel: MerchantAccountActionsProps['openUpdateCustomerSupportPanel'];
  openUpdateEmailNotificationsPanel: MerchantAccountActionsProps['openUpdateEmailNotificationsPanel'];
  openUpdateSurchargeConfigurationPanel: MerchantAccountActionsProps['openUpdateSurchargeConfigurationPanel'];
  isLoading?: boolean;
};

export const MerchantAccountsList = ({
  merchantAccountsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  merchantAccountsRefetch,
  openDetails,
  openEditAndResendPanel,
  openUpdateCustomerSupportPanel,
  openUpdateEmailNotificationsPanel,
  openUpdateSurchargeConfigurationPanel,
  isLoading,
}: Props) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={merchantAccountsList?.rows ?? []}
      pageCount={merchantAccountsList?.pagination.pageCount ?? 0}
      columns={getMerchantAccountColumns({
        refetchMerchantAccountsList: merchantAccountsRefetch,
        i18n,
        openDetails,
        openEditAndResendPanel,
        openUpdateCustomerSupportPanel,
        openUpdateEmailNotificationsPanel,
        openUpdateSurchargeConfigurationPanel,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as Filters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No merchant accounts</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>As merchant accounts onboard, they will appear here.</Trans>
          </span>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={(row: MerchantAccountsListItem) => openDetails(row)}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
