import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import React, {useCallback} from 'react';
import {useNotification} from '../../../hooks';
import {AlertOverlayWithConfirmation} from '../../../overlays';

type RefundConfirmDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  paymentMethod: ShopperListItemOutput['savedPaymentMethods'][number] | null;
  refetch: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const DeletePaymentMethodConfirmDialog = ({
  isOpen,
  onClose,
  paymentMethod,
  trpc,
  refetch,
}: RefundConfirmDialogProps) => {
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const deletePaymentMethodMutation = trpc.savedPaymentMethod.deleteStoredPaymentMethod.useMutation(
    {
      onError: error => {
        onClose();
        showErrorNotification(error.message);
      },
      onSuccess: async () => {
        onClose();
        showSuccessNotification('Payment method deleted');
        refetch();
      },
    }
  );

  const handleDeletePaymentMethod = useCallback(async () => {
    deletePaymentMethodMutation.mutate({
      storedPaymentMethodPublicId: paymentMethod?.publicFacingId || '',
    });
  }, [paymentMethod, showErrorNotification, showSuccessNotification]);

  if (!paymentMethod) {
    return null;
  }

  return (
    <div>
      <AlertOverlayWithConfirmation
        open={isOpen}
        setOpen={onClose}
        loading={deletePaymentMethodMutation.isLoading}
        handleAction={handleDeletePaymentMethod}
        localeText={{
          title: i18n._('Confirm Delete Card'),
          description: i18n._(
            'This will remove the card on file, and you will no longer be able to charge this card. Are you sure?'
          ),
          confirm: i18n._('Delete Card'),
          cancel: i18n._('Cancel'),
        }}
      />
    </div>
  );
};
