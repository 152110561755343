import {ReactNode} from 'react';
import {cn} from '../../utils';

export enum BadgeTypes {
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
  BLUE = 'blue',
  ORANGE = 'orange',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  PINK = 'pink',
}

const badgeCircleStyles: Record<BadgeTypes, string> = {
  yellow: cn('border-yellow-200 bg-yellow-400'),
  green: cn('border-green-200 bg-green-400'),
  red: cn('border-red-200 bg-red-400'),
  orange: cn('border-orange-200 bg-orange-400'),
  blue: cn('border-blue-200 bg-blue-400'),
  indigo: cn('border-indigo-200 bg-indigo-400'),
  purple: cn('border-purple-200 bg-purple-400'),
  pink: cn('border-pink-200 bg-pink-400'),
};

export type BadgeProps = {children: ReactNode; className?: string; color?: BadgeTypes};

export const Badge = ({children, className, color = BadgeTypes.YELLOW}: BadgeProps) => {
  return (
    <span
      className={cn(
        'inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-sm font-bold text-gray-900 ring-1 ring-inset ring-gray-200',
        className
      )}
    >
      <div className={cn('h-3 w-3 shrink-0 rounded-full border-4 ', badgeCircleStyles[color])} />
      {children}
    </span>
  );
};
