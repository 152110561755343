import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {useCallback, useState} from 'react';
import {Button, SlideOverWithBrandedHeader} from '../../../';
import {RefundConfirmDialog} from '../refund-confirm-dialog';
import {SidebarPaymentDetails} from './sidebar-payment-details';

type PaymentDetailsPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  paymentDetailsRow: PaymentListItemOutput | null;
  onViewDetailsClick: (pspReferenceId: string) => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  onRefundOrVoid?: () => void;
  showProfit?: boolean;
  showOrganizationColumn?: boolean;
  disablePortalRefund?: boolean;
};

const REFUND_AVAILABLE_STATUSES = ['SETTLED', 'AUTHORIZED', 'PARTIALLY_REFUNDED'];

export const PaymentDetailsPanel = ({
  isOpen,
  onCancel,
  paymentDetailsRow,
  onRefundOrVoid,
  onViewDetailsClick,
  showProfit,
  showOrganizationColumn,
  trpc,
  disablePortalRefund,
}: PaymentDetailsPanelProps) => {
  const [refundConfirmationOpen, setRefundConfirmationOpen] = useState(false);
  const {i18n} = useLingui();
  const isPaymentAuthorized = paymentDetailsRow?.status === 'AUTHORIZED';

  const handleRefundClick = useCallback(() => {
    setRefundConfirmationOpen(true);
  }, []);

  if (!paymentDetailsRow) {
    return null;
  }
  const {
    status,
    organization: {name: organizationName},
  } = paymentDetailsRow;

  const refundButtonVisible =
    onRefundOrVoid && REFUND_AVAILABLE_STATUSES.includes(status) && !disablePortalRefund;
  const applyButtonText = isPaymentAuthorized ? i18n._('Void') : i18n._('Refund');

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Transaction')}
      closeHandler={onCancel}
      footer={
        <footer className="flex justify-between p-4 shrink-0 gap-x-3">
          {refundButtonVisible ? (
            <Button
              variant="primary"
              size="lg"
              className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
              onClick={handleRefundClick}
            >
              {applyButtonText}
            </Button>
          ) : (
            <div />
          )}
          <Button
            variant="primary"
            size="lg"
            className="w-fit"
            onClick={() => onViewDetailsClick(paymentDetailsRow.pspReferenceId)}
          >
            {i18n._('View Details')}
          </Button>
        </footer>
      }
    >
      <>
        <SidebarPaymentDetails
          paymentRow={paymentDetailsRow}
          merchantAccountName={paymentDetailsRow.merchantAccount.businessName}
          organizationName={showOrganizationColumn ? organizationName : undefined}
          showProfit={showProfit}
        />
        {refundButtonVisible && !!onRefundOrVoid && (
          <RefundConfirmDialog
            isOpen={refundConfirmationOpen}
            paymentDetailsRow={paymentDetailsRow}
            onClose={() => setRefundConfirmationOpen(false)}
            onSuccess={onRefundOrVoid}
            trpc={trpc}
          />
        )}
      </>
    </SlideOverWithBrandedHeader>
  );
};
