import {trpc} from '@/api/trpcClient';
import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
  AddMerchantAccountForm,
  MerchantAccountFormData,
  getMerchantAccountFormSchema,
} from '../add-merchant-account-form';

export type UpdateMerchantAccountFormProps = {
  merchantAccountRow: MerchantAccountDetailsOutput | MerchantAccountsListItem;
  onClose: () => void;
  isOpen: boolean;
  refetchMerchantAccountsList: () => void;
};

export const EditAndResendInvitation = ({
  merchantAccountRow,
  onClose,
  isOpen,
  refetchMerchantAccountsList,
}: UpdateMerchantAccountFormProps) => {
  const {user} = useAuth0();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const resendForm = useForm<MerchantAccountFormData>({
    resolver: zodResolver(getMerchantAccountFormSchema()),
  });
  const {handleSubmit} = resendForm;

  const {sessionId} =
    'firstName' in merchantAccountRow
      ? merchantAccountRow
      : merchantAccountRow.merchantAccountRegistration;

  const getInvitationDetail = trpc.merchantRegistration.getInvitationDetails.useQuery(
    {
      registrationSessionId: sessionId,
    },
    {enabled: isOpen}
  );

  useEffect(() => {
    if (!getInvitationDetail.data) {
      return;
    }

    if (getInvitationDetail.data.merchantName) {
      resendForm.setValue('name', getInvitationDetail.data.merchantName);
    }
    if (getInvitationDetail.data.merchantEmail) {
      resendForm.setValue('email', getInvitationDetail.data.merchantEmail);
    }
  }, [getInvitationDetail.data]);

  const editAndResendMutation = trpc.merchantRegistration.editAndResendInvitation.useMutation({
    onSuccess: (_, variables) => {
      onClose();
      refetchMerchantAccountsList();
      showSuccessNotification(
        t`Invitation sent!`,
        t`You have successfully sent the invitation to ${variables.businessName}.`
      );
    },
    onError: error => {
      onClose();
      showErrorNotification(t`Error`, error.message);
    },
  });

  const handleEditAndResend = useCallback(
    (data: MerchantAccountFormData) => {
      editAndResendMutation.mutate({
        inviterName: user?.name || user?.email || 'Unknown',
        registrationSessionId: sessionId,
        businessName: data.name,
        email: data.email,
      });
    },
    [user]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={merchantAccountRow && Boolean(getInvitationDetail.isFetched)}
      title={
        merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
          ? t`Edit and Invite`
          : t`Edit and Resend Invitation`
      }
      text={
        merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
          ? t`Edit the invitation and send it to signatory.`
          : t`Edit the invitation and resend it to signatory.`
      }
      applyButtonText={t`Send Invite`}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(handleEditAndResend)}
              isLoading={editAndResendMutation.isLoading}
            >
              <Trans>Send Invite</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(handleEditAndResend)}>
        <AddMerchantAccountForm form={resendForm} />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
