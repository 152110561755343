import {Combobox, Transition} from '@headlessui/react';
import {CheckIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {ComponentProps, Fragment, Ref, forwardRef} from 'react';
import {InputSelectOption, Label} from '../..';
import {cn} from '../../utils';

export type TypeaheadProps = Omit<ComponentProps<typeof Combobox>, 'value' | 'onChange'> & {
  options: InputSelectOption<string>[];
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  onSelect: (input: string) => void;
  showSearchIcon?: boolean;
};

function TypeaheadComponent(props: TypeaheadProps, ref: Ref<HTMLDivElement>) {
  const {
    options,
    value,
    onChange,
    placeholder,
    className,
    label,
    labelClassName,
    onSelect,
    wrapperClassName,
    inputClassName,
    showSearchIcon,
    ...restProps
  } = props;

  return (
    <Combobox
      as="div"
      ref={ref}
      value={value}
      {...restProps}
      onChange={onSelect}
      className={cn('relative', className)}
      onClick={(e: Event) => e.preventDefault()} // fixes an issue where component fails to close on click inside slideover
    >
      {({open}) => (
        <>
          <Label text={label} className={labelClassName}>
            <Combobox.Input
              as="div"
              className={cn(
                'p-0 relative flex items-center focus:ring-primary-600 sm:leading-6 w-full rounded-md border-0 text-gray-900 ring-1 ring-gray-300 shadow-sm focus:ring-2 focus:ring-inset focus-within:ring-2 focus-within:ring-primary-600 h-9',
                wrapperClassName
              )}
            >
              {showSearchIcon && (
                <div className="ml-3">
                  <MagnifyingGlassIcon className="w-4 h-4 text-gray-500" />
                </div>
              )}
              <input
                value={value}
                type="text"
                placeholder={placeholder}
                onChange={event => onChange(event.target.value)}
                onBlur={event => onSelect(event.target.value)}
                onKeyDown={event => {
                  const target = event.target as HTMLInputElement;
                  if (event.key !== 'Escape') {
                    return;
                  }
                  onChange(target.value);
                }}
                className={cn(
                  'flex-1 w-full py-1 text-gray-900 border-0 rounded-md placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm ',
                  inputClassName
                )}
              />
            </Combobox.Input>
          </Label>
          {options.length !== 0 && (
            <Transition
              show={open}
              as={Fragment}
              leaveTo="opacity-0"
              leaveFrom="opacity-100"
              leave="transition ease-in duration-100"
            >
              <Combobox.Options
                static
                className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                {options.map((option, id) => (
                  <Combobox.Option
                    key={`Combobox - ${option.id} - ${id}`}
                    className={({active}) =>
                      cn(
                        active
                          ? cn('bg-primary-600 text-white', option.activeClassName)
                          : cn('text-gray-900', option.className),
                        'relative cursor-default select-none py-2 pl-3 pr-9 '
                      )
                    }
                    data-te-toggle={!!option.tooltipText && 'tooltip'}
                    title={option.tooltipText}
                    value={option.id}
                    disabled={option.disabled}
                  >
                    {({selected, active}) => (
                      <>
                        <div className="flex items-center ">
                          {option.iconUrl ? (
                            <img
                              src={option.iconUrl}
                              className="w-5 h-5 rounded-full shrink-0"
                              alt="icon"
                            />
                          ) : null}
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              option.iconUrl && 'ml-3',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-white' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          )}
        </>
      )}
    </Combobox>
  );
}

export const Typeahead = forwardRef(TypeaheadComponent);

Typeahead.displayName = 'AddressSearchSelect';
