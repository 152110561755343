import {I18n} from '@lingui/core';
import {
  maxAchFlatFee,
  maxAchSurchargePercentageFee,
  maxCreditCardFlatFee,
  maxCreditCardSurchargePercentageFee,
} from '@zentact/common';
import * as z from 'zod';
type Params = {
  i18n: I18n;
};
export const getSurchargeConfigurationScheme = ({i18n}: Params) =>
  z.object({
    surchargeAchEnabled: z.boolean(),
    surchargeCreditCardEnabled: z.boolean(),
    creditCardPercentageFee: z.coerce
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Fee must be a multiple of 0.01'))
      .min(0)
      .max(
        maxCreditCardSurchargePercentageFee,
        i18n._('Percentage fee can not be higher than {maxCreditCardSurchargePercentageFee}%', {
          maxCreditCardSurchargePercentageFee,
        })
      )
      .optional(),
    creditCardFlatFee: z.coerce
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Fee must be a multiple of 0.01'))
      .min(0)
      .max(
        maxCreditCardFlatFee,
        i18n._('Credit card flat fee can not be higher than ${maxCreditCardFlatFee}', {
          maxCreditCardFlatFee,
        })
      )
      .optional(),
    achPercentageFee: z.coerce
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Fee must be a multiple of 0.01'))
      .min(0)
      .max(
        maxAchSurchargePercentageFee,
        i18n._('ACH percentage fee can not be higher than {maxAchSurchargePercentageFee}%', {
          maxAchSurchargePercentageFee,
        })
      )
      .optional(),
    achFlatFee: z.coerce
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: i18n._('Only numbers and decimals are allowed'),
      })
      .multipleOf(0.01, i18n._('Fee must be a multiple of 0.01'))
      .min(0)
      .max(
        maxAchFlatFee,
        i18n._('ACH flat fee can not be higher than ${maxAchFlatFee}', {
          maxAchFlatFee,
        })
      )
      .optional(),
  });
